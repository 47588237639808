.tips-search
	position: relative
	button
		+reset-button
		position: absolute
		+center(Y)
		right: (20px)
		font-size: 1rem
		+text(grey-666)
	input
		height: r(48px)
		width: 100%
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
		padding: r(16px) r(20px) r(12px)
		border: 0
		font-size: r(18px)

.tip-item
	+bg(white)
	border: 1px solid color(grey-e1)
	display: flex
	flex-direction: column
	+on-hover
		.tip-title
			text-decoration: underline
	.tip-img
		position: relative
		a
			display: block
			+res-ratio(306,350)
	.tip-caption
		padding: 2rem 1rem
		display: flex
		flex-direction: column
		align-items: flex-start
		flex: 1 1 0%
		.btn
			margin-top: auto
		+mq-max(md)
			padding: r(20px)
	.tip-title
		+font(600,20px,28px)
	.tip-brief
		+line(4)
		font-weight: 300
		+fz-lh(18px,24px)
		text-align: justify
		margin-top: 1rem
		margin-bottom: r(48px)
		+mq-max(md)
			margin-bottom: 1rem
			margin-top: 0
			text-align: left
			+fz-lh(14px,20px)
	.blog-date
		position: absolute
		bottom: 0
		left: 1rem
	.view-more
		margin-top: auto
	&.is-news
		.tip-title
			+line(2)
		.tip-img
			a
				+res-ratio(334,222)
		.tip-brief
			margin-bottom: 1rem
			margin-top: 0.5rem
		.tip-caption
			padding: r(20px) 1rem
		.view-more
			margin-top: 0
	&.is-big
		.tip-img
			a
				+res-ratio(810,500)
	&.is-row
		display: flex
		flex-direction: row
		.tip-img
			+flex-width(36.75%)
			a
				+res-ratio(1,1)
		.tip-caption
			flex: 1
		.tip-brief
			+line(2)
		+mq-max(lg)
			.tip-caption
				padding: 0.5rem 1rem !important
			.tip-brief
				margin-top: 0 !important

.blog-date
	display: flex
	flex-direction: column
	+flex-center
	+box(r(60px))
	+bg(secondary)
	+text(white)
	font-weight: 700
	text-align: center
	pointer-events: none
	.day
		font-size: r(30px)
	.month
		font-size: r(12px)

.tips-slider,.blog-slider
	.swiper-slide
		height: auto
		.tip-item
			height: 100%
