.product-detail-image-wrap
	padding: r(40px) r(60px)
	+radius(2rem)
	+bg(main-light)
	+mq-max(md)
		padding: r(24px)
		+radius(1rem)

.product-detail-images
	.img
		a
			+res-ratio(809, 668)
			+radius(r(8px))
			overflow: hidden
		&.video
			position: relative
			.fal
				position: absolute
				+center('both')
				font-size: r(60px)
				z-index: 2
				color: white
				width: auto
				height: auto

.product-detail-thumbnail
	margin-top: r(24px)
	+mq-max(md)
		margin-top: r(12px)
	.swiper-container
		padding: 4px
		margin: -4px
	.img
		+res-ratio(1,1)
		+radius(r(8px))
		overflow: hidden
		&.video
			position: relative
			.fal
				position: absolute
				+center('both')
				font-size: r(32px)
				z-index: 2
				color: white
				width: auto
				height: auto
	.swiper-slide-thumb-active
		.img
			box-shadow: 0px 0px 0px 3px rgba(140,183,63,1)
	.swiper-btn
		margin: 0 r(-24px)

.product-detail-content
	padding: r(40px) r(28px)
	box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
	+radius(2rem)
	hr
		background: transparent
		border: 0
		border-top: 1px solid #d9d9d9
		margin: r(16px) 0
	+mq-max(md)
		padding: r(24px)
		+radius(1rem)

.product-detail-price
	.price
		font-size: r(24px)
		+text(secondary)
		+trans-all
	.old-price
		+text(grey-999)
		margin-left: r(12px)
		+mq-max(md)
			margin-left: 0

.product-detail-flash-sale
	display: flex
	align-items: center
	height: r(40px)
	background: color(main)
	color: #fff
	position: relative
	padding: 8px r(20px)
	margin-top: r(12px)
	.deal-countdown
		display: flex
		margin-left: auto
		.count
			display: flex
			align-items: center
			.separator
				padding: 0 3px
			.number
				width: r(40px)
				height: r(24px)
				display: flex
				align-items: center
				justify-content: center
				background: #fff
				border-radius: r(5px)
				font-size: r(14px)
				font-weight: 700
				color: color(secondary)
				line-height: 1.25
	@media screen and ( max-width: 1280.98px )
		display: block
		min-height: 40px
		height: auto
		.deal-countdown
			margin-top: r(14px)
	@media screen and ( max-width: 1024.98px )
		max-width: 300px
		text-align: center
		margin: 0 auto
		.deal-countdown
			justify-content: center
			margin-top: 5px

.product-detail-full-content
	font-weight: 300
	+fz-lh(14px,20px)
	+content-margins
		margin-top: r(4px)
	h3
		+text(grey-999)
		font-weight: 400
	ul
		padding-left: 0.75rem
		list-style: disc
	.whole-price
		padding: r(12px) r(16px)
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
		+radius(r(4px))
		display: inline-block
		font-weight: 400
		+text(secondary)

.product-detail-ship
	margin-top: 2rem
	+text(grey-999)
	span
		+text(secondary)
		margin-right: r(12px)

.product-detail-variant
	margin-top: 1rem
	+content-margins
		margin-top: 1rem
	.variant-group
		display: flex
		align-items: center
		.group-title
			font-size: r(14px)
			+text(grey-999)
			+flex-width(15%)
		.option-list
			flex: 1
			+row(12px)
			a
				display: flex
				align-items: center
				justify-content: center
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
				white-space: nowrap
				height: 2rem
				+radius(r(4px))
				padding: 0 r(12px)
				font-weight: 300
				+text(main-light)
				+trans-all
				border: 1px solid transparent
				&.active
					+text(main)
					border: 1px solid color(main)

.product-detail-quantity
	strong
		white-space: nowrap

.product-detail-purchase
	margin-top: r(32px)
	.btn-add-to-cart
		display: inline-flex
		align-items: center
		padding-left: r(48px)
		padding-top: r(4px)
		height: r(40px)
		border-radius: r(40px) 0
		+bg(secondary)
		+text(white)
		width: r(200px)
		position: relative
		text-transform: uppercase
		font-weight: 600
		+trans-all
		&:hover
			+bg(brown)
		em
			position: absolute
			+circle(r(60px))
			border: r(4px) solid #fff
			right: r(-16px)
			+center(Y)
			+bg(main)
			+text(white)
			font-size: r(20px)
			display: flex
			+flex-center

.spin-input-wrap
	display: flex
	.btn-spin,.spin-btn
		+box(2rem)
		display: flex
		+flex-center
		+bg(grey-e1)
		+text(grey-666)
		+radius(r(4px))
		cursor: pointer
	input
		margin: 0 r(8px)
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
		+radius(r(4px))
		border: 0
		text-align: center
		height: 2rem
		width: r(100px)

.detail-attr
	.tabs-nav
		white-space: nowrap
		overflow: auto
		li
			display: inline-block
			font-size: 0px
			+first(1)
				a
					padding-left: r(64px)
					border-radius: r(32px) 0 0 0
			a
				display: flex
				align-items: center
				justify-content: center
				font-size: r(20px)
				+bg(main)
				+text(white)
				padding: r(12px) 2rem r(8px)
				height: r(48px)
				+parent-state(".active")
					+bg(tertiary)
	.tab-content
		padding: r(48px) r(60px)
		box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
		border-radius: 0px 0px r(32px) r(32px)
		h4
			@extend .heading-4
			+text(secondary)
		+mq-max(md)
			padding: r(24px)
