//Reset
@import src/_plugins/sass-libraries/reset.sass
@import src/_plugins/sass-libraries/util.scss
// @import src/_plugins/sass-libraries/fluid-type.sass
@import src/_plugins/sass-libraries/family.scss
@import src/_plugins/sass-libraries/columnizer.scss
@import src/_plugins/sass-libraries/svg-background.scss

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	line-height: 1
	font-family: 'Josefin Sans', sans-serif
	color: #333
	font-size: 3.6vw
	+mq-min(sm)
		font-size: 2.5vw
	+mq-min(md)
		font-size: 1.25vw
	+mq-min(lg)
		font-size: 14px
	+mq-min(xxl)
		font-size: 16px

body
	overflow-x: hidden

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none
