.service-business-2
    .item
        .icon
            +circle(r(90px))
            +bg(main)
            font-size: r(40px)
            display: flex
            +flex-center
            +text(white)
            margin: 0 auto
            padding-top: r(8px)
        .title
            padding: r(20px) r(28px) r(12px)
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05)
            border-radius: r(4px)
            text-align: center
            +font(300,18px,24px)
    .bg-img
        margin-top: r(120px)
        +mq-max(lg)
            margin-top: 2rem