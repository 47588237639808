.service-personal-2
	.item
		.img
			figure
				+circle(r(120px))
				overflow: hidden
				margin: 0 auto
				img
					width: 100%
					height: 100%
					object-fit: cover
		.title
			text-align: center
			+fz-lh(18px,24px)
			font-weight: 300
			margin-top: r(24px)
			+mq-max(md)
				margin-top: r(12px)