
[id*="popup-"],[id*="popup_"]
	width: r(1110px)
	padding: 0
	.popup-form-wrap
		background: #D0DCB7
		.img
			+flex-width((450 / 1110) * 100%)
			+remove(md,max)
		.popup-form
			flex: 1
			position: relative
			z-index: 1
			display: flex
			flex-direction: column
			justify-content: center
			padding: 0 r(40px)
			line-height: 1.333333
			+mq-max(md)
				padding: r(32px)
			&::before
				+pseudo
				+remove(md,max)
				width: r(121px)
				height: r(110px)
				background-image: url(../img/popup-bg-1.png)
				background-position: center
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				top: 0
				left: 0
				z-index: -1
			&::after
				+pseudo
				width: r(300px)
				height: r(220px)
				background-image: url(../img/popup-bg-2.png)
				background-position: center
				background-size: contain
				background-repeat: no-repeat
				position: absolute
				bottom: 0
				right: 0
				z-index: -1
			h2
				> *
					display: block
					& + *
						margin-top: r(16px)
			.form-group
				+all-input
					background: white
					border: 0px
			.row
				+gap-override(20px)
			.frm-btnwrap
				margin-top: r(20px)
