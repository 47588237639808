.blog-discover-cate-slider
	.swiper-slide
		height: auto
	.item
		height: 100%
		display: block
		+bg(white)
		padding: r(8px)
		+radius(r(8px))
		.img
			+radius(r(8px))
			overflow: hidden
			figure
				+res-ratio(1,1)
			img
				+trans-all
		.title
			+font(600,20px,24px)
			margin-top: r(16px)
			+mq-max(md)
				font-size: r(16px)
		+on-hover
			.img
				overflow: hidden
				img
					transform: scale(1.05)