.about-7
	padding-top: r(40px)
	+mq-min(xl)
		padding-top: r(120px)
	.content-wrap
		+bg(tertiary)
		+text(white)
		border-radius: r(20px)
		padding: r(40px) r(24px)
		position: relative
		z-index: 2
		+mq-min(lg)
			padding: r(60px) 0
			border-radius: 0px r(20px) r(20px) 0px
			&:before
				+pseudo
				right: 100%
				height: 100%
				width: 1000px
				+bg(tertiary)
				top: 0
		.bg-img
			position: absolute
			right: r(-32px)
			bottom: r(-144px)
			width: 480 / 1400 * 100%
			+remove(lg,max)
	.item-list
		margin-top: r(40px)
		+row(60px)
		+mq-max(md)
			+row(24px)
		.item-col
			+mq-max(md)
				+flex-width(50%)
		.item
			.icon
				+circle(r(72px))
				+bg(white)
				display: flex
				+flex-center
				font-size: r(40px)
				+text(brown)
			.number
				margin-top: r(20px)
				+text(brown)
				font-weight: 700
				font-size: r(48px)
			.divider
				height: 1px
				background: linear-gradient(270deg, rgba(205, 182, 156, 0) 0%, rgba(216, 185, 24, 0) 0.01%, #CDB69C 95%)
				margin: 1rem 0
			.title 
				+fz-lh(18px,24px)
				text-transform: uppercase