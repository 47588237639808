.about-9
	position: relative
	.bg-img
		position: absolute
		bottom: 0
		left: 0
		width: 100%
	.img
		border-radius: r(48px) r(48px) 0px 0px
		overflow: hidden
		+mq-max(lg)
			border-radius: r(16px) r(16px) 0 0