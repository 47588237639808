@charset "UTF-8";
/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.detail-social {
  display: flex; }
  .detail-social a {
    width: 3rem;
    height: 3rem;
    border-radius: 999999px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #8CB73F;
    color: #fff;
    font-size: 1.5rem; }

.fa-exclamation-triangle {
  line-height: 2.5;
  color: #ff3333;
  font-weight: 400;
  font-family: 'Roboto', sans-serif; }
  .fa-exclamation-triangle:before {
    font-family: 'Font Awesome 5 Pro'; }

.frm-btn-reset {
  display: none !important; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.875rem;
  margin-bottom: -1.875rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.875rem 1.875rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 576.98px) {
  .row {
    margin-left: -0.9375rem;
    margin-bottom: -0.9375rem; }
  .row > * {
    padding: 0 0 0.9375rem 0.9375rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

body, html {
  line-height: 1;
  font-family: 'Josefin Sans', sans-serif;
  color: #333;
  font-size: 3.6vw; }
  @media (min-width: 577px) {
    body, html {
      font-size: 2.5vw; } }
  @media (min-width: 769px) {
    body, html {
      font-size: 1.25vw; } }
  @media (min-width: 1025px) {
    body, html {
      font-size: 14px; } }
  @media (min-width: 1441px) {
    body, html {
      font-size: 16px; } }

body {
  overflow-x: hidden; }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

a {
  text-decoration: none; }

.cart {
  text-align: center;
  position: relative;
  top: 2px; }
  .cart .cart-toggle {
    cursor: pointer; }
    .cart .cart-toggle .icon {
      font-size: 1rem; }
      @media (max-width: 768px) {
        .cart .cart-toggle .icon {
          font-size: 4.8vw; } }
    .cart .cart-toggle .cart-amount {
      font-size: 0.75rem;
      background: red;
      color: #fff; }
      @media (max-width: 768px) {
        .cart .cart-toggle .cart-amount {
          top: -2.5vw;
          right: -2.5vw;
          width: 3.6vw;
          height: 3.6vw; } }
      @media (min-width: 769px) {
        .cart .cart-toggle .cart-amount {
          width: 1.25rem;
          height: 1.25rem;
          bottom: 100%;
          left: 100%; } }
  .cart .cart-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 12px;
    width: 22.5rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 20;
    display: none; }
    .cart .cart-dropdown.show {
      display: block; }
  .cart .cart-container {
    padding: 1.5625rem; }
  .cart .cart-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.75rem;
    border-bottom: 3px solid #ebebeb;
    margin-bottom: 0.9375rem; }
  .cart .cart-close {
    font-size: 1.25rem;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 24px; }
  .cart .cart-body {
    overflow-y: auto;
    height: 16.5625rem;
    padding-right: 15px; }
    .cart .cart-body::-webkit-scrollbar {
      width: 4px;
      background-color: #ccc; }
    .cart .cart-body::-webkit-scrollbar-thumb {
      background-color: #8CB73F; }
    .cart .cart-body ul li + li {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #ebebeb; }
  .cart .cart-product .img {
    display: block;
    width: 3.75rem; }
  .cart .cart-product .caption {
    flex: 1;
    text-align: left;
    padding-left: 1.25rem; }
  .cart .cart-product .name {
    display: block;
    margin-bottom: 10px; }
    .cart .cart-product .name:hover {
      color: #8CB73F; }
  .cart .cart-product .quantity {
    color: #999; }
  .cart .cart-button {
    margin-top: 0.9375rem;
    padding-top: 0.9375rem;
    border-top: 1px solid #ebebeb; }
    .cart .cart-button a {
      height: 2.5rem;
      width: 100%;
      background: #8CB73F;
      color: #fff;
      border-radius: 20px; }
      .cart .cart-button a em {
        margin-left: 0.9375rem; }
  @media screen and (max-width: 576px) {
    .cart {
      position: static; } }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 9.375rem;
  right: 1.25rem; }
  @media (max-width: 768px) {
    .fixed-stuff {
      bottom: 0.5rem;
      right: 0.5rem; } }
  .fixed-stuff ul {
    text-align: center; }
    .fixed-stuff ul li:nth-child(n + 2) {
      margin-top: 0.625rem; }
    .fixed-stuff ul li .btn-circle {
      transition: all 0.3s ease-in-out;
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 999999px;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 768px) {
        .fixed-stuff ul li .btn-circle {
          width: 3rem;
          height: 3rem;
          border-radius: 999999px; } }
      .fixed-stuff ul li .btn-circle em {
        font-size: 1.5rem; }
        @media (max-width: 768px) {
          .fixed-stuff ul li .btn-circle em {
            font-size: 1rem; } }
      .fixed-stuff ul li .btn-circle.back-to-top {
        flex-direction: column;
        background-color: #fff;
        color: #999;
        font-size: 1rem; }
        @media (max-width: 768px) {
          .fixed-stuff ul li .btn-circle.back-to-top {
            font-size: 0.75rem; } }
      .fixed-stuff ul li .btn-circle.cta-phone {
        width: 5rem;
        height: 5rem;
        border-radius: 999999px;
        border: 1px solid #5D873B; }
        @media (max-width: 768px) {
          .fixed-stuff ul li .btn-circle.cta-phone {
            width: 3rem;
            height: 3rem;
            border-radius: 999999px; } }
        .fixed-stuff ul li .btn-circle.cta-phone .inner {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 999999px;
          background-color: #5D873B;
          color: #fff; }
          @media (max-width: 768px) {
            .fixed-stuff ul li .btn-circle.cta-phone .inner {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 999999px; } }
    .fixed-stuff ul li.has-tooltip {
      position: relative; }
      .fixed-stuff ul li.has-tooltip .btn-circle {
        position: relative;
        z-index: 2; }
      .fixed-stuff ul li.has-tooltip .tooltip {
        position: absolute;
        right: 0;
        height: 2.5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #00406D;
        border: 1px solid #FFFFFF;
        color: #fff;
        border-radius: 999px;
        z-index: 1;
        padding: 0;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        font-size: 1.125rem;
        line-height: 1.33333; }
        @media (max-width: 768px) {
          .fixed-stuff ul li.has-tooltip .tooltip {
            font-size: clamp(14px, 1.125rem, 18px); } }
      .fixed-stuff ul li.has-tooltip:hover .tooltip {
        opacity: 1;
        pointer-events: all;
        padding: 0 5rem 0 1.875rem; }

.footer-top {
  padding: 3rem 0;
  border-top: 1px solid #e1e1e1; }
  @media (max-width: 1024px) {
    .footer-top {
      padding: 2rem 0; } }
  .footer-top .container {
    position: relative; }
  .footer-top .pot-bg {
    position: absolute;
    bottom: -6.875rem;
    left: -3.75rem; }
    @media (max-width: 1024px) {
      .footer-top .pot-bg {
        display: none; } }
  .footer-top .item {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .footer-top .item {
        flex-direction: column; } }
    .footer-top .item .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 5.25rem;
      flex: 0 0 5.25rem;
      width: 100%;
      height: 5.25rem;
      border-radius: 50%;
      background-clip: padding-box;
      background-color: #5D873B;
      color: #fff;
      font-size: 2.5rem; }
    .footer-top .item .content {
      flex: 1;
      padding-left: 1rem;
      font-size: 1.125rem; }
      @media (max-width: 768px) {
        .footer-top .item .content {
          text-align: center;
          margin-top: 1rem;
          padding-left: 0; } }

.footer-middle {
  padding: 3.25rem 0 1.5rem; }
  @media (max-width: 1024px) {
    .footer-middle {
      padding: 2rem 0; } }
  .footer-middle .subscribe-title h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem; }
  .footer-middle .subscribe .subscribefrm {
    position: relative; }
    .footer-middle .subscribe .subscribefrm input {
      width: 100%;
      height: 3.125rem;
      border: 1px solid #e1e1e1;
      padding: 1rem 3.75rem 1rem 1.25rem;
      background: transparent;
      color: #fff;
      font-size: 1rem; }
      .footer-middle .subscribe .subscribefrm input::placeholder {
        color: #fff; }
    .footer-middle .subscribe .subscribefrm button {
      padding: 0;
      border: none;
      font: inherit;
      color: inherit;
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 1.25rem;
      font-size: 1.25rem; }
  .footer-middle .social-list ul {
    display: flex;
    align-items: center; }
    .footer-middle .social-list ul > * + * {
      margin-left: 0.5rem; }
    .footer-middle .social-list ul li a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 3.125rem;
      height: 3.125rem;
      border-radius: 999999px;
      border: 1px solid #fff;
      font-size: 1.5rem; }

.footer-bottom {
  position: relative;
  background: linear-gradient(97.17deg, #F0EFEF 0.39%, #EDEAEA 100.44%); }
  .footer-bottom .pot-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 21.35417%;
    z-index: 1; }
    @media (max-width: 1024px) {
      .footer-bottom .pot-bg {
        display: none; } }

.footer-bottom-wrap {
  padding: 2.5rem 0 3.75rem;
  position: relative;
  z-index: 2; }
  .footer-bottom-wrap .footer-item h3 {
    font-size: 1.5rem;
    color: #5D873B;
    font-weight: 700;
    margin-bottom: 1.75rem; }
  .footer-bottom-wrap .footer-info ul > * + * {
    margin-top: 1rem; }
  .footer-bottom-wrap .footer-info ul li {
    display: flex;
    gap: 1rem; }
    .footer-bottom-wrap .footer-info ul li em {
      font-size: 1.125rem;
      flex: 0 0 1.125rem;
      color: #5D873B; }
  .footer-bottom-wrap .footer-info .bct {
    margin-top: 1.75rem; }
  .footer-bottom-wrap .footer-link ul {
    font-size: 1.125rem;
    line-height: 1.5rem; }
    .footer-bottom-wrap .footer-link ul > * + * {
      margin-top: 0.5rem; }
    @media (hover: hover) and (pointer: fine) {
      .footer-bottom-wrap .footer-link ul li a:hover {
        color: #8CB73F; } }
  .footer-bottom-wrap .footer-link.is-2col .footer-link-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-bottom: -0.5rem; }
    .footer-bottom-wrap .footer-link.is-2col .footer-link-row > * {
      padding-left: 1rem;
      padding-bottom: 0.5rem; }
    .footer-bottom-wrap .footer-link.is-2col .footer-link-row .footer-link-1 {
      width: 35%; }
    .footer-bottom-wrap .footer-link.is-2col .footer-link-row .footer-link-2 {
      width: 65%; }

.footer-copyright {
  position: relative;
  z-index: 2;
  padding: 0.75rem 0;
  background-color: #CDB69C;
  color: #fff; }
  .footer-copyright .footer-copyright-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    line-height: 1.42857; }
    @media (max-width: 768px) {
      .footer-copyright .footer-copyright-wrap {
        font-size: clamp(14px, 0.875rem, 14px); } }
    @media (max-width: 1024px) {
      .footer-copyright .footer-copyright-wrap {
        display: block;
        text-align: center; } }

.global-header {
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  background-color: #fff; }

@media (max-width: 1024px) {
  .logo img {
    height: 60px; } }

@media (min-width: 1025px) {
  .logo {
    text-align: center; } }

@media (min-width: 1025px) {
  .menu {
    padding-left: 1.5rem; } }

@media (min-width: 1281px) {
  .menu {
    padding-left: 2.25rem; } }

@media (min-width: 1441px) {
  .menu {
    padding-left: 5rem; } }

@media (min-width: 1025px) {
  .menu > ul {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media (min-width: 1441px) {
  .menu > ul {
    justify-content: flex-start; }
    .menu > ul > * + * {
      margin-left: 4rem; } }

@media (max-width: 1024px) {
  .menu > ul > li:nth-child(n + 2) {
    margin-top: 1.25rem; } }

.menu > ul > li > a {
  display: inline-flex;
  height: 6.25rem;
  align-items: center;
  font-size: 1.125rem;
  line-height: 1.33333;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    .menu > ul > li > a {
      font-size: clamp(14px, 1.125rem, 18px); } }
  @media (max-width: 1024px) {
    .menu > ul > li > a {
      font-size: 1rem;
      height: auto;
      text-align: left;
      justify-content: flex-start;
      color: #fff; } }

.menu > ul > li.active {
  color: #8CB73F; }

@media (hover: hover) and (pointer: fine) {
  .menu > ul > li:hover {
    color: #8CB73F; } }

.has-sub {
  position: relative;
  display: flex;
  align-items: center; }
  .has-sub > span {
    margin-left: 5px; }
  .has-sub .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2; }
    @media (min-width: 1025px) {
      .has-sub .sub-menu {
        min-width: 20rem; } }
    .has-sub .sub-menu ul {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      background-color: #5D873B;
      padding: 0.5rem;
      border-radius: 0.3125rem;
      background-clip: padding-box; }
      .has-sub .sub-menu ul > * + * {
        margin-top: 0.5rem; }
      .has-sub .sub-menu ul li a {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 1rem 2rem 0.75rem;
        transition: all 0.3s ease-in-out;
        color: #fff;
        border-radius: 0.3125rem;
        background-clip: padding-box;
        font-size: 1.125rem;
        font-weight: 700; }
      @media (hover: hover) and (pointer: fine) {
        .has-sub .sub-menu ul li:hover > a {
          background-color: #D0DCB7;
          color: #333; } }
      .has-sub .sub-menu ul li.active > a {
        background-color: #D0DCB7;
        color: #333; }
      .has-sub .sub-menu ul li.has-child {
        position: relative; }
        @media (hover: hover) and (pointer: fine) {
          .has-sub .sub-menu ul li.has-child:hover .child-menu {
            display: block; } }
        .has-sub .sub-menu ul li.has-child .child-menu {
          position: absolute;
          top: 0;
          left: 100%;
          padding-left: 0.75rem;
          display: none; }
  @media (max-width: 1024px) {
    .has-sub {
      justify-content: space-between;
      flex-wrap: wrap; }
      .has-sub > span {
        font-size: 1rem;
        color: #fff;
        position: relative;
        margin-right: 1rem; }
      .has-sub .sub-menu {
        position: static;
        width: 100%;
        border-top: 0;
        box-shadow: none;
        margin-top: 0.5rem; }
        .has-sub .sub-menu ul {
          color: white;
          padding: 0 0 0 1rem;
          background: transparent;
          box-shadow: none; }
          .has-sub .sub-menu ul > * + * {
            margin-top: 0.75rem; }
          .has-sub .sub-menu ul li a {
            font-size: 0.875rem;
            white-space: initial;
            padding: 0;
            color: #fff; }
          .has-sub .sub-menu ul li.active a {
            background: transparent;
            color: #8CB73F; }
          .has-sub .sub-menu ul li.has-child {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap; }
            .has-sub .sub-menu ul li.has-child > span {
              font-size: 0.875rem;
              position: relative;
              margin-right: 0.75rem; }
            .has-sub .sub-menu ul li.has-child .child-menu {
              position: static;
              width: 100%;
              border-top: 0; }
              .has-sub .sub-menu ul li.has-child .child-menu ul {
                box-shadow: none;
                padding: 0.5rem 0 0; }
                .has-sub .sub-menu ul li.has-child .child-menu ul li a {
                  font-size: 1rem;
                  font-weight: 400; } }
  @media (min-width: 1025px) {
    .has-sub .toggle {
      display: none; }
    .has-sub:hover .sub-menu {
      display: block; } }

.header-util, .header-tool {
  display: flex;
  align-items: center; }

.header-util {
  justify-content: flex-end; }

.header-tool {
  padding-left: 1.5rem; }
  .header-tool > * {
    height: 1.5rem;
    display: inline-flex;
    align-items: center; }
  .header-tool > * + * {
    margin-left: 0.75rem;
    padding-left: 0.75rem;
    border-left: 1px solid rgba(51, 51, 51, 0.3); }

@media (max-width: 768px) {
  .search-toggle {
    font-size: 1rem; } }

.language .current {
  display: flex;
  align-items: center;
  justify-content: center; }
  .language .current img {
    height: 1.5rem; }
  .language .current em {
    margin-left: 0.5rem; }
    @media (max-width: 768px) {
      .language .current em {
        font-size: 3.6vw; } }

.language .dropdown-content {
  top: 100%;
  min-width: auto;
  z-index: 2;
  padding-top: 1.25rem; }
  .language .dropdown-content ul {
    padding: 0.5rem; }
    .language .dropdown-content ul > * + * {
      margin-top: 0.25rem; }

.account {
  display: block !important; }
  .account .firstnav > span {
    font-weight: 700;
    white-space: nowrap; }
  .account > a {
    display: flex;
    align-items: center;
    font-size: 0; }
    .account > a::before {
      font-family: 'Font Awesome 6 Pro';
      content: "\f2bd";
      font-weight: 400;
      font-size: 1rem;
      display: inline-block; }
    .account > a span {
      overflow: hidden;
      text-overflow: ellipsis; }
  .account .dropdown-content {
    z-index: 2;
    padding-top: 1.25rem; }
    .account .dropdown-content ul li a {
      white-space: nowrap;
      line-height: 1.5; }
  @media (max-width: 1024px) {
    .account {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      background-color: #fff; }
      .account > a {
        font-size: 0.875rem; }
        .account > a em {
          display: none; }
        .account > a::before {
          font-size: 0.875rem;
          text-align: center;
          margin-right: 12px; }
      .account .dropdown-content {
        right: auto;
        left: 0;
        position: static;
        display: block;
        margin-top: 0; }
        .account .dropdown-content ul {
          padding: 0;
          background: transparent;
          box-shadow: none; }
          .account .dropdown-content ul li.firstnav {
            display: none; }
          .account .dropdown-content ul li a, .account .dropdown-content ul li span {
            font-size: 1rem; } }

@media (max-width: 1024px) {
  .menu-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    line-height: 1;
    background-color: #8CB73F;
    color: #fff; } }

@media (max-width: 768px) {
  .menu-toggle {
    width: 2.5rem !important;
    height: 2.5rem !important; } }

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px); }
  .mobile-menu .mobile-menu-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .mobile-menu .mobile-menu-wrapper {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: relative;
    transform: translateY(-100%);
    transition: 0.3s all;
    pointer-events: none; }
    .mobile-menu.is-open .mobile-menu-wrapper {
      transform: none; }
  .mobile-menu .mobile-menu-container {
    position: absolute;
    pointer-events: all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 88vh;
    width: 94vw;
    background: linear-gradient(135deg, #47a347 50%, #5d873b 100%);
    padding: 1.25rem;
    overflow: auto;
    border-radius: 1.25rem; }
  .mobile-menu .logo-w {
    margin-bottom: 1.25rem; }

.Module-1363 .ActionMenuBody {
  left: auto !important;
  right: 0; }

.search-page {
  padding: 3.75rem 0; }
  @media (max-width: 1024px) {
    .search-page {
      padding: 2.5rem 0; } }
  .search-page h1 {
    margin-bottom: 1.25rem;
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 700;
    color: #333333; }
    @media (max-width: 768px) {
      .search-page h1 {
        font-size: clamp(14px, 2.5rem, 40px); } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.625rem;
      z-index: 1;
      font-size: 1.5rem;
      color: #8CB73F;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.625rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.75rem;
    height: 3.75rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.25rem;
    padding-right: 3.375rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.75rem !important;
    border-radius: 1.875rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    outline: none;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.25rem;
    font-size: 0.875rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresults {
    margin-top: 1.875rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.875rem; }
  .search-page .search-result-wrap {
    margin-top: 1.875rem; }
  .search-page .searchresult {
    margin-bottom: 1.875rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 1.125rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.625rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.625rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.AspNet-TreeView {
  line-height: 1.5; }
  .AspNet-TreeView > ul {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px; }
    .AspNet-TreeView > ul > .AspNet-TreeView-Root {
      margin-bottom: 26px;
      break-inside: avoid-column; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 7px; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
        display: none; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #06c; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
          font-weight: 500;
          display: inline-block; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
          margin-bottom: 7px; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
          list-style: disc;
          padding-left: 1.25em; }
          .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
            font-size: 16px;
            margin-bottom: 7px; }
  @media screen and (max-width: 768.98px) {
    .AspNet-TreeView > ul {
      column-count: 1; } }

.sitemap {
  padding: 2.5rem 0; }
  .sitemap a:hover {
    text-decoration: underline; }

.sitemap-heading {
  height: 3.25rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }
  .sitemap-heading h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.5; }

.faq-list {
  counter-reset: section; }

.faq-item {
  background: #fff;
  overflow: hidden; }
  .faq-item + .faq-item {
    margin-top: 1rem; }
  .faq-item .heading {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(153, 153, 153, 0.5);
    display: flex;
    align-items: center;
    transition: 0.3s all;
    cursor: pointer; }
    .faq-item .heading .number::before {
      counter-increment: section;
      content: counter(section) ". "; }
    .faq-item .heading .arrow {
      position: relative;
      top: 4px;
      font-size: 14px; }
    .faq-item .heading.active {
      color: #8CB73F; }
      .faq-item .heading.active .arrow span:before {
        content: '\f077'; }
  .faq-item .title {
    line-height: 1.5; }
  .faq-item .arrow {
    margin-left: auto;
    transition: 0.3s all; }
  .faq-item .body {
    padding: 1rem 0 0;
    display: none; }
  @media screen and (max-width: 768.98px) {
    .faq-item .heading {
      padding: 0.8125rem 0.9375rem 0.625rem; }
      .faq-item .heading .arrow {
        top: 0px; }
      .faq-item .heading .number {
        margin-right: 0.75rem;
        line-height: 1.15; }
    .faq-item .body {
      padding: 0.9375rem; } }

.policy-content {
  padding: 2.625rem 3.75rem;
  box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2rem; }
  @media (max-width: 768px) {
    .policy-content {
      padding: 1.5rem;
      border-radius: 1rem; } }

@media (min-width: 1025px) {
  .side-news {
    margin-left: -1.875rem;
    margin-bottom: -2.5rem; }
    .side-news > * {
      padding-left: 1.875rem;
      padding-bottom: 2.5rem; } }

.sub-banner-section .banner {
  position: relative; }
  @media (max-width: 768px) {
    .sub-banner-section .banner .img {
      position: relative;
      display: block;
      padding-top: 56.25%; }
      .sub-banner-section .banner .img > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .sub-banner-section .banner .caption {
    position: absolute;
    bottom: 5rem;
    width: 100%;
    color: #2D411B; }
    @media (max-width: 768px) {
      .sub-banner-section .banner .caption {
        top: auto;
        transform: none;
        bottom: 1.25rem; } }
  .sub-banner-section .banner .title {
    font-size: 3.75rem;
    margin-bottom: 1.5rem; }
    @media (max-width: 768px) {
      .sub-banner-section .banner .title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem; } }
  .sub-banner-section .banner .desc {
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 3rem; }
    @media (max-width: 768px) {
      .sub-banner-section .banner .desc {
        font-size: clamp(14px, 2rem, 32px); } }
    @media (max-width: 768px) {
      .sub-banner-section .banner .desc {
        margin-bottom: 0.5rem;
        font-size: 1rem; } }

.news-detail-page .sub-banner-section, .product-detail-page .sub-banner-section, .recruitment-detail-page .sub-banner-section {
  display: none; }

@media (min-width: 1025px) {
  .blog-corner-list .row {
    margin-left: -3.75rem;
    margin-bottom: -3.75rem; }
    .blog-corner-list .row > * {
      padding-left: 3.75rem;
      padding-bottom: 3.75rem; } }

.blog-corner-item {
  background-color: #fff;
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  overflow: hidden; }
  @media (max-width: 768px) {
    .blog-corner-item {
      border-radius: 0.5rem; } }
  .blog-corner-item .caption {
    padding: 2.5rem 2.5rem 5rem; }
    @media (max-width: 768px) {
      .blog-corner-item .caption {
        padding: 1.25rem; } }
  .blog-corner-item .subtitle {
    font-weight: 300;
    color: #5D873B;
    margin-bottom: 0.5rem;
    text-transform: uppercase; }
  .blog-corner-item .title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .blog-corner-item .title {
        font-size: clamp(14px, 1.25rem, 20px); } }
    @media (max-width: 768px) {
      .blog-corner-item .title {
        font-size: 1rem; } }
  .blog-corner-item .brief {
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; }
    @media (min-width: 769px) {
      .blog-corner-item .brief {
        text-align: justify;
        margin-top: 1.5rem; } }
  .blog-corner-item .view-more {
    margin-top: 1rem; }
    @media (min-width: 769px) {
      .blog-corner-item .view-more {
        margin-top: 1.5rem; } }
  .blog-corner-item .img a {
    display: block;
    position: relative;
    display: block;
    padding-top: 74.4186%; }
    .blog-corner-item .img a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .blog-corner-item .img img {
    transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .blog-corner-item:hover .img {
      overflow: hidden; }
      .blog-corner-item:hover .img img {
        transform: scale(1.05); } }

@media (min-width: 1025px) {
  .blog-discover-list .row {
    margin-left: -3.125rem;
    margin-bottom: -1.875rem; }
    .blog-discover-list .row > * {
      padding-left: 3.125rem;
      padding-bottom: 1.875rem; } }

.breadcrumb-wrapper {
  background-color: #fff;
  color: #999; }
  @media (max-width: 1024px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.75rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      font-size: 0.875rem;
      line-height: 1.28571;
      display: flex;
      align-items: center; }
      @media (max-width: 768px) {
        .breadcrumb-wrapper .breadcrumb li a {
          font-size: clamp(14px, 0.875rem, 14px); } }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.75rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "\f054";
        font-family: 'Font Awesome 6 Pro';
        margin-right: 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        position: relative;
        font-weight: 300; }
    .breadcrumb-wrapper .breadcrumb li:first-child a {
      font-size: 0; }
      .breadcrumb-wrapper .breadcrumb li:first-child a:before {
        content: '\f015';
        font-family: 'Font Awesome 6 Pro';
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 300; }
        @media (max-width: 768px) {
          .breadcrumb-wrapper .breadcrumb li:first-child a:before {
            font-size: clamp(14px, 1rem, 16px); } }

.cart-section .card-item + .card-item {
  margin-top: 1.875rem; }

.cart-section .card-item .card-heading {
  padding: 1.25rem 1.5625rem;
  border-bottom: 1px solid #ebebeb; }
  .cart-section .card-item .card-heading .card-title {
    font-size: 1.125rem;
    color: #8CB73F;
    text-transform: uppercase;
    font-weight: 700; }

.cart-section .card-item .card-body {
  padding: 1.5625rem;
  line-height: 1.375; }

.cart-section input[type="checkbox"] {
  appearance: none;
  position: relative;
  display: inline-block;
  height: 13px;
  width: 13px;
  margin: 0;
  background-image: url("../img/checkbox.png");
  background-repeat: no-repeat;
  background-size: contain; }
  .cart-section input[type="checkbox"]:checked {
    background-image: url("../img/checkbox-checked.png"); }

.cart-inner .card-title a {
  display: none; }

.cart-product-list .cart-product-list-heading {
  background: #f3f3f3;
  padding: 0.9375rem;
  border-radius: 5px; }

.cart-product-item {
  padding: 20px 15px; }
  .cart-product-item + .cart-product-item {
    border-top: 1px solid #eeeeee; }
  .cart-product-item .cart-product-item-img {
    width: 6.875rem; }
  .cart-product-item .cart-product-item-name {
    display: block;
    flex: 0 0 50%; }
  .cart-product-item .cart-product-item-content {
    flex: 1;
    line-height: 1.375;
    margin-left: 1rem; }
  .cart-product-item .cart-product-item-caption, .cart-product-item .cart-product-item-price {
    width: 50%; }
  .cart-product-item .cart-product-item-content-left, .cart-product-item .cart-product-item-detail {
    flex: 1; }
  .cart-product-item .cart-product-gift {
    margin-top: 0.625rem;
    padding-top: 0.625rem;
    border-top: 1px solid #999; }
    .cart-product-item .cart-product-gift ul li + li {
      margin-top: 10px; }
    .cart-product-item .cart-product-gift ul li a {
      display: flex;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem; }
      .cart-product-item .cart-product-gift ul li a .img {
        flex: 0 0 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.75rem; }
        .cart-product-item .cart-product-gift ul li a .img img {
          max-width: 100%; }
      .cart-product-item .cart-product-gift ul li a .value {
        margin-top: 0.125rem;
        color: #8CB73F; }
  .cart-product-item .cart-product-item-amount {
    text-align: right;
    margin-left: 1rem; }
    .cart-product-item .cart-product-item-amount .spin-input-wrap {
      border-radius: 5px; }
      .cart-product-item .cart-product-item-amount .spin-input-wrap input {
        width: 3.125rem; }

.cart-add-wishlist.active span:before {
  content: "\EE0E"; }

@media screen and (max-width: 768.98px) {
  .card-item .card-body {
    padding: 0; }
  .cart-product-item {
    flex-wrap: wrap;
    padding: 15px 0; }
    .cart-product-item .cart-product-item-amount {
      flex: 100%;
      text-align: left;
      margin-left: 0;
      margin-top: 0.75rem; }
    .cart-product-item .cart-product-item-caption, .cart-product-item .cart-product-item-price {
      width: 100%; }
    .cart-product-item .cart-product-item-price {
      text-align: left;
      margin-top: 0.5rem; }
      .cart-product-item .cart-product-item-price p {
        justify-content: flex-start;
        text-align: left !important; }
    .cart-product-item .cart-product-item-content-bottom p {
      text-align-last: left; }
    .cart-product-item .cart-product-item-name {
      flex: 0 0 100%; } }

.cart-order-info ul li + li {
  margin-top: 0.75rem; }

.cart-order-info hr {
  overflow: visible;
  text-align: inherit;
  margin: 1.25rem 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

.cart-coupon-input label {
  display: block;
  margin-bottom: 0.75rem; }

.cart-coupon-input .form-inline {
  display: flex;
  border-radius: 5px;
  overflow: hidden; }
  .cart-coupon-input .form-inline input {
    height: 2.5rem;
    border: 1px solid #e1e1e1;
    flex: 1; }
  .cart-coupon-input .form-inline button, .cart-coupon-input .form-inline a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.875rem;
    height: 2.5rem;
    background-color: #8CB73F;
    color: #fff;
    border: 0;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.01rem; }

.cart-coupon-note * + * {
  margin-top: 0.9375rem; }

.cart-group-button {
  justify-content: flex-end;
  margin: -0.3125rem;
  display: flex; }
  .cart-group-button .btn {
    margin: 0.3125rem; }
  @media screen and (max-width: 576px) {
    .cart-group-button {
      flex-wrap: wrap; }
      .cart-group-button .btn {
        width: 100%; } }

@media screen and (max-width: 768.98px) {
  .cart-page header {
    display: none; }
  .cart-page .breadcrumb-wrapper {
    display: none; }
  .cart-page .cart-section {
    padding: 0;
    overflow: hidden; }
    .cart-page .cart-section .container {
      max-width: none;
      padding: 0; }
    .cart-page .cart-section .row {
      margin-bottom: 0;
      margin-left: 0; }
      .cart-page .cart-section .row > * {
        padding-bottom: 0;
        padding-left: 0; }
  .cart-page .card-item {
    border-radius: 0;
    box-shadow: none;
    border-top: 5px solid #e5e5e5; }
    .cart-page .card-item + .card-item {
      margin-top: 0; }
    .cart-page .card-item .card-heading {
      border-bottom: 0;
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px; }
    .cart-page .card-item .card-body {
      padding: 15px; }
  .cart-page .cart-inner {
    border-top: 0; }
    .cart-page .cart-inner .card-heading {
      background: #8CB73F;
      padding-bottom: 1.25rem; }
      .cart-page .cart-inner .card-heading .card-title {
        color: #fff !important;
        text-align: center;
        position: relative; }
        .cart-page .cart-inner .card-heading .card-title a {
          display: block;
          position: absolute;
          font-size: 24px;
          top: 50%;
          transform: translateY(-50%); }
    .cart-page .cart-inner .card-body {
      padding: 0; }
  .cart-page .cart-group-button {
    padding: 20px 0 30px;
    justify-content: center; }
    .cart-page .cart-group-button .btn-back {
      display: none; }
  .cart-page .cart-aside .cart-order-info {
    order: 2; }
    .cart-page .cart-aside .cart-order-info hr {
      margin-left: -15px;
      margin-right: -15px;
      border-top: 5px solid #e5e5e5; }
    .cart-page .cart-aside .cart-order-info .total-price {
      margin-top: 5rem;
      justify-content: flex-end;
      align-items: baseline; }
      .cart-page .cart-aside .cart-order-info .total-price strong {
        font-size: 14px;
        font-weight: 400; }
      .cart-page .cart-aside .cart-order-info .total-price .text-main {
        font-size: 24px;
        margin-left: 20px;
        font-weight: 700; }
  .cart-page .cart-aside .cart-coupon {
    order: 1; } }

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between; }

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; }

@media (max-width: 768px) {
  font-size: 12px; }

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2; }

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2; }

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  margin-bottom: 6px; }

.stepper-item.active {
  font-weight: bold; }

.stepper-item.completed .step-counter {
  background-color: #8CB73F;
  color: #fff; }

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #8CB73F;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3; }

.stepper-item:first-child::before {
  content: none; }

.stepper-item:last-child::after {
  content: none; }

.contact-showroom-bg-2 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (min-width: 1025px) {
    .contact-showroom-bg-2 {
      padding-bottom: 11.25rem !important; } }
  .contact-showroom-bg-2 .subnav-wrap {
    margin-bottom: 1.5rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-2 .subnav-wrap {
        margin-bottom: 2.25rem; } }
  .contact-showroom-bg-2 .subnav ul li a {
    background-color: #f3f3f3; }
  .contact-showroom-bg-2 .text-shadow {
    text-transform: none;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 1.5rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-2 .text-shadow {
        margin-bottom: 3.75rem; } }

.contact-note {
  position: relative; }
  .contact-note p {
    font-size: 2.25rem;
    text-align: center; }
  .contact-note .contact-note-bg-small {
    position: absolute;
    right: 0;
    bottom: -3.25rem; }
    @media (max-width: 1024px) {
      .contact-note .contact-note-bg-small {
        display: none; } }
  .contact-note .contact-note-bg-large {
    position: absolute;
    right: 0;
    bottom: 2.5rem; }
    @media (max-width: 1024px) {
      .contact-note .contact-note-bg-large {
        display: none; } }

.contact-showroom-bg-3 {
  background-color: #fff;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto; }
  @media (min-width: 1025px) {
    .contact-showroom-bg-3 {
      padding-bottom: 16.25rem !important; } }
  .contact-showroom-bg-3 .subnav-wrap {
    margin-bottom: 1.5rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-3 .subnav-wrap {
        margin-bottom: 2.25rem; } }
  .contact-showroom-bg-3 .text-shadow {
    text-transform: none;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 1.5rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-3 .text-shadow {
        margin-bottom: 3.75rem; } }

.contact-showroom-bg-1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .contact-showroom-bg-1 .subnav-wrap {
    margin-bottom: 1.5rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-1 .subnav-wrap {
        margin-bottom: 7.75rem; } }
  @media (min-width: 1025px) {
    .contact-showroom-bg-1 .form-contact {
      padding-right: 1.875rem;
      padding-top: 1.875rem;
      border-right: 1px solid #5D873B; } }
  @media (min-width: 1025px) {
    .contact-showroom-bg-1 .form-info {
      padding-top: 1.875rem; } }
  .contact-showroom-bg-1 .form-info ul > * + * {
    margin-top: 0.75rem; }
    @media (min-width: 1025px) {
      .contact-showroom-bg-1 .form-info ul > * + * {
        margin-top: 1.5rem; } }
  .contact-showroom-bg-1 .form-info ul li {
    display: flex;
    align-items: center; }
    .contact-showroom-bg-1 .form-info ul li .icon {
      max-width: 5.75rem;
      flex: 0 0 5.75rem;
      width: 100%;
      width: 5.75rem;
      height: 5.75rem;
      border-radius: 999999px;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      display: flex; }
      .contact-showroom-bg-1 .form-info ul li .icon img {
        max-width: 60%; }
    .contact-showroom-bg-1 .form-info ul li .text {
      padding-left: 1rem;
      font-size: 1.125rem; }
      .contact-showroom-bg-1 .form-info ul li .text > * + * {
        margin-top: 0.75rem; }

.dealer-wrap .filter-map {
  margin-bottom: 1.5rem; }
  .dealer-wrap .filter-map .form-group {
    border: 0.25rem solid #D0DCB7;
    border-radius: 0.25rem;
    background-clip: padding-box; }
    .dealer-wrap .filter-map .form-group select {
      background-image: url("../img/sl-bg-white.png");
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-color: #5D873B;
      color: #fff;
      border: 0;
      height: 2.5rem; }

.dealer-wrap .dealer-result {
  padding: 1.5rem 1rem;
  border: 1px solid #5D873B;
  border-radius: 0.25rem; }

.dealer-wrap .result-list {
  height: 100%; }
  .dealer-wrap .result-list.result-info {
    height: auto; }
    @media (max-width: 1024px) {
      .dealer-wrap .result-list.result-info {
        display: none; } }
  .dealer-wrap .result-list ul {
    max-height: 25rem;
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 2rem; }
    .dealer-wrap .result-list ul::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .dealer-wrap .result-list ul::-webkit-scrollbar-thumb {
      background: #5D873B; }
    .dealer-wrap .result-list ul::-webkit-scrollbar-track {
      background: #d9d9d9; }
    .dealer-wrap .result-list ul > * + * {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid rgba(93, 135, 59, 0.2); }
    .dealer-wrap .result-list ul li {
      cursor: pointer; }
      @media (min-width: 1025px) {
        .dealer-wrap .result-list ul li {
          padding-left: 1.25rem;
          padding-right: 1.25rem; } }
      .dealer-wrap .result-list ul li p {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 300; }
        @media (max-width: 768px) {
          .dealer-wrap .result-list ul li p {
            font-size: clamp(14px, 1rem, 16px); } }
      .dealer-wrap .result-list ul li .name {
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.55556;
        color: #8CB73F;
        text-transform: uppercase; }
        @media (max-width: 768px) {
          .dealer-wrap .result-list ul li .name {
            font-size: clamp(14px, 1.125rem, 18px); } }

.dealer-wrap .map {
  margin-top: auto; }
  .dealer-wrap .map iframe {
    width: 100%;
    height: 17.5rem; }

.gallery-item {
  display: block;
  position: relative; }
  .gallery-item .gallery-img {
    position: relative;
    display: block;
    padding-top: 62.6087%;
    border-radius: 0.25rem;
    overflow: hidden; }
    .gallery-item .gallery-img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .gallery-item .gallery-title {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1.25rem 2rem 0.75rem 2.5rem;
    font-size: 1.25rem;
    border-radius: 100px 0 0 0;
    background-color: #D0DCB7;
    font-weight: 600;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }

.product-item {
  background-color: #fff;
  border-radius: 0.5rem;
  background-clip: padding-box;
  padding: 0.5rem;
  display: flex;
  flex-direction: column; }
  .product-item .product-img {
    border-radius: 0.5rem;
    background-clip: padding-box;
    background-color: #D0DCB7;
    position: relative; }
    .product-item .product-img .product-discount {
      height: 1.5rem;
      min-width: 2.5rem;
      border-radius: 0.25rem;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      justify-content: center;
      color: #fff;
      text-align: center;
      padding: 0 0.5rem;
      background-color: #5D873B;
      position: absolute;
      top: 0;
      right: 0; }
    .product-item .product-img figure a {
      display: block;
      position: relative;
      display: block;
      padding-top: 100%; }
      .product-item .product-img figure a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .product-item .product-caption {
    padding: 1.5rem 0.75rem;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .product-item .product-caption {
        padding: 0.75rem 0; } }
  .product-item .product-title {
    line-height: 1.3333 !important; }
    @media (max-width: 768px) {
      .product-item .product-title {
        font-size: 1.25rem !important; } }
  .product-item .product-subtitle {
    margin-top: 0.25rem;
    color: #666;
    font-size: 1.25rem;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .product-item .product-subtitle {
        font-size: clamp(14px, 1.25rem, 20px); } }
    @media (max-width: 768px) {
      .product-item .product-subtitle {
        font-size: 1rem; } }
  .product-item .product-brief {
    font-weight: 300;
    margin-top: 0.5rem;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .product-item .product-bottom {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .product-item .product-bottom {
        margin-top: 0.75rem; } }
  .product-item .product-price .price {
    font-size: 1.25rem;
    color: #5D873B;
    transition: all 0.3s ease-in-out; }
  .product-item .product-price .old-price {
    font-size: 0.875rem;
    color: #999;
    margin-left: 0.75rem; }
    @media (max-width: 768px) {
      .product-item .product-price .old-price {
        margin-left: 0; } }
  .product-item .product-buy a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 999999px;
    border: 1px solid #e1e1e1;
    font-size: 0.875rem;
    color: #999;
    transition: all 0.3s ease-in-out; }
  .product-item .product-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    border-radius: 0.5rem;
    font-size: 12px;
    background-color: rgba(208, 220, 183, 0.2);
    color: #5D873B;
    margin: 0 0.75rem;
    margin-top: auto; }
  .product-item .product-deadline {
    display: flex;
    padding: 0.625rem 0.75rem 1.25rem;
    margin-left: -5px; }
    .product-item .product-deadline .count {
      flex: 1;
      padding-left: 5px; }
      .product-item .product-deadline .count > * {
        display: block; }
      .product-item .product-deadline .count .number {
        height: 25px;
        padding-top: 3px;
        background: #5D873B;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .product-item .product-deadline .count .unit {
        text-align: center;
        font-size: 12px;
        color: #5D873B;
        margin-top: 8px;
        font-weight: 400; }
  @media (hover: hover) and (pointer: fine) {
    .product-item:hover .product-price .price {
      color: #CDB69C; }
    .product-item:hover .product-buy a {
      background-color: #5D873B;
      color: #fff; }
      .product-item:hover .product-buy a span:before {
        content: '\2b'; } }

.product-slider .swiper-slide {
  height: auto; }
  .product-slider .swiper-slide .product-item {
    height: 100%; }

.service-gallery-slider .img figure {
  position: relative;
  display: block;
  padding-top: 56.45161%; }
  .service-gallery-slider .img figure > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.service-gallery-slider .swiper-btn {
  margin: 0 -5.625rem; }

.service-contact .wrap-form {
  padding: 3.75rem;
  border-radius: 0.5rem;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("../img/service-bg-5.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (max-width: 768px) {
    .service-contact .wrap-form {
      padding: 1.5rem; } }

.side-nav {
  background-color: #5D873B;
  color: #fff;
  border-radius: 0.5rem;
  background-clip: padding-box;
  overflow: hidden; }
  .side-nav .side-nav-heading {
    padding: 1.75rem 2rem 1.25rem;
    background-color: #2D411B;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1024px) {
      .side-nav .side-nav-heading {
        padding: 1rem 1rem 0.75rem; } }
    @media (max-width: 1024px) {
      .side-nav .side-nav-heading h2 {
        font-size: 1.25rem !important; } }
  .side-nav .side-nav-mobile-toggle {
    font-size: 1.5rem; }
    @media (min-width: 1025px) {
      .side-nav .side-nav-mobile-toggle {
        display: none; } }
  .side-nav .side-nav-body {
    padding: 1.5rem 0.75rem 2rem; }
    @media (max-width: 1024px) {
      .side-nav .side-nav-body {
        display: none; } }
  .side-nav .side-nav-menu ul > * + * {
    margin-top: 0.25rem; }
  .side-nav .side-nav-menu ul > li {
    font-size: 1.125rem;
    line-height: 1.33333; }
    @media (max-width: 768px) {
      .side-nav .side-nav-menu ul > li {
        font-size: clamp(14px, 1.125rem, 18px); } }
    .side-nav .side-nav-menu ul > li .links {
      display: flex;
      border-radius: 0.25rem;
      background-clip: padding-box;
      transition: all 0.3s ease-in-out;
      padding: 0.75rem 1.25rem 0.5rem; }
      @media (hover: hover) and (pointer: fine) {
        .side-nav .side-nav-menu ul > li .links:hover {
          background-color: #D0DCB7;
          color: #333; } }
      .side-nav .side-nav-menu ul > li .links > a {
        display: block;
        width: 100%; }
    .side-nav .side-nav-menu ul > li.active > .links {
      background-color: #D0DCB7;
      color: #333; }
    .side-nav .side-nav-menu ul > li.has-accordion {
      position: relative; }
      .side-nav .side-nav-menu ul > li.has-accordion .links a {
        flex: 1; }
      .side-nav .side-nav-menu ul > li.has-accordion .links .accordion-toggle {
        cursor: pointer;
        position: relative; }
      .side-nav .side-nav-menu ul > li.has-accordion .accordion-menu {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        padding-left: 2.5rem;
        font-weight: 300;
        padding-top: 0.5rem;
        display: none; }
        .side-nav .side-nav-menu ul > li.has-accordion .accordion-menu > ul > * + * {
          margin-top: 0.5rem; }
        .side-nav .side-nav-menu ul > li.has-accordion .accordion-menu > ul > li > a {
          padding: 0;
          font-size: 1rem; }
        .side-nav .side-nav-menu ul > li.has-accordion .accordion-menu > ul > li.active a, .side-nav .side-nav-menu ul > li.has-accordion .accordion-menu > ul > li:hover a {
          text-decoration: underline; }
      .side-nav .side-nav-menu ul > li.has-accordion.active .accordion-menu {
        display: block; }

@media (min-width: 769px) {
  .subnav ul {
    display: flex;
    gap: 1.5rem;
    white-space: nowrap;
    overflow-x: auto;
    justify-content: center; } }

@media (max-width: 768px) {
  .subnav ul {
    display: none;
    padding: 1rem;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1); }
    .subnav ul > * + * {
      margin-top: 1rem; } }

.subnav ul li a {
  display: inline-flex;
  padding: 1rem 2rem 0.75rem;
  border: 1px solid #5D873B;
  align-items: center;
  justify-content: center;
  color: #5D873B;
  border-radius: 0.25rem;
  background-clip: padding-box;
  font-size: 1.125rem;
  line-height: 1.11111;
  transition: all 0.3s ease-in-out; }
  @media (max-width: 768px) {
    .subnav ul li a {
      font-size: clamp(14px, 1.125rem, 18px); } }
  .subnav ul li:hover a {
    background-color: #5D873B;
    color: #fff; }
  .subnav ul li.active a {
    background-color: #5D873B;
    color: #fff; }
  @media (min-width: 1025px) {
    .subnav ul li a {
      min-width: 14.6875rem; } }
  @media (max-width: 768px) {
    .subnav ul li a {
      width: 100%;
      justify-content: flex-start;
      padding: 1rem 1rem 0.75rem;
      font-size: 1rem; } }

@media (min-width: 1025px) {
  .subnav ul.is-center {
    justify-content: center; } }

.sub-nav-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #5D873B;
  color: #fff; }
  @media (min-width: 769px) {
    .sub-nav-mobile {
      display: none; } }

.about-1 {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05); }
  .about-1 .content-wrap {
    position: relative; }
  .about-1 .content .bg-leaf {
    position: absolute;
    top: -3.75rem;
    pointer-events: none; }
    @media (max-width: 1024px) {
      .about-1 .content .bg-leaf {
        display: none; } }
    .about-1 .content .bg-leaf.is-left {
      right: 100%;
      width: 32.6875rem;
      transform: rotate(13.14deg); }
      .about-1 .content .bg-leaf.is-left figure {
        position: relative;
        display: block;
        padding-top: 123.13576%; }
        .about-1 .content .bg-leaf.is-left figure > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .about-1 .content .bg-leaf.is-right {
      left: 100%;
      width: 32.5625rem;
      transform: rotate(-13.14deg); }
      .about-1 .content .bg-leaf.is-right figure {
        position: relative;
        display: block;
        padding-top: 123.41651%; }
        .about-1 .content .bg-leaf.is-right figure > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
  .about-1 .content h2 {
    color: #5D873B; }

@media (max-width: 1024px) {
  .about-2 .item-list-wrap {
    background-image: none !important; } }

@media (min-width: 1025px) {
  .about-2 .item-list-wrap {
    padding-top: 15rem;
    padding-bottom: 2.5rem;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50% 0;
    min-height: 49.75rem; } }

.about-2 .item-list {
  background: #FFFFFF;
  box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  padding: 1rem; }
  @media (min-width: 1025px) {
    .about-2 .item-list {
      padding: 1rem 0 2.5rem; } }
  .about-2 .item-list .item-col:nth-child(n + 2) {
    border-top: 1px solid #8CB73F; }
    @media (min-width: 1025px) {
      .about-2 .item-list .item-col:nth-child(n + 2) {
        border-left: 1px solid #8CB73F;
        border-top: 0; } }
  .about-2 .item-list .item {
    font-size: 1.125rem;
    line-height: 1.33333;
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column; }
    @media (max-width: 768px) {
      .about-2 .item-list .item {
        font-size: clamp(14px, 1.125rem, 18px); } }
    @media (min-width: 1025px) {
      .about-2 .item-list .item {
        padding: 0 2.5rem 1.5rem; } }
    .about-2 .item-list .item .icon {
      width: 7.5rem;
      height: 7.5rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2D411B;
      font-size: 3.75rem;
      background-color: #D0DCB7;
      margin: 0 auto; }
      @media (min-width: 1025px) {
        .about-2 .item-list .item .icon {
          margin: -4.5rem auto 0; } }
    .about-2 .item-list .item .title {
      margin: 1.5rem 0 1rem;
      text-transform: uppercase;
      color: #5D873B; }
    .about-2 .item-list .item .btn-wrap {
      margin-top: auto; }
    .about-2 .item-list .item .content {
      margin-bottom: 2.5rem; }
      @media (max-width: 1024px) {
        .about-2 .item-list .item .content {
          margin-bottom: 1.25rem; } }

.award-slider .swiper-container {
  padding: 0.9375rem;
  margin: -0.9375rem; }

.award-slider .item {
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1); }
  .award-slider .item .img figure {
    position: relative;
    display: block;
    padding-top: 131.21212%; }
    .award-slider .item .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .award-slider .item .title {
    padding: 1.25rem 1.5rem;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.33333;
    text-align: center; }
    @media (max-width: 768px) {
      .award-slider .item .title {
        font-size: clamp(14px, 1.125rem, 18px); } }

.about-4 {
  padding: 7.25rem 0; }
  @media (max-width: 1024px) {
    .about-4 {
      padding: 2.5rem 0; } }

.partner-slider .item {
  position: relative;
  display: block;
  padding-top: 50.45872%;
  background-color: #fff;
  border: 1px solid #e1e1e1; }
  .partner-slider .item > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .partner-slider .item img {
    width: 90%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: none; }

@media (max-width: 1024px) {
  .about-6 {
    margin-top: 2.5rem; } }

@media (min-width: 1025px) {
  .about-6 .section-title {
    margin-bottom: 6.25rem; } }

.about-6 .content-wrap {
  background-color: rgba(205, 182, 156, 0.6);
  position: relative;
  border-radius: 1.25rem; }
  @media (min-width: 1025px) {
    .about-6 .content-wrap {
      border-radius: 1.25rem 0px 0px 1.25rem; }
      .about-6 .content-wrap:before {
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        height: 100%;
        width: 1000px;
        background-color: rgba(205, 182, 156, 0.6); } }
  .about-6 .content-wrap .bg-img {
    position: absolute;
    left: -8rem;
    top: -1.875rem; }
    @media (max-width: 1024px) {
      .about-6 .content-wrap .bg-img {
        display: none; } }

.about-6 .content {
  padding: 2.5rem 1.25rem 1.25rem; }
  @media (min-width: 1025px) {
    .about-6 .content {
      padding: 5.75rem 2.5rem 8rem; } }
  @media (min-width: 1281px) {
    .about-6 .content {
      margin: 0 0 -3.75rem 0; } }

.about-6 .img {
  text-align: center;
  padding: 0 1.25rem 2.5rem; }
  @media (min-width: 1025px) {
    .about-6 .img {
      padding: 2.5rem 0; } }
  @media (min-width: 1281px) {
    .about-6 .img {
      padding: 5rem 7.5rem;
      background-color: #f3f3f3;
      border-radius: 1.25rem;
      margin: -3.75rem 0; } }

@media (min-width: 1025px) {
  .about-5 {
    padding: 0 0 7.5rem 0; } }

.about-9 {
  position: relative; }
  .about-9 .bg-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .about-9 .img {
    border-radius: 3rem 3rem 0px 0px;
    overflow: hidden; }
    @media (max-width: 1024px) {
      .about-9 .img {
        border-radius: 1rem 1rem 0 0; } }

.about-7 {
  padding-top: 2.5rem; }
  @media (min-width: 1281px) {
    .about-7 {
      padding-top: 7.5rem; } }
  .about-7 .content-wrap {
    background-color: #2D411B;
    color: #fff;
    border-radius: 1.25rem;
    padding: 2.5rem 1.5rem;
    position: relative;
    z-index: 2; }
    @media (min-width: 1025px) {
      .about-7 .content-wrap {
        padding: 3.75rem 0;
        border-radius: 0px 1.25rem 1.25rem 0px; }
        .about-7 .content-wrap:before {
          content: "";
          display: block;
          position: absolute;
          right: 100%;
          height: 100%;
          width: 1000px;
          background-color: #2D411B;
          top: 0; } }
    .about-7 .content-wrap .bg-img {
      position: absolute;
      right: -2rem;
      bottom: -9rem;
      width: 34.28571%; }
      @media (max-width: 1024px) {
        .about-7 .content-wrap .bg-img {
          display: none; } }
  .about-7 .item-list {
    margin-top: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.75rem;
    margin-bottom: -3.75rem; }
    .about-7 .item-list > * {
      padding-left: 3.75rem;
      padding-bottom: 3.75rem; }
    @media (max-width: 768px) {
      .about-7 .item-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-bottom: -1.5rem; }
        .about-7 .item-list > * {
          padding-left: 1.5rem;
          padding-bottom: 1.5rem; } }
    @media (max-width: 768px) {
      .about-7 .item-list .item-col {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
    .about-7 .item-list .item .icon {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 999999px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      color: #CDB69C; }
    .about-7 .item-list .item .number {
      margin-top: 1.25rem;
      color: #CDB69C;
      font-weight: 700;
      font-size: 3rem; }
    .about-7 .item-list .item .divider {
      height: 1px;
      background: linear-gradient(270deg, rgba(205, 182, 156, 0) 0%, rgba(216, 185, 24, 0) 0.01%, #CDB69C 95%);
      margin: 1rem 0; }
    .about-7 .item-list .item .title {
      font-size: 1.125rem;
      line-height: 1.33333;
      text-transform: uppercase; }
      @media (max-width: 768px) {
        .about-7 .item-list .item .title {
          font-size: clamp(14px, 1.125rem, 18px); } }

.info-cate-slider .item .img a {
  position: relative;
  display: block;
  padding-top: 100%;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #e1e1e1;
  transition: all 0.3s ease-in-out; }
  .info-cate-slider .item .img a > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.info-cate-slider .item .title {
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2;
  margin-top: 1rem; }
  @media (max-width: 768px) {
    .info-cate-slider .item .title {
      font-size: clamp(14px, 1.25rem, 20px); } }

@media (hover: hover) and (pointer: fine) {
  .info-cate-slider .item:hover .img a {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; } }

@media (max-width: 1024px) {
  .about-8 {
    padding-top: 2.5rem; } }

.about-8 .content-wrap {
  background-color: #f3f3f3;
  position: relative;
  z-index: 1;
  border-radius: 1.25rem;
  padding: 2.5rem 1.5rem; }
  @media (min-width: 1025px) {
    .about-8 .content-wrap {
      border-radius: 0;
      padding: 6.75rem 0 4.5rem;
      margin-top: -4rem; }
      .about-8 .content-wrap:before {
        content: "";
        display: block;
        position: absolute;
        left: 100%;
        height: 100%;
        width: 1000px;
        background-color: #f3f3f3;
        top: 0; } }
  .about-8 .content-wrap .bg-img {
    position: absolute;
    top: 4rem;
    left: -2.5rem;
    z-index: 3; }
    @media (max-width: 1024px) {
      .about-8 .content-wrap .bg-img {
        display: none; } }

.about-8 .item-list-wrap:nth-child(n + 2) {
  margin-top: 2.5rem; }

.about-8 .item-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2.5rem;
  margin-bottom: -2.5rem; }
  .about-8 .item-list > * {
    padding-left: 2.5rem;
    padding-bottom: 2.5rem; }
  .about-8 .item-list .btn:hover {
    background-color: #CDB69C; }
  @media (max-width: 1024px) {
    .about-8 .item-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.25rem;
      margin-bottom: -1.25rem; }
      .about-8 .item-list > * {
        padding-left: 1.25rem;
        padding-bottom: 1.25rem; } }
  @media (max-width: 768px) {
    .about-8 .item-list > * {
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%; }
    .about-8 .item-list .btn {
      width: 100%; } }

.section-blog-3 {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 0 50%; }
  @media (min-width: 1025px) {
    .section-blog-3 .swiper-slide:nth-child(even) {
      padding-top: 3.75rem; } }

.blog-info-detail-image-wrap {
  display: flex;
  padding: 3.5rem 3.75rem;
  background-color: #D0DCB7;
  border-radius: 2rem;
  background-clip: padding-box; }
  @media (max-width: 1024px) {
    .blog-info-detail-image-wrap {
      flex-wrap: wrap-reverse; } }
  @media (max-width: 768px) {
    .blog-info-detail-image-wrap {
      padding: 1.5rem;
      border-radius: 1rem;
      background-clip: padding-box; } }

.blog-info-detail-thumbnail {
  max-width: 100%;
  flex: 0 0 100%;
  width: 100%;
  margin-top: 1rem; }
  @media (min-width: 1025px) {
    .blog-info-detail-thumbnail {
      max-width: 7.5rem;
      flex: 0 0 7.5rem;
      width: 100%;
      margin-top: 0; } }
  .blog-info-detail-thumbnail .swiper-container {
    padding: 4px;
    margin: -4px; }
    @media (min-width: 1025px) {
      .blog-info-detail-thumbnail .swiper-container {
        height: 41rem; } }
  .blog-info-detail-thumbnail .img {
    position: relative;
    display: block;
    padding-top: 100%;
    border-radius: 0.5rem;
    background-clip: padding-box;
    overflow: hidden; }
    .blog-info-detail-thumbnail .img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .blog-info-detail-thumbnail .swiper-slide-thumb-active .img {
    box-shadow: 0px 0px 0px 3px #8cb73f; }
  .blog-info-detail-thumbnail .swiper-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    margin: -2rem 0; }
    .blog-info-detail-thumbnail .swiper-btn.swiper-prev {
      bottom: 100%;
      right: auto;
      left: 50%; }
    .blog-info-detail-thumbnail .swiper-btn.swiper-next {
      top: 100%;
      left: 50%; }

.blog-info-detail-images {
  max-width: 100%;
  flex: 0 0 100%;
  width: 100%; }
  @media (min-width: 1025px) {
    .blog-info-detail-images {
      max-width: calc(100% - 128px);
      flex: 0 0 calc(100% - 128px);
      width: 100%;
      padding-left: 2rem; } }
  .blog-info-detail-images .img {
    position: relative;
    display: block;
    padding-top: 100%;
    border-radius: 0.5rem;
    background-clip: padding-box;
    overflow: hidden; }
    .blog-info-detail-images .img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.blog-info-detail-content {
  padding: 2.5rem 1.75rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  background-clip: padding-box; }
  .blog-info-detail-content hr {
    background: transparent;
    border: 0;
    border-top: 1px solid #d9d9d9;
    margin: 1rem 0; }
  @media (max-width: 768px) {
    .blog-info-detail-content {
      padding: 1.5rem;
      border-radius: 1rem;
      background-clip: padding-box; } }

.blog-info-detail-table {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 768px) {
    .blog-info-detail-table {
      font-size: clamp(14px, 1.125rem, 18px); } }
  .blog-info-detail-table table {
    width: 100%; }
    .blog-info-detail-table table td {
      width: 50%;
      padding: 0.75rem 0; }
      .blog-info-detail-table table td:first-child {
        padding-right: 1rem; }

@media (min-width: 1025px) {
  .blog-info-list .row {
    margin-left: -0.9375rem;
    margin-bottom: -1.875rem; }
    .blog-info-list .row > * {
      padding-left: 0.9375rem;
      padding-bottom: 1.875rem; } }

.blog-info-item {
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-clip: padding-box; }
  .blog-info-item .img {
    border-radius: 0.5rem;
    background-clip: padding-box;
    overflow: hidden; }
    .blog-info-item .img a {
      position: relative;
      display: block;
      padding-top: 100%; }
      .blog-info-item .img a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .blog-info-item .img img {
      transition: all 0.3s ease-in-out; }
  .blog-info-item .caption {
    padding: 1.75rem 0.75rem 1.25rem; }
    @media (max-width: 768px) {
      .blog-info-item .caption {
        padding: 1rem 0.5rem 0.5rem; } }
  .blog-info-item .title {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.33333;
    margin-bottom: 0.5rem; }
    @media (max-width: 768px) {
      .blog-info-item .title {
        font-size: clamp(14px, 1.5rem, 24px); } }
    @media (max-width: 768px) {
      .blog-info-item .title {
        font-size: 1.125rem; } }
  .blog-info-item .brief {
    font-size: 1.125rem;
    line-height: 1.33333;
    font-weight: 300;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
    @media (max-width: 768px) {
      .blog-info-item .brief {
        font-size: clamp(14px, 1.125rem, 18px); } }
    @media (max-width: 768px) {
      .blog-info-item .brief {
        font-size: 1rem; } }
  @media (hover: hover) and (pointer: fine) {
    .blog-info-item:hover .img {
      overflow: hidden; }
      .blog-info-item:hover .img img {
        transform: scale(1.05); } }

.blog-detail {
  padding: 3.75rem;
  box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  position: relative; }

.blog-detail-util .detail-social {
  text-align: center;
  margin-left: 1rem; }
  @media (min-width: 1441px) {
    .blog-detail-util .detail-social {
      margin-left: 0;
      margin-top: 1.25rem; } }
  .blog-detail-util .detail-social a {
    margin: 0 auto; }

@media (max-width: 1440px) {
  .blog-detail-util {
    display: flex;
    align-items: center;
    margin-top: 1rem; } }

@media (min-width: 1441px) {
  .blog-detail-util {
    position: absolute;
    right: 100%;
    top: 3.75rem; } }

.blog-detail-heading {
  padding-bottom: 1rem;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 2rem; }

.tips-search {
  position: relative; }
  .tips-search button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 1rem;
    color: #666; }
  .tips-search input {
    height: 3rem;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 1rem 1.25rem 0.75rem;
    border: 0;
    font-size: 1.125rem; }

.tip-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  display: flex;
  flex-direction: column; }
  @media (hover: hover) and (pointer: fine) {
    .tip-item:hover .tip-title {
      text-decoration: underline; } }
  .tip-item .tip-img {
    position: relative; }
    .tip-item .tip-img a {
      display: block;
      position: relative;
      display: block;
      padding-top: 114.37908%; }
      .tip-item .tip-img a > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .tip-item .tip-caption {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 0%; }
    .tip-item .tip-caption .btn {
      margin-top: auto; }
    @media (max-width: 768px) {
      .tip-item .tip-caption {
        padding: 1.25rem; } }
  .tip-item .tip-title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .tip-item .tip-title {
        font-size: clamp(14px, 1.25rem, 20px); } }
  .tip-item .tip-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.33333;
    text-align: justify;
    margin-top: 1rem;
    margin-bottom: 3rem; }
    @media (max-width: 768px) {
      .tip-item .tip-brief {
        font-size: clamp(14px, 1.125rem, 18px); } }
    @media (max-width: 768px) {
      .tip-item .tip-brief {
        margin-bottom: 1rem;
        margin-top: 0;
        text-align: left;
        font-size: 0.875rem;
        line-height: 1.42857; } }
  @media (max-width: 768px) and (max-width: 768px) {
    .tip-item .tip-brief {
      font-size: clamp(14px, 0.875rem, 14px); } }
  .tip-item .blog-date {
    position: absolute;
    bottom: 0;
    left: 1rem; }
  .tip-item .view-more {
    margin-top: auto; }
  .tip-item.is-news .tip-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
  .tip-item.is-news .tip-img a {
    position: relative;
    display: block;
    padding-top: 66.46707%; }
    .tip-item.is-news .tip-img a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .tip-item.is-news .tip-brief {
    margin-bottom: 1rem;
    margin-top: 0.5rem; }
  .tip-item.is-news .tip-caption {
    padding: 1.25rem 1rem; }
  .tip-item.is-news .view-more {
    margin-top: 0; }
  .tip-item.is-big .tip-img a {
    position: relative;
    display: block;
    padding-top: 61.7284%; }
    .tip-item.is-big .tip-img a > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .tip-item.is-row {
    display: flex;
    flex-direction: row; }
    .tip-item.is-row .tip-img {
      max-width: 36.75%;
      flex: 0 0 36.75%;
      width: 100%; }
      .tip-item.is-row .tip-img a {
        position: relative;
        display: block;
        padding-top: 100%; }
        .tip-item.is-row .tip-img a > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .tip-item.is-row .tip-caption {
      flex: 1; }
    .tip-item.is-row .tip-brief {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
    @media (max-width: 1024px) {
      .tip-item.is-row .tip-caption {
        padding: 0.5rem 1rem !important; }
      .tip-item.is-row .tip-brief {
        margin-top: 0 !important; } }

.blog-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  background-color: #5D873B;
  color: #fff;
  font-weight: 700;
  text-align: center;
  pointer-events: none; }
  .blog-date .day {
    font-size: 1.875rem; }
  .blog-date .month {
    font-size: 0.75rem; }

.tips-slider .swiper-slide, .blog-slider .swiper-slide {
  height: auto; }
  .tips-slider .swiper-slide .tip-item, .blog-slider .swiper-slide .tip-item {
    height: 100%; }

.blog-discover-cate-slider .swiper-slide {
  height: auto; }

.blog-discover-cate-slider .item {
  height: 100%;
  display: block;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-clip: padding-box; }
  .blog-discover-cate-slider .item .img {
    border-radius: 0.5rem;
    background-clip: padding-box;
    overflow: hidden; }
    .blog-discover-cate-slider .item .img figure {
      position: relative;
      display: block;
      padding-top: 100%; }
      .blog-discover-cate-slider .item .img figure > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .blog-discover-cate-slider .item .img img {
      transition: all 0.3s ease-in-out; }
  .blog-discover-cate-slider .item .title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.2;
    margin-top: 1rem; }
    @media (max-width: 768px) {
      .blog-discover-cate-slider .item .title {
        font-size: clamp(14px, 1.25rem, 20px); } }
    @media (max-width: 768px) {
      .blog-discover-cate-slider .item .title {
        font-size: 1rem; } }
  @media (hover: hover) and (pointer: fine) {
    .blog-discover-cate-slider .item:hover .img {
      overflow: hidden; }
      .blog-discover-cate-slider .item:hover .img img {
        transform: scale(1.05); } }

.career-detail-brief table {
  width: 41.25rem; }
  @media (max-width: 768px) {
    .career-detail-brief table {
      width: 100%; } }
  .career-detail-brief table tbody > * + * {
    border-top: 1px solid #e1e1e1; }
  .career-detail-brief table tr td {
    padding: 1rem 0 0.5rem; }
    .career-detail-brief table tr td:first-child {
      font-weight: 700; }
    .career-detail-brief table tr td:nth-child(n + 2) {
      font-weight: 300;
      padding-left: 1rem; }

.career-detail-content {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300; }
  @media (max-width: 768px) {
    .career-detail-content {
      font-size: clamp(14px, 1rem, 16px); } }
  .career-detail-content h3 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.2;
    color: #666;
    margin-bottom: 0.5rem; }
    @media (max-width: 768px) {
      .career-detail-content h3 {
        font-size: clamp(14px, 1.25rem, 20px); } }
    .career-detail-content h3:before {
      content: '•';
      margin-right: 0.5rem; }
  .career-detail-content * + h3 {
    margin-top: 1rem; }

.btn-group {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -1.25rem; }
  .btn-group > * {
    margin-right: 1.25rem;
    margin-bottom: 1.25rem; }
  .btn-group .btn {
    min-width: 12.5rem; }
  .btn-group span {
    display: block;
    text-align: center; }
  @media (max-width: 768px) {
    .btn-group {
      display: block; }
      .btn-group > * {
        width: 100%; } }

.career-detail-other {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f3f3f3; }
  .career-detail-other .other-title {
    padding: 1rem 1.25rem 0.5rem;
    border-radius: 0.25rem;
    background-color: #D0DCB7;
    font-size: 1.25rem;
    font-weight: 600; }
  .career-detail-other .other-item-list {
    padding: 1.5rem 0.5rem; }
    .career-detail-other .other-item-list > * + * {
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid #e1e1e1; }
  .career-detail-other .other-item h3 {
    line-height: 1.5;
    margin-bottom: 0.5rem; }
  .career-detail-other .other-item ul {
    display: flex; }
    .career-detail-other .other-item ul > * {
      width: 50%; }
    .career-detail-other .other-item ul li {
      display: flex;
      font-weight: 300;
      font-size: 0.875rem;
      line-height: 1.42857;
      color: #999; }
      @media (max-width: 768px) {
        .career-detail-other .other-item ul li {
          font-size: clamp(14px, 0.875rem, 14px); } }
      .career-detail-other .other-item ul li span {
        margin-right: 0.75rem;
        font-size: 1rem; }

.t-ff109 {
  font-size: 16px; }

.apply-frm {
  position: relative;
  margin: 0;
  background: url(../img/career-popup-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  line-height: 1.375;
  padding: 3.25rem 1.875rem;
  overflow: hidden; }
  @media (max-width: 576px) {
    .apply-frm {
      padding: 16px; } }
  .apply-frm .text-heading-4 {
    font-size: 2em; }
  .apply-frm .form-group .label {
    display: none; }
  .apply-frm .form-group textarea {
    height: 8em; }
  .apply-frm .frm-btnwrap {
    margin-top: 0; }
  .apply-frm .attachfile1 {
    position: relative; }
    .apply-frm .attachfile1 .label {
      display: flex;
      align-items: center;
      height: 3.125em;
      padding: 0 2em;
      background-color: #999;
      color: #333;
      cursor: pointer; }
    .apply-frm .attachfile1 .ruBrowse {
      display: none; }
    .apply-frm .attachfile1 .ruInputs {
      position: static !important; }
      .apply-frm .attachfile1 .ruInputs li {
        margin-bottom: 0;
        height: auto; }
    .apply-frm .attachfile1 .ruFileWrap {
      height: auto;
      position: static !important; }

.recruitment-detail-page .fancybox-iframe {
  width: 869px !important; }

.checkout-complete-section {
  padding: 40px 0; }
  .checkout-complete-section .card-item .card-heading {
    padding: 1.25rem 1.5625rem;
    border-bottom: 1px solid #ebebeb; }
    .checkout-complete-section .card-item .card-heading .card-title {
      font-size: 1.125rem;
      color: #2aba2a;
      text-transform: uppercase;
      display: flex;
      align-items: center; }
      .checkout-complete-section .card-item .card-heading .card-title .text {
        font-weight: 700;
        margin-left: 0.75rem; }
  .checkout-complete-section .card-item .card-body {
    padding: 3.125rem; }
  .checkout-complete-section .cart-group-button {
    justify-content: flex-start;
    margin: 0;
    margin-top: 1.875rem; }
    .checkout-complete-section .cart-group-button .btn {
      margin: 0; }

@media screen and (max-width: 768.99px) {
  .checkout-complete-page .breadcrumb-wrapper {
    border-bottom: 1px solid #eeeeee; }
    .checkout-complete-page .breadcrumb-wrapper .container {
      max-width: none; }
  .checkout-complete-page .checkout-complete-section {
    padding: 0 !important; }
    .checkout-complete-page .checkout-complete-section .container {
      padding: 0;
      max-width: none; }
    .checkout-complete-page .checkout-complete-section .row {
      margin-bottom: 0;
      margin-left: 0; }
      .checkout-complete-page .checkout-complete-section .row > * {
        padding-bottom: 0;
        padding-left: 0; }
    .checkout-complete-page .checkout-complete-section .card-item {
      border-radius: 0;
      box-shadow: none; }
      .checkout-complete-page .checkout-complete-section .card-item .card-heading {
        border-bottom: 0;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px; }
      .checkout-complete-page .checkout-complete-section .card-item .card-body {
        padding: 20px 15px; }
    .checkout-complete-page .checkout-complete-section .img {
      margin-bottom: 1.5625rem; }
    .checkout-complete-page .checkout-complete-section .btn-next {
      width: 100%; } }

.checkout-section .card-item + .card-item {
  margin-top: 1.875rem; }

.checkout-section .card-item .card-heading {
  padding: 1.25rem 1.5625rem;
  border-bottom: 1px solid #ebebeb; }
  .checkout-section .card-item .card-heading .card-title {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700; }

.checkout-section .card-item .card-body {
  padding: 1.5625rem; }

.checkout-info-form .form-group {
  display: flex;
  align-items: center; }
  .checkout-info-form .form-group + .form-group {
    margin-top: 20px; }
  .checkout-info-form .form-group label {
    white-space: nowrap;
    flex: 1 1 0%; }
    .checkout-info-form .form-group label.error {
      color: #dd0705;
      font-size: 12px;
      margin-top: 4px;
      font-style: italic; }
  .checkout-info-form .form-group .form-control {
    flex: 3 1 0%; }

.checkout-info-form .add-new-address a {
  color: #068fdd;
  text-decoration: underline; }

@media screen and (max-width: 576px) {
  .checkout-info-form .form-group {
    flex-wrap: wrap; }
    .checkout-info-form .form-group label {
      flex: 0 0 100%;
      margin-bottom: 10px; }
    .checkout-info-form .form-group .form-control {
      flex: 0 0 100%; } }

.checkout-shipping-wrap {
  margin-top: 1.875rem; }
  @media screen and (max-width: 768.98px) {
    .checkout-shipping-wrap {
      margin-top: 0; } }

.checkout-check-list {
  padding-left: 0;
  list-style: none; }

.checkout-check-item {
  cursor: pointer; }
  .checkout-check-item + .checkout-check-item {
    margin-top: 20px; }
  .checkout-check-item label {
    display: flex;
    align-items: center; }
  .checkout-check-item input[type="radio"], .checkout-check-item input[type="checkbox"] {
    position: absolute;
    height: 1px;
    width: 1px;
    visibility: hidden;
    clip: rect(0px, 0px, 0px, 0px);
    opacity: 0; }
    .checkout-check-item input[type="radio"]:checked + .fake-radio::before, .checkout-check-item input[type="checkbox"]:checked + .fake-radio::before {
      content: "\F050";
      color: #8CB73F; }
  .checkout-check-item .fake-radio::before {
    font-family: remixicon !important;
    content: "\EB7D";
    margin-right: 0.75rem;
    font-size: 1rem;
    color: #999999; }
  .checkout-check-item .content {
    width: calc( 100% - 30px);
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    display: flex; }
    .checkout-check-item .content .img {
      width: 100px;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #e1e1e1; }
    .checkout-check-item .content .text-wrap {
      flex: 1;
      display: flex;
      align-items: center; }
    .checkout-check-item .content .text {
      padding: 15px 20px;
      line-height: 1.375;
      flex: 1; }
      .checkout-check-item .content .text * + * {
        margin-top: 10px; }
      .checkout-check-item .content .text table {
        width: 100%; }
        .checkout-check-item .content .text table tr td:first-child {
          width: 33.33%; }
        .checkout-check-item .content .text table tr td:last-child {
          padding-left: 10px; }
      .checkout-check-item .content .text.address-item * + * {
        margin-top: 0; }
    .checkout-check-item .content .services {
      display: flex;
      align-items: center;
      padding-right: 20px; }
      .checkout-check-item .content .services select {
        appearance: none;
        background-image: url(../img/sl-checkout.png);
        background-position: 95%;
        background-repeat: no-repeat;
        font-size: 1rem;
        width: 15.625rem;
        height: 35px;
        border: 0;
        border-bottom: 2px solid #e1e1e1;
        padding: 0 15px; }
  .checkout-check-item .vnpay-type-group {
    margin-left: 30px;
    margin-top: 1.25rem;
    padding: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 5px; }
    .checkout-check-item .vnpay-type-group .vnpay-type-group-title {
      line-height: 1.375;
      font-weight: 700; }
    .checkout-check-item .vnpay-type-group.vnpay-type-group-dropdown .vnpay-type-group-title {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .checkout-check-item .vnpay-type-group.vnpay-type-group-dropdown .vnpay-type-group-title i {
        font-size: 1.25rem; }
    .checkout-check-item .vnpay-type-group.vnpay-type-group-dropdown .bank-list {
      display: none; }
  .checkout-check-item .bank-list {
    padding-top: 10px; }
    .checkout-check-item .bank-list .row {
      margin-left: -10px !important;
      margin-bottom: -10px !important; }
      .checkout-check-item .bank-list .row > * {
        padding-left: 10px !important;
        padding-bottom: 10px !important; }
  .checkout-check-item .select-bank {
    cursor: pointer; }
    .checkout-check-item .select-bank .content {
      padding-top: 66.66%;
      position: relative;
      width: 100%;
      border-style: dashed; }
      .checkout-check-item .select-bank .content img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 90%;
        height: auto;
        transform: translate(-50%, -50%); }
    .checkout-check-item .select-bank input[type="radio"]:checked + .content, .checkout-check-item .select-bank input[type="checkbox"]:checked + .content {
      border-color: #8CB73F; }
  @media screen and (max-width: 576px) {
    .checkout-check-item .content .services select {
      width: 150px; } }

.checkout-issue-invoice-wrap .checkout-check-item .content {
  border: 0; }

.checkout-issue-invoice-wrap .card-body {
  display: none; }
  .checkout-issue-invoice-wrap .card-body.show {
    display: block; }

.Module-194 {
  height: 100%; }
  .Module-194 .ModuleContent {
    position: sticky;
    top: 100px;
    z-index: 3; }

.checkout-cart-summary .cart-summary-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb; }
  .checkout-cart-summary .cart-summary-item .product-detail-promo {
    width: 100%; }
  .checkout-cart-summary .cart-summary-item .product-link {
    display: flex;
    width: 50%; }
    .checkout-cart-summary .cart-summary-item .product-link .img {
      width: 50px;
      margin-right: 10px; }
    .checkout-cart-summary .cart-summary-item .product-link .text {
      flex: 1;
      line-height: 1.375; }
  .checkout-cart-summary .cart-summary-item .product-price {
    text-align: right; }
    .checkout-cart-summary .cart-summary-item .product-price * + * {
      margin-top: 5px; }
    .checkout-cart-summary .cart-summary-item .product-price .total-price {
      font-size: 1.125rem; }

.checkout-cart-summary .cart-summary-price hr {
  overflow: visible;
  text-align: inherit;
  margin: 1.25rem 0;
  border: 0;
  border-top: 1px solid #e5e5e5; }

.checkout-cart-summary .cart-summary-price ul li + li {
  margin-top: 1rem; }

.checkout-point-wrap .point-slider {
  height: 5px;
  border: 0 !important;
  background: #bcbcbc;
  margin: 0 5px 10px; }
  .checkout-point-wrap .point-slider .ui-slider-handle {
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border: 1px solid #666666;
    background-color: #fff; }
  .checkout-point-wrap .point-slider .ui-slider-range {
    background-color: #8CB73F;
    border: 0; }

.checkout-point-wrap .point-slider-input label {
  flex: 1; }

.checkout-point-wrap .point-slider-input input {
  width: 156px;
  background: #fafafa;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin-left: 10px;
  padding: 0 15px;
  height: 40px; }

@media screen and (max-width: 768.99px) {
  .checkout-page .breadcrumb-wrapper, .cartpage .breadcrumb-wrapper {
    border-bottom: 1px solid #eeeeee; }
    .checkout-page .breadcrumb-wrapper .container, .cartpage .breadcrumb-wrapper .container {
      max-width: none; }
  .checkout-page .checkout-section, .cartpage .checkout-section {
    padding: 0 !important; }
    .checkout-page .checkout-section .container, .cartpage .checkout-section .container {
      padding: 0;
      max-width: none; }
    .checkout-page .checkout-section .row, .cartpage .checkout-section .row {
      margin-bottom: 0;
      margin-left: 0; }
      .checkout-page .checkout-section .row > *, .cartpage .checkout-section .row > * {
        padding-bottom: 0;
        padding-left: 0; }
    .checkout-page .checkout-section .card-item, .cartpage .checkout-section .card-item {
      border-radius: 0;
      box-shadow: none;
      border-top: 5px solid #e5e5e5; }
      .checkout-page .checkout-section .card-item + .card-item, .cartpage .checkout-section .card-item + .card-item {
        margin-top: 0; }
      .checkout-page .checkout-section .card-item .card-heading, .cartpage .checkout-section .card-item .card-heading {
        border-bottom: 0;
        padding-bottom: 0;
        padding-left: 15px;
        padding-right: 15px; }
      .checkout-page .checkout-section .card-item .card-body, .cartpage .checkout-section .card-item .card-body {
        padding: 20px 15px; }
  .checkout-page .checkout-issue-invoice-wrap, .cartpage .checkout-issue-invoice-wrap {
    padding-bottom: 20px; }
  .checkout-page .checkout-info-form .form-group, .cartpage .checkout-info-form .form-group {
    flex-direction: column;
    align-items: start; }
    .checkout-page .checkout-info-form .form-group label, .cartpage .checkout-info-form .form-group label {
      display: block;
      margin-bottom: 10px;
      flex: auto;
      font-size: 14px; }
    .checkout-page .checkout-info-form .form-group .form-control, .cartpage .checkout-info-form .form-group .form-control {
      flex: auto;
      width: 100%; }
  .checkout-page .checkout-check-item .content .img, .cartpage .checkout-check-item .content .img {
    display: none; }
  .checkout-page .cart-group-button, .cartpage .cart-group-button {
    padding: 15px;
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 15px 0 rgba(18, 51, 104, 0.1);
    z-index: 2224;
    margin: 0; }
    .checkout-page .cart-group-button > *, .cartpage .cart-group-button > * {
      margin: 0; }
    .checkout-page .cart-group-button .btn-next, .cartpage .cart-group-button .btn-next {
      width: 100%; }
    .checkout-page .cart-group-button .btn-back, .cartpage .cart-group-button .btn-back {
      display: none; }
    .checkout-page .cart-group-button .cart-summary-total-price, .cartpage .cart-group-button .cart-summary-total-price {
      margin-bottom: 10px;
      justify-content: flex-end;
      align-items: baseline; }
      .checkout-page .cart-group-button .cart-summary-total-price strong, .cartpage .cart-group-button .cart-summary-total-price strong {
        font-size: 16px;
        font-weight: 400; }
      .checkout-page .cart-group-button .cart-summary-total-price .text-main, .cartpage .cart-group-button .cart-summary-total-price .text-main {
        font-size: 24px;
        font-weight: 700;
        margin-left: 20px; } }

.cart-aside, .checkout-cart-summary {
  line-height: 1.25; }

.cart-login {
  width: 31.25rem;
  max-width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  margin: 0 auto; }
  .cart-login .login-heading {
    padding: 1.25rem 1.5625rem 0.75rem;
    border-bottom: 1px solid #ebebeb;
    font-size: 1.125rem;
    color: #8CB73F;
    text-transform: uppercase;
    font-weight: 700; }
  .cart-login .login-form {
    padding: 1.5625rem; }
    .cart-login .login-form .select-group {
      line-height: 1.375; }
      .cart-login .login-form .select-group ul li, .cart-login .login-form .select-group ul li > span {
        display: flex;
        align-items: center; }
        .cart-login .login-form .select-group ul li label, .cart-login .login-form .select-group ul li > span label {
          margin-left: 0.5rem; }
        .cart-login .login-form .select-group ul li input, .cart-login .login-form .select-group ul li > span input {
          position: relative;
          margin: 0; }
    .cart-login .login-form .module-title {
      text-transform: uppercase;
      margin-top: 0.75rem;
      margin-bottom: 0.625rem;
      font-weight: 700; }
    .cart-login .login-form .form-group + .form-group {
      margin-top: 25px; }
    .cart-login .login-form .form-group label {
      display: block;
      margin-bottom: 10px; }
    .cart-login .login-form .form-group input[type="text"], .cart-login .login-form .form-group input[type="password"] {
      width: 100%;
      height: 3.125rem;
      border-radius: 5px;
      border: 1px solid #e1e1e1;
      background-color: #fafafa;
      padding: 0 20px; }
      .cart-login .login-form .form-group input[type="text"]::placeholder, .cart-login .login-form .form-group input[type="password"]::placeholder {
        color: #999999; }
    .cart-login .login-form .form-group.form-link {
      display: flex;
      justify-content: space-between; }
    .cart-login .login-form .form-group .register-link, .cart-login .login-form .form-group #ctl00_mainContent_ctl00_LoginCtrl_lnkPasswordRecovery {
      font-size: 14px; }
    .cart-login .login-form .form-group #ctl00_mainContent_ctl00_LoginCtrl_lnkRegisterExtraLink {
      color: #00c1ef;
      text-decoration: underline; }
    .cart-login .login-form input[type="submit"] {
      height: 3.125rem;
      width: 100%;
      background: #fff;
      border-radius: 5px;
      border: 2px solid #8CB73F;
      color: #8CB73F;
      font-weight: 700;
      margin-top: 1.25rem;
      font-size: 1rem;
      cursor: pointer; }
  .cart-login .social-login {
    padding: 0 1.5625rem 1.5625rem; }
    .cart-login .social-login a {
      width: 100%;
      color: #fff;
      font-size: 14px; }
      .cart-login .social-login a + a {
        margin-top: 20px; }
      .cart-login .social-login a.facebook-login {
        background: #4267B2; }
      .cart-login .social-login a.google-login {
        background: #df4a32; }

.home-banner .banner {
  position: relative; }
  @media (min-width: 769px) {
    .home-banner .banner .img, .home-banner .banner .video {
      height: 53.75rem; }
      .home-banner .banner .img > *, .home-banner .banner .video > * {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (max-width: 768px) {
    .home-banner .banner .img, .home-banner .banner .video {
      position: relative;
      display: block;
      padding-top: 56.25%; }
      .home-banner .banner .img > *, .home-banner .banner .video > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .home-banner .banner .caption {
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    color: #fff; }
    @media (max-width: 768px) {
      .home-banner .banner .caption {
        top: auto;
        transform: none;
        bottom: 1.25rem; } }
  .home-banner .banner .title {
    font-size: 3.75rem;
    margin-bottom: 1.5rem; }
    @media (max-width: 768px) {
      .home-banner .banner .title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem; } }
  .home-banner .banner .desc {
    font-weight: 300;
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 3rem; }
    @media (max-width: 768px) {
      .home-banner .banner .desc {
        font-size: clamp(14px, 2rem, 32px); } }
    @media (max-width: 768px) {
      .home-banner .banner .desc {
        margin-bottom: 0.5rem;
        font-size: 1rem; } }
    .home-banner .banner .desc .btn {
      font-size: 1rem;
      margin-top: 0.75rem; }

.home-banner .swiper-pagination {
  bottom: 3rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  .home-banner .swiper-pagination > * + * {
    margin-left: 0.5rem; }
  .home-banner .swiper-pagination .swiper-pagination-bullet {
    height: 0.25rem;
    width: 1.25rem;
    background-color: #fff;
    border-radius: 0;
    transition: all 0.3s ease-in-out; }
    .home-banner .swiper-pagination .swiper-pagination-bullet-active {
      width: 3.75rem; }

.section-home-2 {
  position: relative; }
  .section-home-2 .content-container {
    padding: 2.5rem 0; }
    @media (min-width: 1281px) {
      .section-home-2 .content-container {
        padding: 0;
        position: absolute;
        width: 100%;
        top: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  @media (min-width: 1281px) {
    .section-home-2 .article-content {
      padding-right: 5rem; } }
  @media (min-width: 1281px) {
    .section-home-2 .img {
      height: 36.25rem; }
      .section-home-2 .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .section-home-2 .item-list {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -3.75rem;
    margin-bottom: -3.75rem; }
    .section-home-2 .item-list > * {
      padding-left: 3.75rem;
      padding-bottom: 3.75rem; }
    @media (max-width: 768px) {
      .section-home-2 .item-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-bottom: -1.5rem; }
        .section-home-2 .item-list > * {
          padding-left: 1.5rem;
          padding-bottom: 1.5rem; } }
    @media (max-width: 768px) {
      .section-home-2 .item-list .item-col {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }
    .section-home-2 .item-list .item .icon {
      width: 4.5rem;
      height: 4.5rem;
      border-radius: 999999px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      color: #CDB69C; }
    .section-home-2 .item-list .item .number {
      margin-top: 1.25rem;
      color: #CDB69C;
      font-weight: 700;
      font-size: 3rem; }
    .section-home-2 .item-list .item .divider {
      height: 1px;
      background: linear-gradient(270deg, rgba(205, 182, 156, 0) 0%, rgba(216, 185, 24, 0) 0.01%, #CDB69C 95%);
      margin: 1rem 0; }
    .section-home-2 .item-list .item .title {
      font-size: 1.125rem;
      line-height: 1.33333;
      text-transform: uppercase; }
      @media (max-width: 768px) {
        .section-home-2 .item-list .item .title {
          font-size: clamp(14px, 1.125rem, 18px); } }

.section-home-3 .ModuleContent {
  height: 100%; }

.home-service-cate {
  height: 100%; }
  .home-service-cate > * {
    height: 100%; }
  .home-service-cate .item {
    height: 100%;
    display: block;
    position: relative; }
    .home-service-cate .item .img {
      height: 100%; }
      @media (min-width: 1025px) {
        .home-service-cate .item .img figure {
          height: 100%; }
          .home-service-cate .item .img figure img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
      @media (max-width: 1024px) {
        .home-service-cate .item .img figure {
          position: relative;
          display: block;
          padding-top: 125%; }
          .home-service-cate .item .img figure > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    .home-service-cate .item .title {
      position: absolute;
      width: 100%;
      bottom: 3.5rem;
      color: #fff;
      text-align: center;
      left: 0;
      padding: 0 1rem;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.33333; }
      @media (max-width: 768px) {
        .home-service-cate .item .title {
          font-size: clamp(14px, 1.5rem, 24px); } }
      @media (max-width: 768px) {
        .home-service-cate .item .title {
          bottom: 1.25rem;
          font-size: 1.25rem; } }

.product-new-list {
  padding: 4.5rem 5rem; }
  @media (max-width: 1440px) {
    .product-new-list {
      padding: 3.25rem 3rem; } }
  @media (max-width: 1024px) {
    .product-new-list {
      padding: 2.5rem 0.9375rem; } }
  .product-new-list .product-slider .swiper-btn {
    margin: 0 -1.875rem;
    background-color: #fff;
    color: #999; }

.career-list {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-clip: padding-box;
  background-color: #f3f3f3; }
  @media (max-width: 768px) {
    .career-list {
      overflow: auto; } }
  .career-list table {
    width: 100%; }
    @media (max-width: 768px) {
      .career-list table {
        width: 150vw; } }
    .career-list table thead {
      border-radius: 0.25rem;
      background-clip: padding-box; }
    .career-list table th {
      font-size: 1.25rem;
      line-height: 1.4;
      text-transform: uppercase;
      background-color: #D0DCB7;
      padding: 1rem 6rem 0.5rem; }
      @media (max-width: 768px) {
        .career-list table th {
          font-size: clamp(14px, 1.25rem, 20px); } }
      @media (max-width: 768px) {
        .career-list table th {
          padding: 1rem 1rem 0.5rem; } }
      .career-list table th:first-child {
        text-align: left; }
    @media (hover: hover) and (pointer: fine) {
      .career-list table tr:hover td {
        color: #8CB73F; } }
    .career-list table td {
      padding: 1rem 6rem 0.5rem;
      text-align: center;
      color: #666; }
      .career-list table td:first-child {
        text-align: left; }
      @media (max-width: 768px) {
        .career-list table td {
          padding: 1rem 1rem 0.5rem; } }
    .career-list table tbody > * + * {
      border-top: 1px solid #e1e1e1; }

.section-home-4 {
  position: relative; }
  .section-home-4 .content-container {
    padding: 2.5rem 0; }
    @media (min-width: 1281px) {
      .section-home-4 .content-container {
        padding: 0;
        position: absolute;
        width: 100%;
        top: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  @media (min-width: 1281px) {
    .section-home-4 .content-wrap {
      padding-right: 3rem; } }
  .section-home-4 .img {
    display: block;
    position: relative; }
    @media (min-width: 1281px) {
      .section-home-4 .img {
        height: 36.25rem; }
        .section-home-4 .img img, .section-home-4 .img iframe {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
    .section-home-4 .img .icon {
      position: absolute;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7.625rem; }
      .section-home-4 .img .icon img {
        width: 100%; }
  .section-home-4 .item-list {
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    margin-left: -2.25rem;
    margin-bottom: -2.25rem; }
    .section-home-4 .item-list > * {
      padding-left: 2.25rem;
      padding-bottom: 2.25rem; }
    @media (max-width: 768px) {
      .section-home-4 .item-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.5rem;
        margin-bottom: -1.5rem; }
        .section-home-4 .item-list > * {
          padding-left: 1.5rem;
          padding-bottom: 1.5rem; } }
    .section-home-4 .item-list .item-col {
      max-width: 33.33%;
      flex: 0 0 33.33%;
      width: 100%; }
      @media (max-width: 768px) {
        .section-home-4 .item-list .item-col {
          max-width: 50%;
          flex: 0 0 50%;
          width: 100%; } }
    .section-home-4 .item-list .item .icon {
      width: 5.625rem;
      height: 5.625rem;
      border-radius: 999999px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      color: #CDB69C; }
    .section-home-4 .item-list .item .title {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.33333;
      text-transform: uppercase;
      margin-top: 1.5rem; }
      @media (max-width: 768px) {
        .section-home-4 .item-list .item .title {
          font-size: clamp(14px, 1.125rem, 18px); } }
    .section-home-4 .item-list .item .brief {
      font-weight: 400;
      font-size: 1.125rem;
      line-height: 1.33333;
      margin-top: 0.75rem; }
      @media (max-width: 768px) {
        .section-home-4 .item-list .item .brief {
          font-size: clamp(14px, 1.125rem, 18px); } }

.section-home-5 {
  padding: 80px 0 7.5rem;
  background-position: bottom !important; }
  @media (max-width: 1024px) {
    .section-home-5 {
      padding: 2.5rem 0; } }

.section-home-6 {
  background: linear-gradient(97.17deg, #F0EFEF 0.39%, #EDEAEA 100.44%); }

.home-plant-slider .item {
  padding-bottom: 3.75rem;
  position: relative; }
  @media (min-width: 1281px) {
    .home-plant-slider .item .img {
      width: 67.16418%;
      border-radius: 0.5rem;
      background-clip: padding-box; } }
  .home-plant-slider .item .img figure {
    position: relative;
    display: block;
    padding-top: 68.88889%;
    overflow: hidden; }
    .home-plant-slider .item .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .home-plant-slider .item .caption {
    background-color: #fff;
    padding: 1.5rem; }
    @media (min-width: 1281px) {
      .home-plant-slider .item .caption {
        padding: 3.75rem 2rem;
        border-radius: 0.5rem;
        background-clip: padding-box;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 46.26866%; } }
    .home-plant-slider .item .caption .subtitle {
      color: #5D873B;
      text-transform: uppercase;
      margin-bottom: 0.5rem; }
    .home-plant-slider .item .caption .title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.2;
      margin-bottom: 1.25rem; }
      @media (max-width: 768px) {
        .home-plant-slider .item .caption .title {
          font-size: clamp(14px, 1.25rem, 20px); } }
    .home-plant-slider .item .caption .brief {
      font-weight: 300;
      font-size: 1.125rem;
      line-height: 1.33333;
      margin-bottom: 2rem;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-weight: 300; }
      @media (max-width: 768px) {
        .home-plant-slider .item .caption .brief {
          font-size: clamp(14px, 1.125rem, 18px); } }

@media (max-width: 1024px) {
  .section-home-8 {
    padding-top: 0 !important; } }

.section-home-8 .partner-slider .row {
  margin-left: -1.25rem;
  margin-bottom: -1.25rem; }
  .section-home-8 .partner-slider .row > * {
    padding-left: 1.25rem;
    padding-bottom: 1.25rem; }

.product-list-heading {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media (max-width: 768px) {
    .product-list-heading {
      display: block; } }

.product-list-util {
  display: flex;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-bottom: -2rem; }
  .product-list-util > * {
    padding-left: 2rem;
    padding-bottom: 2rem; }
  @media (max-width: 768px) {
    .product-list-util {
      margin-left: -1rem;
      margin-bottom: -1rem;
      margin-top: 1rem; }
      .product-list-util > * {
        padding-left: 1rem;
        padding-bottom: 1rem; }
      .product-list-util > * {
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%; } }

.product-sort {
  display: flex;
  align-items: center; }
  @media (max-width: 768px) {
    .product-sort {
      display: block; } }
  .product-sort label {
    margin-right: 1.25rem; }
    @media (max-width: 768px) {
      .product-sort label {
        margin-right: 0;
        margin-bottom: 0.5rem;
        display: inline-block; } }
  .product-sort .form-group {
    border: 0.25rem solid #D0DCB7;
    border-radius: 0.25rem;
    background-clip: padding-box; }
    .product-sort .form-group select {
      background-image: url("../img/sl-bg-white.png");
      background-repeat: no-repeat;
      background-position: calc(100% - 0.75rem) 50%;
      background-color: #5D873B;
      color: #fff;
      border: 0;
      height: 2.5rem;
      padding-right: 3rem !important; }

.product-detail-image-wrap {
  padding: 2.5rem 3.75rem;
  border-radius: 2rem;
  background-clip: padding-box;
  background-color: #D0DCB7; }
  @media (max-width: 768px) {
    .product-detail-image-wrap {
      padding: 1.5rem;
      border-radius: 1rem;
      background-clip: padding-box; } }

.product-detail-images .img a {
  position: relative;
  display: block;
  padding-top: 82.57108%;
  border-radius: 0.5rem;
  background-clip: padding-box;
  overflow: hidden; }
  .product-detail-images .img a > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.product-detail-images .img.video {
  position: relative; }
  .product-detail-images .img.video .fal {
    position: absolute;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3.75rem;
    z-index: 2;
    color: white;
    width: auto;
    height: auto; }

.product-detail-thumbnail {
  margin-top: 1.5rem; }
  @media (max-width: 768px) {
    .product-detail-thumbnail {
      margin-top: 0.75rem; } }
  .product-detail-thumbnail .swiper-container {
    padding: 4px;
    margin: -4px; }
  .product-detail-thumbnail .img {
    position: relative;
    display: block;
    padding-top: 100%;
    border-radius: 0.5rem;
    background-clip: padding-box;
    overflow: hidden; }
    .product-detail-thumbnail .img > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .product-detail-thumbnail .img.video {
      position: relative; }
      .product-detail-thumbnail .img.video .fal {
        position: absolute;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        z-index: 2;
        color: white;
        width: auto;
        height: auto; }
  .product-detail-thumbnail .swiper-slide-thumb-active .img {
    box-shadow: 0px 0px 0px 3px #8cb73f; }
  .product-detail-thumbnail .swiper-btn {
    margin: 0 -1.5rem; }

.product-detail-content {
  padding: 2.5rem 1.75rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  background-clip: padding-box; }
  .product-detail-content hr {
    background: transparent;
    border: 0;
    border-top: 1px solid #d9d9d9;
    margin: 1rem 0; }
  @media (max-width: 768px) {
    .product-detail-content {
      padding: 1.5rem;
      border-radius: 1rem;
      background-clip: padding-box; } }

.product-detail-price .price {
  font-size: 1.5rem;
  color: #5D873B;
  transition: all 0.3s ease-in-out; }

.product-detail-price .old-price {
  color: #999;
  margin-left: 0.75rem; }
  @media (max-width: 768px) {
    .product-detail-price .old-price {
      margin-left: 0; } }

.product-detail-flash-sale {
  display: flex;
  align-items: center;
  height: 2.5rem;
  background: #8CB73F;
  color: #fff;
  position: relative;
  padding: 8px 1.25rem;
  margin-top: 0.75rem; }
  .product-detail-flash-sale .deal-countdown {
    display: flex;
    margin-left: auto; }
    .product-detail-flash-sale .deal-countdown .count {
      display: flex;
      align-items: center; }
      .product-detail-flash-sale .deal-countdown .count .separator {
        padding: 0 3px; }
      .product-detail-flash-sale .deal-countdown .count .number {
        width: 2.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 0.3125rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: #5D873B;
        line-height: 1.25; }
  @media screen and (max-width: 1280.98px) {
    .product-detail-flash-sale {
      display: block;
      min-height: 40px;
      height: auto; }
      .product-detail-flash-sale .deal-countdown {
        margin-top: 0.875rem; } }
  @media screen and (max-width: 1024.98px) {
    .product-detail-flash-sale {
      max-width: 300px;
      text-align: center;
      margin: 0 auto; }
      .product-detail-flash-sale .deal-countdown {
        justify-content: center;
        margin-top: 5px; } }

.product-detail-full-content {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.42857; }
  @media (max-width: 768px) {
    .product-detail-full-content {
      font-size: clamp(14px, 0.875rem, 14px); } }
  .product-detail-full-content > * + * {
    margin-top: 0.25rem; }
  .product-detail-full-content h3 {
    color: #999;
    font-weight: 400; }
  .product-detail-full-content ul {
    padding-left: 0.75rem;
    list-style: disc; }
  .product-detail-full-content .whole-price {
    padding: 0.75rem 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    background-clip: padding-box;
    display: inline-block;
    font-weight: 400;
    color: #5D873B; }

.product-detail-ship {
  margin-top: 2rem;
  color: #999; }
  .product-detail-ship span {
    color: #5D873B;
    margin-right: 0.75rem; }

.product-detail-variant {
  margin-top: 1rem; }
  .product-detail-variant > * + * {
    margin-top: 1rem; }
  .product-detail-variant .variant-group {
    display: flex;
    align-items: center; }
    .product-detail-variant .variant-group .group-title {
      font-size: 0.875rem;
      color: #999;
      max-width: 15%;
      flex: 0 0 15%;
      width: 100%; }
    .product-detail-variant .variant-group .option-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.75rem;
      margin-bottom: -0.75rem; }
      .product-detail-variant .variant-group .option-list > * {
        padding-left: 0.75rem;
        padding-bottom: 0.75rem; }
      .product-detail-variant .variant-group .option-list a {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        height: 2rem;
        border-radius: 0.25rem;
        background-clip: padding-box;
        padding: 0 0.75rem;
        font-weight: 300;
        color: #D0DCB7;
        transition: all 0.3s ease-in-out;
        border: 1px solid transparent; }
        .product-detail-variant .variant-group .option-list a.active {
          color: #8CB73F;
          border: 1px solid #8CB73F; }

.product-detail-quantity strong {
  white-space: nowrap; }

.product-detail-purchase {
  margin-top: 2rem; }
  .product-detail-purchase .btn-add-to-cart {
    display: inline-flex;
    align-items: center;
    padding-left: 3rem;
    padding-top: 0.25rem;
    height: 2.5rem;
    border-radius: 2.5rem 0;
    background-color: #5D873B;
    color: #fff;
    width: 12.5rem;
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease-in-out; }
    .product-detail-purchase .btn-add-to-cart:hover {
      background-color: #CDB69C; }
    .product-detail-purchase .btn-add-to-cart em {
      position: absolute;
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 999999px;
      border: 0.25rem solid #fff;
      right: -1rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: #8CB73F;
      color: #fff;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center; }

.spin-input-wrap {
  display: flex; }
  .spin-input-wrap .btn-spin, .spin-input-wrap .spin-btn {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e1e1e1;
    color: #666;
    border-radius: 0.25rem;
    background-clip: padding-box;
    cursor: pointer; }
  .spin-input-wrap input {
    margin: 0 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    background-clip: padding-box;
    border: 0;
    text-align: center;
    height: 2rem;
    width: 6.25rem; }

.detail-attr .tabs-nav {
  white-space: nowrap;
  overflow: auto; }
  .detail-attr .tabs-nav li {
    display: inline-block;
    font-size: 0px; }
    .detail-attr .tabs-nav li:first-child a {
      padding-left: 4rem;
      border-radius: 2rem 0 0 0; }
    .detail-attr .tabs-nav li a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      background-color: #8CB73F;
      color: #fff;
      padding: 0.75rem 2rem 0.5rem;
      height: 3rem; }
      .detail-attr .tabs-nav li.active a {
        background-color: #2D411B; }

.detail-attr .tab-content {
  padding: 3rem 3.75rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 2rem 2rem; }
  .detail-attr .tab-content h4 {
    color: #5D873B; }
  @media (max-width: 768px) {
    .detail-attr .tab-content {
      padding: 1.5rem; } }

[id*="popup-"], [id*="popup_"] {
  width: 69.375rem;
  padding: 0; }
  [id*="popup-"] .popup-form-wrap, [id*="popup_"] .popup-form-wrap {
    background: #D0DCB7; }
    [id*="popup-"] .popup-form-wrap .img, [id*="popup_"] .popup-form-wrap .img {
      max-width: 40.54054%;
      flex: 0 0 40.54054%;
      width: 100%; }
      @media (max-width: 768px) {
        [id*="popup-"] .popup-form-wrap .img, [id*="popup_"] .popup-form-wrap .img {
          display: none; } }
    [id*="popup-"] .popup-form-wrap .popup-form, [id*="popup_"] .popup-form-wrap .popup-form {
      flex: 1;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 2.5rem;
      line-height: 1.333333; }
      @media (max-width: 768px) {
        [id*="popup-"] .popup-form-wrap .popup-form, [id*="popup_"] .popup-form-wrap .popup-form {
          padding: 2rem; } }
      [id*="popup-"] .popup-form-wrap .popup-form::before, [id*="popup_"] .popup-form-wrap .popup-form::before {
        content: "";
        display: block;
        position: absolute;
        width: 7.5625rem;
        height: 6.875rem;
        background-image: url(../img/popup-bg-1.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1; }
        @media (max-width: 768px) {
          [id*="popup-"] .popup-form-wrap .popup-form::before, [id*="popup_"] .popup-form-wrap .popup-form::before {
            display: none; } }
      [id*="popup-"] .popup-form-wrap .popup-form::after, [id*="popup_"] .popup-form-wrap .popup-form::after {
        content: "";
        display: block;
        position: absolute;
        width: 18.75rem;
        height: 13.75rem;
        background-image: url(../img/popup-bg-2.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1; }
      [id*="popup-"] .popup-form-wrap .popup-form h2 > *, [id*="popup_"] .popup-form-wrap .popup-form h2 > * {
        display: block; }
        [id*="popup-"] .popup-form-wrap .popup-form h2 > * + *, [id*="popup_"] .popup-form-wrap .popup-form h2 > * + * {
          margin-top: 1rem; }
      [id*="popup-"] .popup-form-wrap .popup-form .form-group input[type="text"], [id*="popup-"] .popup-form-wrap .popup-form .form-group input[type="date"], [id*="popup-"] .popup-form-wrap .popup-form .form-group select, [id*="popup-"] .popup-form-wrap .popup-form .form-group textarea, [id*="popup_"] .popup-form-wrap .popup-form .form-group input[type="text"], [id*="popup_"] .popup-form-wrap .popup-form .form-group input[type="date"], [id*="popup_"] .popup-form-wrap .popup-form .form-group select, [id*="popup_"] .popup-form-wrap .popup-form .form-group textarea {
        background: white;
        border: 0px; }
      [id*="popup-"] .popup-form-wrap .popup-form .row, [id*="popup_"] .popup-form-wrap .popup-form .row {
        margin-left: -1.25rem;
        margin-bottom: -1.25rem; }
        [id*="popup-"] .popup-form-wrap .popup-form .row > *, [id*="popup_"] .popup-form-wrap .popup-form .row > * {
          padding-left: 1.25rem;
          padding-bottom: 1.25rem; }
      [id*="popup-"] .popup-form-wrap .popup-form .frm-btnwrap, [id*="popup_"] .popup-form-wrap .popup-form .frm-btnwrap {
        margin-top: 1.25rem; }

.service-select-popup {
  width: 53.4375rem;
  padding: 0; }
  .service-select-popup .popup-content {
    min-height: 13.25rem;
    background-image: url(../img/service-popup-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem; }
    .service-select-popup .popup-content .service-select {
      text-align: center; }
      .service-select-popup .popup-content .service-select h3 {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 1.25rem; }
        @media (max-width: 768px) {
          .service-select-popup .popup-content .service-select h3 {
            font-size: clamp(14px, 1.25rem, 20px); } }
      .service-select-popup .popup-content .service-select ul {
        display: flex;
        justify-content: center;
        gap: 2rem; }
      .service-select-popup .popup-content .service-select a {
        min-width: 12.5rem;
        height: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1;
        color: #2D411B;
        text-transform: uppercase; }
        @media (max-width: 768px) {
          .service-select-popup .popup-content .service-select a {
            font-size: clamp(14px, 1rem, 16px); } }
        @media (max-width: 576px) {
          .service-select-popup .popup-content .service-select a {
            min-width: 9.375rem; } }

.noti-purchase-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 50;
  width: 100%; }
  .noti-purchase-wrap .noti-purchase-content {
    background: #FFFFFF;
    box-shadow: 0px -6px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0.625rem 0.625rem 0px 0px;
    line-height: 1.33333;
    padding: 1.25rem;
    position: relative; }
    @media (min-width: 577px) {
      .noti-purchase-wrap .noti-purchase-content {
        padding: 2rem 3.375rem 2rem 1.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between; } }
  .noti-purchase-wrap .noti-purchase-text {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4; }
    @media (max-width: 768px) {
      .noti-purchase-wrap .noti-purchase-text {
        font-size: clamp(14px, 1.25rem, 20px); } }
    @media (min-width: 577px) {
      .noti-purchase-wrap .noti-purchase-text {
        margin-bottom: 0;
        padding-right: 1.875rem; } }
  .noti-purchase-wrap .product-detail-purchase {
    margin-top: 0; }
  .noti-purchase-wrap .noti-close {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    font-size: 1.5rem;
    cursor: pointer; }

@media (max-width: 1024px) {
  .service-business-1 .bg-img {
    margin-bottom: 1rem; } }

@media (min-width: 1025px) {
  .service-business-1 .bg-img figure {
    height: 14.375rem; } }

.service-business-1 .bg-img figure img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@media (min-width: 1025px) {
  .service-business-1 .item-list {
    margin: -6.5rem auto 0; } }

@media (min-width: 1281px) {
  .service-business-1 .item-list {
    width: 1140px; } }

.service-business-1 .item-list .item .img figure {
  width: 13rem;
  height: 13rem;
  border-radius: 999999px;
  border: 0.5rem solid #fff;
  overflow: hidden;
  margin: 0 auto; }
  @media (max-width: 576px) {
    .service-business-1 .item-list .item .img figure {
      width: 10rem;
      height: 10rem;
      border-radius: 999999px; } }
  .service-business-1 .item-list .item .img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.service-business-1 .item-list .item .title {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.33333;
  margin-top: 0.75rem;
  text-align: center; }
  @media (max-width: 768px) {
    .service-business-1 .item-list .item .title {
      font-size: clamp(14px, 1.125rem, 18px); } }

.service-business-2 .item .icon {
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 999999px;
  background-color: #8CB73F;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin: 0 auto;
  padding-top: 0.5rem; }

.service-business-2 .item .title {
  padding: 1.25rem 1.75rem 0.75rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  text-align: center;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 768px) {
    .service-business-2 .item .title {
      font-size: clamp(14px, 1.125rem, 18px); } }

.service-business-2 .bg-img {
  margin-top: 7.5rem; }
  @media (max-width: 1024px) {
    .service-business-2 .bg-img {
      margin-top: 2rem; } }

.section-business-3 .item-list .item {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem 1.25rem; }
  .section-business-3 .item-list .item .title {
    font-size: 4.75rem;
    color: #8CB73F; }
    .section-business-3 .item-list .item .title sub {
      font-size: 0.875rem; }
  .section-business-3 .item-list .item .title, .section-business-3 .item-list .item .img {
    height: 7.5rem; }
  .section-business-3 .item-list .item .img figure {
    height: 100%; }
  .section-business-3 .item-list .item .img img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .section-business-3 .item-list .item .desc {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.33333;
    margin-top: 1rem; }
    @media (max-width: 768px) {
      .section-business-3 .item-list .item .desc {
        font-size: clamp(14px, 1.125rem, 18px); } }

.project-detail-hero {
  padding: 3.75rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2rem; }
  @media (max-width: 768px) {
    .project-detail-hero {
      border-radius: 1rem;
      padding: 1.25rem; } }
  .project-detail-hero figure {
    border-radius: 2rem;
    overflow: hidden; }
    @media (max-width: 768px) {
      .project-detail-hero figure {
        border-radius: 1rem; } }

.project-detail-table {
  padding: 3rem 5rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 2rem 2rem;
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 768px) {
    .project-detail-table {
      font-size: clamp(14px, 1.125rem, 18px); } }
  @media (max-width: 768px) {
    .project-detail-table {
      padding: 1.25rem;
      margin-top: 1.25rem;
      border-radius: 1rem; } }
  .project-detail-table table {
    width: 100%; }
    .project-detail-table table tr:nth-child(n + 2) {
      border-top: 1px solid #d9d9d9; }
    .project-detail-table table tr td {
      padding: 1.25rem 0 0.75rem; }
      .project-detail-table table tr td:first-child {
        width: 40%;
        color: #666; }
      .project-detail-table table tr td:nth-child(n + 2) {
        font-weight: 300; }

.project-detail-gallery .img {
  transition: all 0.3s ease-in-out;
  background: #000; }
  .project-detail-gallery .img figure {
    transition: all 0.3s ease-in-out;
    position: relative;
    display: block;
    padding-top: 58.89145%;
    opacity: 0.6; }
    .project-detail-gallery .img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }

@media (min-width: 1025px) {
  .project-detail-gallery .swiper-slide .img {
    transform: scale(0.7); }
  .project-detail-gallery .swiper-slide-next, .project-detail-gallery .swiper-slide-prev {
    z-index: 2; }
    .project-detail-gallery .swiper-slide-next .img, .project-detail-gallery .swiper-slide-prev .img {
      transform: scale(0.85); }
  .project-detail-gallery .swiper-slide-active {
    z-index: 3; }
    .project-detail-gallery .swiper-slide-active .img {
      transform: scale(1); }
      .project-detail-gallery .swiper-slide-active .img figure {
        opacity: 1; } }

.project-detail-gallery .swiper-navigation {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 860px;
  z-index: 4; }
  .project-detail-gallery .swiper-navigation .swiper-btn {
    margin: 0 -2rem;
    background-color: #999;
    color: #fff;
    border: 0; }

.service-personal-2 .item .img figure {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 999999px;
  overflow: hidden;
  margin: 0 auto; }
  .service-personal-2 .item .img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.service-personal-2 .item .title {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 300;
  margin-top: 1.5rem; }
  @media (max-width: 768px) {
    .service-personal-2 .item .title {
      font-size: clamp(14px, 1.125rem, 18px); } }
  @media (max-width: 768px) {
    .service-personal-2 .item .title {
      margin-top: 0.75rem; } }

@media (max-width: 1600px) {
  .service-share-wrap {
    display: flex;
    align-items: center; } }

.service-share-wrap .btn-share {
  width: 5.25rem;
  height: 5.25rem;
  border-radius: 999999px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(93, 135, 59, 0.5);
  color: #fff;
  font-size: 2rem;
  margin-right: 1rem;
  flex: 0 0 5.25rem; }
  @media (min-width: 1601px) {
    .service-share-wrap .btn-share {
      position: absolute;
      right: 100%;
      margin-right: 0; } }
  .service-share-wrap .btn-share span {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 999999px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5D873B; }

.service-personal-1 .item .icon {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 999999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #8CB73F;
  font-size: 3rem;
  color: #fff; }

.service-personal-1 .item .title {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.33333;
  font-weight: 300;
  margin-top: 1.5rem; }
  @media (max-width: 768px) {
    .service-personal-1 .item .title {
      font-size: clamp(14px, 1.125rem, 18px); } }
  @media (max-width: 768px) {
    .service-personal-1 .item .title {
      margin-top: 0.75rem; } }

.project-filter-wrap {
  padding: 1rem 2rem;
  border: 1px solid #e1e1e1; }
  @media (max-width: 768px) {
    .project-filter-wrap {
      padding: 1rem; } }

.project-filter {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5rem;
  margin-bottom: -1.5rem; }
  .project-filter > * {
    padding-left: 1.5rem;
    padding-bottom: 1.5rem; }
  .project-filter .form-group {
    display: flex;
    align-items: center;
    max-width: 46%;
    flex: 0 0 46%;
    width: 100%; }
    @media (max-width: 768px) {
      .project-filter .form-group {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        flex-direction: column;
        align-items: flex-start; } }
    .project-filter .form-group label {
      margin-right: 0.75rem;
      white-space: nowrap;
      font-size: 1.125rem; }
      @media (max-width: 768px) {
        .project-filter .form-group label {
          margin-bottom: 0.5rem; } }
    .project-filter .form-group select {
      height: 2.5rem;
      border-color: #e1e1e1;
      background-image: url("../img/sl-small.png");
      background-repeat: no-repeat;
      background-position: calc(100% - 1.25rem) 50%; }
  .project-filter .form-submit {
    flex: 1; }
  .project-filter .btn-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    flex: 0 0 100%;
    border-radius: 2.5rem;
    background-color: #8CB73F;
    color: #fff;
    font-size: 1.125rem;
    text-transform: uppercase;
    padding-top: 0.25rem; }

.project-item {
  display: block;
  position: relative;
  padding: 2rem 1.25rem;
  box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  background-color: #fff; }
  @media (max-width: 576px) {
    .project-item {
      padding: 0.75rem;
      border-radius: 0.75rem; } }
  @media (hover: hover) and (pointer: fine) {
    .project-item:hover .project-title {
      text-decoration: underline; } }
  .project-item .project-img figure {
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    display: block;
    padding-top: 65.63981%; }
    .project-item .project-img figure > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .project-item .project-title {
    padding: 1.25rem 2.5rem;
    background-color: #2D411B;
    color: #fff;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: 600;
    position: absolute;
    bottom: 1.25rem;
    left: 0;
    right: 0;
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .project-item .project-title {
        font-size: clamp(14px, 1.25rem, 20px); } }
    @media (max-width: 576px) {
      .project-item .project-title {
        bottom: 0;
        padding: 1rem;
        font-size: 1rem; } }
    .project-item .project-title span {
      padding-right: 1.25rem; }
    .project-item .project-title em {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 2.5rem 0px;
      padding-right: 0.125rem;
      background-color: #5D873B;
      color: #fff;
      font-size: 0.75rem; }

.project-slider .swiper-container {
  padding: 20px;
  margin: -20px; }

.service-personal-3 .section-heading {
  padding: 2rem 0.5rem;
  background-color: #2D411B;
  color: #fff;
  text-transform: uppercase;
  position: relative; }
  .service-personal-3 .section-heading:before {
    content: '';
    position: absolute;
    width: 43.95833%;
    height: 17.875rem;
    background-image: url(../img/service-bg-1.png);
    background-repeat: no-repeat;
    background-size: contain;
    top: -3.75rem;
    pointer-events: none; }
    @media (max-width: 1024px) {
      .service-personal-3 .section-heading:before {
        display: none; } }
  .service-personal-3 .section-heading:after {
    content: '';
    position: absolute;
    width: 44.73958%;
    height: 20.0625rem;
    background-image: url(../img/service-bg-2.png);
    background-repeat: no-repeat;
    background-size: contain;
    top: -3.75rem;
    pointer-events: none;
    right: 0; }
    @media (max-width: 1024px) {
      .service-personal-3 .section-heading:after {
        display: none; } }

.article-content {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 768px) {
    .article-content {
      font-size: clamp(14px, 1.125rem, 18px); } }
  .article-content > * + * {
    margin-top: 1rem; }
  .article-content ul {
    list-style: disc;
    padding-left: 1.25rem; }
  .article-content table td, .article-content table th {
    border: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top; }
  .article-content h2 {
    font-size: 1.25rem;
    font-weight: 700; }
  .article-content h3 {
    font-size: 1.125rem;
    font-weight: 600; }
  .article-content * + h2 {
    margin-top: 1.75rem; }

.expand-content {
  overflow: hidden; }
  .expand-content.show {
    max-height: none !important; }

.btn-main {
  background-color: #8CB73F;
  color: #fff; }
  .btn-main.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main:hover {
      background-color: #6f9132; } }

.btn-border-main {
  border: 1px solid #8CB73F;
  color: #8CB73F; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main:hover {
      background-color: #8CB73F;
      color: #fff; } }

.btn-secondary {
  background-color: #5D873B;
  color: #fff; }
  .btn-secondary.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-secondary:hover {
      background-color: #45642b; } }

.btn-border-secondary {
  border: 1px solid #5D873B;
  color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-secondary:hover {
      background-color: #5D873B;
      color: #fff; } }

.btn-secondary-light {
  background-color: rgba(208, 220, 183, 0.2);
  color: #333; }
  .btn-secondary-light.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-secondary-light:hover {
      background-color: rgba(186, 203, 149, 0.2); } }

.btn-border-secondary-light {
  border: 1px solid rgba(208, 220, 183, 0.2);
  color: rgba(208, 220, 183, 0.2); }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-secondary-light:hover {
      background-color: rgba(208, 220, 183, 0.2);
      color: #333; } }

.btn-tertiary {
  background-color: #2D411B;
  color: #fff; }
  .btn-tertiary.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-tertiary:hover {
      background-color: #141d0c; } }

.btn-border-tertiary {
  border: 1px solid #2D411B;
  color: #2D411B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-tertiary:hover {
      background-color: #2D411B;
      color: #fff; } }

.btn-brown {
  background-color: #CDB69C;
  color: #333; }
  .btn-brown.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-brown:hover {
      background-color: #bc9d7a; } }

.btn-border-brown {
  border: 1px solid #CDB69C;
  color: #CDB69C; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-brown:hover {
      background-color: #CDB69C;
      color: #333; } }

.btn-brown-light {
  background-color: rgba(205, 182, 156, 0.2);
  color: #333; }
  .btn-brown-light.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-brown-light:hover {
      background-color: rgba(188, 157, 122, 0.2); } }

.btn-border-brown-light {
  border: 1px solid rgba(205, 182, 156, 0.2);
  color: rgba(205, 182, 156, 0.2); }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-brown-light:hover {
      background-color: rgba(205, 182, 156, 0.2);
      color: #333; } }

.btn-main-light {
  background-color: #D0DCB7;
  color: #333; }
  .btn-main-light.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main-light:hover {
      background-color: #bacb95; } }

.btn-border-main-light {
  border: 1px solid #D0DCB7;
  color: #D0DCB7; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main-light:hover {
      background-color: #D0DCB7;
      color: #333; } }

.btn-grey-666 {
  background-color: #666;
  color: #fff; }
  .btn-grey-666.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-666:hover {
      background-color: #4d4d4d; } }

.btn-border-grey-666 {
  border: 1px solid #666;
  color: #666; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-666:hover {
      background-color: #666;
      color: #fff; } }

.btn-grey-999 {
  background-color: #999;
  color: #333; }
  .btn-grey-999.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-999:hover {
      background-color: gray; } }

.btn-border-grey-999 {
  border: 1px solid #999;
  color: #999; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-999:hover {
      background-color: #999;
      color: #333; } }

.btn-grey-e1 {
  background-color: #e1e1e1;
  color: #333; }
  .btn-grey-e1.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-e1:hover {
      background-color: #c8c8c8; } }

.btn-border-grey-e1 {
  border: 1px solid #e1e1e1;
  color: #e1e1e1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-e1:hover {
      background-color: #e1e1e1;
      color: #333; } }

.btn-grey-f3 {
  background-color: #f3f3f3;
  color: #333; }
  .btn-grey-f3.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-f3:hover {
      background-color: #dadada; } }

.btn-border-grey-f3 {
  border: 1px solid #f3f3f3;
  color: #f3f3f3; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-f3:hover {
      background-color: #f3f3f3;
      color: #333; } }

.btn-white {
  background-color: #fff;
  color: #333; }
  .btn-white.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      background-color: #fff;
      color: #333; } }

.btn-body {
  background-color: #333;
  color: #fff; }
  .btn-body.has-icon em {
    background-color: #fff;
    color: #5D873B; }
  @media (hover: hover) and (pointer: fine) {
    .btn-body:hover {
      background-color: #1a1a1a; } }

.btn-border-body {
  border: 1px solid #333;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-body:hover {
      background-color: #333;
      color: #fff; } }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 2.5rem 0px;
  padding: 0.75rem 2rem 0.5rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 600; }
  .btn.btn-secondary-light {
    color: #333; }
  .btn.has-icon {
    position: relative; }
    .btn.has-icon em {
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 2.5rem 0px;
      padding-right: 0.125rem; }
      .btn.has-icon em.fa-phone {
        font-size: 0.75rem; }
        .btn.has-icon em.fa-phone:before {
          transform: matrix(-1, 0, 0, 1, 0, 0); }
  .btn.is-icon-left {
    padding: 0.75rem 1.75rem 0.5rem 3rem; }
    .btn.is-icon-left em {
      left: 0.5rem; }
  .btn.is-icon-right {
    padding: 0.75rem 3rem 0.5rem 1.75rem; }
    .btn.is-icon-right em {
      right: 0.5rem; }
  .btn.btn-secondary-light em {
    background-color: #5D873B;
    color: #fff; }
  .btn.btn-white em {
    background-color: #5D873B;
    color: #fff; }
  .btn.view-more {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    height: auto; }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 999999px;
  border: 1px solid #999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 2rem;
  position: absolute;
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all; }
  @media (max-width: 1024px) {
    .swiper-btn {
      display: none; } }
  @media (max-width: 1440px) {
    .swiper-btn {
      background-color: #999;
      color: #fff; } }
  @media (min-width: 1025px) {
    .swiper-btn.swiper-prev {
      left: 0; }
    .swiper-btn.swiper-next {
      right: 0; } }
  @media (min-width: 1441px) {
    .swiper-btn {
      margin: 0 2rem; }
      .swiper-btn.swiper-prev {
        right: 100%;
        left: auto; }
      .swiper-btn.swiper-next {
        right: auto;
        left: 100%; } }
  .swiper-btn-white {
    border-color: #fff;
    color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #8CB73F;
      color: #fff; } }

.container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 92vw; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 1260px; } }
  @media (min-width: 1441px) {
    .container {
      max-width: 1440px; } }

.container-large {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container-large {
      max-width: 92vw; } }
  @media (min-width: 1025px) {
    .container-large {
      max-width: 1640px; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: 0.75rem; }

.fz-14 {
  font-size: 0.875rem; }

.fz-16 {
  font-size: 1rem; }

.fz-18 {
  font-size: 1.125rem; }

.fz-20 {
  font-size: 1.25rem; }

.fz-22 {
  font-size: 1.375rem; }

.fz-24 {
  font-size: 1.5rem; }

.fz-26 {
  font-size: 1.625rem; }

.fz-28 {
  font-size: 1.75rem; }

.fz-30 {
  font-size: 1.875rem; }

.fz-32 {
  font-size: 2rem; }

.fz-34 {
  font-size: 2.125rem; }

.fz-36 {
  font-size: 2.25rem; }

.fz-38 {
  font-size: 2.375rem; }

.fz-40 {
  font-size: 2.5rem; }

@media (min-width: 577px) {
  .fz-sm-12 {
    font-size: 0.75rem; }
  .fz-sm-14 {
    font-size: 0.875rem; }
  .fz-sm-16 {
    font-size: 1rem; }
  .fz-sm-18 {
    font-size: 1.125rem; }
  .fz-sm-20 {
    font-size: 1.25rem; }
  .fz-sm-22 {
    font-size: 1.375rem; }
  .fz-sm-24 {
    font-size: 1.5rem; }
  .fz-sm-26 {
    font-size: 1.625rem; }
  .fz-sm-28 {
    font-size: 1.75rem; }
  .fz-sm-30 {
    font-size: 1.875rem; }
  .fz-sm-32 {
    font-size: 2rem; }
  .fz-sm-34 {
    font-size: 2.125rem; }
  .fz-sm-36 {
    font-size: 2.25rem; }
  .fz-sm-38 {
    font-size: 2.375rem; }
  .fz-sm-40 {
    font-size: 2.5rem; } }

@media (min-width: 769px) {
  .fz-md-12 {
    font-size: 0.75rem; }
  .fz-md-14 {
    font-size: 0.875rem; }
  .fz-md-16 {
    font-size: 1rem; }
  .fz-md-18 {
    font-size: 1.125rem; }
  .fz-md-20 {
    font-size: 1.25rem; }
  .fz-md-22 {
    font-size: 1.375rem; }
  .fz-md-24 {
    font-size: 1.5rem; }
  .fz-md-26 {
    font-size: 1.625rem; }
  .fz-md-28 {
    font-size: 1.75rem; }
  .fz-md-30 {
    font-size: 1.875rem; }
  .fz-md-32 {
    font-size: 2rem; }
  .fz-md-34 {
    font-size: 2.125rem; }
  .fz-md-36 {
    font-size: 2.25rem; }
  .fz-md-38 {
    font-size: 2.375rem; }
  .fz-md-40 {
    font-size: 2.5rem; } }

@media (min-width: 1025px) {
  .fz-lg-12 {
    font-size: 0.75rem; }
  .fz-lg-14 {
    font-size: 0.875rem; }
  .fz-lg-16 {
    font-size: 1rem; }
  .fz-lg-18 {
    font-size: 1.125rem; }
  .fz-lg-20 {
    font-size: 1.25rem; }
  .fz-lg-22 {
    font-size: 1.375rem; }
  .fz-lg-24 {
    font-size: 1.5rem; }
  .fz-lg-26 {
    font-size: 1.625rem; }
  .fz-lg-28 {
    font-size: 1.75rem; }
  .fz-lg-30 {
    font-size: 1.875rem; }
  .fz-lg-32 {
    font-size: 2rem; }
  .fz-lg-34 {
    font-size: 2.125rem; }
  .fz-lg-36 {
    font-size: 2.25rem; }
  .fz-lg-38 {
    font-size: 2.375rem; }
  .fz-lg-40 {
    font-size: 2.5rem; } }

@media (min-width: 1281px) {
  .fz-xl-12 {
    font-size: 0.75rem; }
  .fz-xl-14 {
    font-size: 0.875rem; }
  .fz-xl-16 {
    font-size: 1rem; }
  .fz-xl-18 {
    font-size: 1.125rem; }
  .fz-xl-20 {
    font-size: 1.25rem; }
  .fz-xl-22 {
    font-size: 1.375rem; }
  .fz-xl-24 {
    font-size: 1.5rem; }
  .fz-xl-26 {
    font-size: 1.625rem; }
  .fz-xl-28 {
    font-size: 1.75rem; }
  .fz-xl-30 {
    font-size: 1.875rem; }
  .fz-xl-32 {
    font-size: 2rem; }
  .fz-xl-34 {
    font-size: 2.125rem; }
  .fz-xl-36 {
    font-size: 2.25rem; }
  .fz-xl-38 {
    font-size: 2.375rem; }
  .fz-xl-40 {
    font-size: 2.5rem; } }

@media (min-width: 1441px) {
  .fz-xxl-12 {
    font-size: 0.75rem; }
  .fz-xxl-14 {
    font-size: 0.875rem; }
  .fz-xxl-16 {
    font-size: 1rem; }
  .fz-xxl-18 {
    font-size: 1.125rem; }
  .fz-xxl-20 {
    font-size: 1.25rem; }
  .fz-xxl-22 {
    font-size: 1.375rem; }
  .fz-xxl-24 {
    font-size: 1.5rem; }
  .fz-xxl-26 {
    font-size: 1.625rem; }
  .fz-xxl-28 {
    font-size: 1.75rem; }
  .fz-xxl-30 {
    font-size: 1.875rem; }
  .fz-xxl-32 {
    font-size: 2rem; }
  .fz-xxl-34 {
    font-size: 2.125rem; }
  .fz-xxl-36 {
    font-size: 2.25rem; }
  .fz-xxl-38 {
    font-size: 2.375rem; }
  .fz-xxl-40 {
    font-size: 2.5rem; } }

@media (min-width: 1601px) {
  .fz-2xl-12 {
    font-size: 0.75rem; }
  .fz-2xl-14 {
    font-size: 0.875rem; }
  .fz-2xl-16 {
    font-size: 1rem; }
  .fz-2xl-18 {
    font-size: 1.125rem; }
  .fz-2xl-20 {
    font-size: 1.25rem; }
  .fz-2xl-22 {
    font-size: 1.375rem; }
  .fz-2xl-24 {
    font-size: 1.5rem; }
  .fz-2xl-26 {
    font-size: 1.625rem; }
  .fz-2xl-28 {
    font-size: 1.75rem; }
  .fz-2xl-30 {
    font-size: 1.875rem; }
  .fz-2xl-32 {
    font-size: 2rem; }
  .fz-2xl-34 {
    font-size: 2.125rem; }
  .fz-2xl-36 {
    font-size: 2.25rem; }
  .fz-2xl-38 {
    font-size: 2.375rem; }
  .fz-2xl-40 {
    font-size: 2.5rem; } }

.form-group input[type="text"], .form-group input[type="date"], .form-group select, .form-group textarea {
  border-radius: 0.25rem;
  background-clip: padding-box;
  border: 1px solid #666;
  padding: 0.25rem 1rem 0 !important;
  width: 100%;
  height: 3rem;
  font-style: normal !important;
  font-size: 1rem !important;
  font-family: 'Josefin Sans', sans-serif !important;
  background: transparent; }

.form-group select {
  appearance: none;
  background-image: url("../img/sl.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 1.25rem) 50%; }
  .form-group select option {
    color: #333; }

.form-group textarea {
  height: 8.75rem;
  padding-top: 1rem !important; }

@media (max-width: 576px) {
  .form-group .form-link .btn {
    width: 100%; } }

.form-group .RadPicker {
  width: 100% !important;
  position: relative; }
  .form-group .RadPicker .riTextBox {
    background-color: rgba(255, 255, 255, 0.95);
    border: 0 !important; }
  .form-group .RadPicker .riEmpty {
    color: #666; }
  .form-group .RadPicker .rcTable {
    width: 100% !important; }
  .form-group .RadPicker .rcCalPopup {
    position: absolute;
    right: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/calendar.png);
    background-position: center !important; }

.form-group.qradio ul {
  margin-bottom: -1.25rem;
  display: flex;
  flex-wrap: wrap; }
  .form-group.qradio ul > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc( (100% - (2 - 1) * 1.25rem) / 2);
    margin-bottom: 1.25rem; }
    .form-group.qradio ul > *:not(:last-child) {
      margin-right: 1.25rem; }
    .form-group.qradio ul > *:nth-child(2n) {
      margin-right: 0; }
  .form-group.qradio ul li input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    white-space: nowrap; }
    .form-group.qradio ul li input:checked ~ label:before {
      content: '\f192'; }
  .form-group.qradio ul li label {
    display: flex; }
    .form-group.qradio ul li label:before {
      content: '\f111';
      font-family: "Font Awesome 6 Pro";
      margin-right: 0.625rem;
      position: relative; }

.frm-btnwrap {
  margin-top: 1.875rem; }
  .frm-btnwrap label {
    display: none; }
  @media (max-width: 576px) {
    .frm-btnwrap {
      margin-top: 1.25rem; } }

.frm-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 2.5rem 0px;
  padding: 0.75rem 2rem 0.5rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  position: relative;
  background-color: #5D873B;
  width: 12.5rem;
  overflow: hidden; }
  .frm-btn:before {
    position: absolute;
    display: inline-flex;
    content: '\e09f';
    font-family: "Font Awesome 6 Pro";
    font-weight: 300;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 2.5rem 0px;
    font-size: 1rem;
    z-index: 2;
    right: 0.5rem;
    background-color: #fff;
    color: #5D873B; }
  .frm-btn input {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-transform: uppercase;
    padding-top: 0.25rem; }

.frm-captcha {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 1.25rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1.25rem; }
  .frm-captcha label {
    display: none; }
  .frm-captcha .frm-captcha-input {
    flex: 1; }
    @media (max-width: 576px) {
      .frm-captcha .frm-captcha-input {
        flex: 0 0 100%; } }
  .frm-captcha .RadCaptcha {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    max-width: fit-content; }
    .frm-captcha .RadCaptcha img {
      height: 3.125rem !important; }
    .frm-captcha .RadCaptcha > span {
      color: red;
      display: block;
      margin-top: 5px; }
    .frm-captcha .RadCaptcha > div > div {
      display: flex;
      align-items: center; }
    .frm-captcha .RadCaptcha a {
      font-size: 0;
      margin-left: 20px; }
      .frm-captcha .RadCaptcha a:before {
        font-family: "Font Awesome 6 Pro";
        content: '\f2ea';
        font-size: 1.6875rem;
        color: #333; }

.form-container .wrap-form .qradio .label {
  display: none; }

.form-container .wrap-form .frm-btn {
  height: 2.5rem;
  width: 100%; }
  .form-container .wrap-form .frm-btn input[type="submit"] {
    width: 100%;
    min-width: auto; }

.form-container .wrap-form .frm-btnwrap {
  width: 12.5rem;
  margin-left: 1.25rem;
  margin-top: 0 !important; }
  @media (max-width: 576px) {
    .form-container .wrap-form .frm-btnwrap {
      width: 100%;
      margin-left: 0;
      margin-top: 1.25rem !important; } }

@media (max-width: 576px) {
  .form-container .wrap-form .form-append {
    flex-direction: column; } }

.img-zoom-out {
  overflow: hidden; }
  .img-zoom-out img {
    transition: all 0.3s ease-in-out; }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-out:hover img {
      transform: scale(1.05); } }

.img-zoom-in {
  overflow: hidden; }
  .img-zoom-in img {
    transition: all 0.3s ease-in-out;
    transform: scale(1.05); }
  @media (hover: hover) and (pointer: fine) {
    .img-zoom-in:hover img {
      transform: scale(1); } }

@media (hover: hover) and (pointer: fine) {
  .link-hover:hover {
    text-decoration: underline; } }

.text-main {
  color: #8CB73F; }

.text-secondary {
  color: #5D873B; }

.text-secondary-light {
  color: rgba(208, 220, 183, 0.2); }

.text-tertiary {
  color: #2D411B; }

.text-brown {
  color: #CDB69C; }

.text-brown-light {
  color: rgba(205, 182, 156, 0.2); }

.text-main-light {
  color: #D0DCB7; }

.text-grey-666 {
  color: #666; }

.text-grey-999 {
  color: #999; }

.text-grey-e1 {
  color: #e1e1e1; }

.text-grey-f3 {
  color: #f3f3f3; }

.text-white {
  color: #fff; }

.text-body {
  color: #333; }

.background-main {
  background: #8CB73F; }

.background-secondary {
  background: #5D873B; }

.background-secondary-light {
  background: rgba(208, 220, 183, 0.2); }

.background-tertiary {
  background: #2D411B; }

.background-brown {
  background: #CDB69C; }

.background-brown-light {
  background: rgba(205, 182, 156, 0.2); }

.background-main-light {
  background: #D0DCB7; }

.background-grey-666 {
  background: #666; }

.background-grey-999 {
  background: #999; }

.background-grey-e1 {
  background: #e1e1e1; }

.background-grey-f3 {
  background: #f3f3f3; }

.background-white {
  background: #fff; }

.background-body {
  background: #333; }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Text color opacity -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Has Divider -------------------------*/
/* Content margins -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Responsive Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Image Contain -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.object-fit-img img {
  font-family: 'object-fit: cover'; }

.modulepager {
  margin-top: 1.875rem; }
  .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 0.5rem; }
      .modulepager .pagination li:first-child {
        margin-left: 0; }
      .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        width: 3.75rem;
        height: 3.75rem;
        font-size: 1.25rem;
        color: #999;
        border-radius: 50%;
        background-clip: padding-box;
        border: 1px solid #999;
        transition: all 0.3s ease-in-out; }
      .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #8CB73F;
        color: #fff;
        border-color: #8CB73F; }
      .modulepager .pagination li .FirstPage, .modulepager .pagination li .LastPage, .modulepager .pagination li .BackPage, .modulepager .pagination li .NextPage {
        display: none !important; }

.no-pagination .pages {
  display: none; }

.section {
  padding: 3.75rem 0; }
  @media screen and (max-width: 1024px) {
    .section {
      padding: 2.5rem 0; } }

.section-large {
  padding: 5rem 0; }
  @media screen and (max-width: 1024px) {
    .section-large {
      padding: 2.5rem 0; } }

.section-small {
  padding: 2.5rem 0; }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.25rem;
  : 0.25rem; }

.mt-2 {
  margin-top: 0.5rem;
  : 0.5rem; }

.mt-3 {
  margin-top: 0.75rem;
  : 0.75rem; }

.mt-4 {
  margin-top: 1rem;
  : 1rem; }

.mt-5 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-6 {
  margin-top: 1.5rem;
  : 1.5rem; }

.mt-7 {
  margin-top: 1.75rem;
  : 1.75rem; }

.mt-8 {
  margin-top: 2rem;
  : 2rem; }

.mt-9 {
  margin-top: 2.25rem;
  : 2.25rem; }

.mt-10 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-11 {
  margin-top: 2.75rem;
  : 2.75rem; }

.mt-12 {
  margin-top: 3rem;
  : 3rem; }

.mt-13 {
  margin-top: 3.25rem;
  : 3.25rem; }

.mt-14 {
  margin-top: 3.5rem;
  : 3.5rem; }

.mt-15 {
  margin-top: 3.75rem;
  : 3.75rem; }

.mt-16 {
  margin-top: 4rem;
  : 4rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.25rem;
  : 0.25rem; }

.mr-2 {
  margin-right: 0.5rem;
  : 0.5rem; }

.mr-3 {
  margin-right: 0.75rem;
  : 0.75rem; }

.mr-4 {
  margin-right: 1rem;
  : 1rem; }

.mr-5 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-6 {
  margin-right: 1.5rem;
  : 1.5rem; }

.mr-7 {
  margin-right: 1.75rem;
  : 1.75rem; }

.mr-8 {
  margin-right: 2rem;
  : 2rem; }

.mr-9 {
  margin-right: 2.25rem;
  : 2.25rem; }

.mr-10 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-11 {
  margin-right: 2.75rem;
  : 2.75rem; }

.mr-12 {
  margin-right: 3rem;
  : 3rem; }

.mr-13 {
  margin-right: 3.25rem;
  : 3.25rem; }

.mr-14 {
  margin-right: 3.5rem;
  : 3.5rem; }

.mr-15 {
  margin-right: 3.75rem;
  : 3.75rem; }

.mr-16 {
  margin-right: 4rem;
  : 4rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.25rem;
  : 0.25rem; }

.mb-2 {
  margin-bottom: 0.5rem;
  : 0.5rem; }

.mb-3 {
  margin-bottom: 0.75rem;
  : 0.75rem; }

.mb-4 {
  margin-bottom: 1rem;
  : 1rem; }

.mb-5 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-6 {
  margin-bottom: 1.5rem;
  : 1.5rem; }

.mb-7 {
  margin-bottom: 1.75rem;
  : 1.75rem; }

.mb-8 {
  margin-bottom: 2rem;
  : 2rem; }

.mb-9 {
  margin-bottom: 2.25rem;
  : 2.25rem; }

.mb-10 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-11 {
  margin-bottom: 2.75rem;
  : 2.75rem; }

.mb-12 {
  margin-bottom: 3rem;
  : 3rem; }

.mb-13 {
  margin-bottom: 3.25rem;
  : 3.25rem; }

.mb-14 {
  margin-bottom: 3.5rem;
  : 3.5rem; }

.mb-15 {
  margin-bottom: 3.75rem;
  : 3.75rem; }

.mb-16 {
  margin-bottom: 4rem;
  : 4rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.25rem;
  : 0.25rem; }

.ml-2 {
  margin-left: 0.5rem;
  : 0.5rem; }

.ml-3 {
  margin-left: 0.75rem;
  : 0.75rem; }

.ml-4 {
  margin-left: 1rem;
  : 1rem; }

.ml-5 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-6 {
  margin-left: 1.5rem;
  : 1.5rem; }

.ml-7 {
  margin-left: 1.75rem;
  : 1.75rem; }

.ml-8 {
  margin-left: 2rem;
  : 2rem; }

.ml-9 {
  margin-left: 2.25rem;
  : 2.25rem; }

.ml-10 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-11 {
  margin-left: 2.75rem;
  : 2.75rem; }

.ml-12 {
  margin-left: 3rem;
  : 3rem; }

.ml-13 {
  margin-left: 3.25rem;
  : 3.25rem; }

.ml-14 {
  margin-left: 3.5rem;
  : 3.5rem; }

.ml-15 {
  margin-left: 3.75rem;
  : 3.75rem; }

.ml-16 {
  margin-left: 4rem;
  : 4rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.25rem;
  : 0.25rem; }

.m-2 {
  margin: 0.5rem;
  : 0.5rem; }

.m-3 {
  margin: 0.75rem;
  : 0.75rem; }

.m-4 {
  margin: 1rem;
  : 1rem; }

.m-5 {
  margin: 1.25rem;
  : 1.25rem; }

.m-6 {
  margin: 1.5rem;
  : 1.5rem; }

.m-7 {
  margin: 1.75rem;
  : 1.75rem; }

.m-8 {
  margin: 2rem;
  : 2rem; }

.m-9 {
  margin: 2.25rem;
  : 2.25rem; }

.m-10 {
  margin: 2.5rem;
  : 2.5rem; }

.m-11 {
  margin: 2.75rem;
  : 2.75rem; }

.m-12 {
  margin: 3rem;
  : 3rem; }

.m-13 {
  margin: 3.25rem;
  : 3.25rem; }

.m-14 {
  margin: 3.5rem;
  : 3.5rem; }

.m-15 {
  margin: 3.75rem;
  : 3.75rem; }

.m-16 {
  margin: 4rem;
  : 4rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.25rem;
  : 0.25rem; }

.pt-2 {
  padding-top: 0.5rem;
  : 0.5rem; }

.pt-3 {
  padding-top: 0.75rem;
  : 0.75rem; }

.pt-4 {
  padding-top: 1rem;
  : 1rem; }

.pt-5 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-6 {
  padding-top: 1.5rem;
  : 1.5rem; }

.pt-7 {
  padding-top: 1.75rem;
  : 1.75rem; }

.pt-8 {
  padding-top: 2rem;
  : 2rem; }

.pt-9 {
  padding-top: 2.25rem;
  : 2.25rem; }

.pt-10 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-11 {
  padding-top: 2.75rem;
  : 2.75rem; }

.pt-12 {
  padding-top: 3rem;
  : 3rem; }

.pt-13 {
  padding-top: 3.25rem;
  : 3.25rem; }

.pt-14 {
  padding-top: 3.5rem;
  : 3.5rem; }

.pt-15 {
  padding-top: 3.75rem;
  : 3.75rem; }

.pt-16 {
  padding-top: 4rem;
  : 4rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.25rem;
  : 0.25rem; }

.pr-2 {
  padding-right: 0.5rem;
  : 0.5rem; }

.pr-3 {
  padding-right: 0.75rem;
  : 0.75rem; }

.pr-4 {
  padding-right: 1rem;
  : 1rem; }

.pr-5 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-6 {
  padding-right: 1.5rem;
  : 1.5rem; }

.pr-7 {
  padding-right: 1.75rem;
  : 1.75rem; }

.pr-8 {
  padding-right: 2rem;
  : 2rem; }

.pr-9 {
  padding-right: 2.25rem;
  : 2.25rem; }

.pr-10 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-11 {
  padding-right: 2.75rem;
  : 2.75rem; }

.pr-12 {
  padding-right: 3rem;
  : 3rem; }

.pr-13 {
  padding-right: 3.25rem;
  : 3.25rem; }

.pr-14 {
  padding-right: 3.5rem;
  : 3.5rem; }

.pr-15 {
  padding-right: 3.75rem;
  : 3.75rem; }

.pr-16 {
  padding-right: 4rem;
  : 4rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.25rem;
  : 0.25rem; }

.pb-2 {
  padding-bottom: 0.5rem;
  : 0.5rem; }

.pb-3 {
  padding-bottom: 0.75rem;
  : 0.75rem; }

.pb-4 {
  padding-bottom: 1rem;
  : 1rem; }

.pb-5 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-6 {
  padding-bottom: 1.5rem;
  : 1.5rem; }

.pb-7 {
  padding-bottom: 1.75rem;
  : 1.75rem; }

.pb-8 {
  padding-bottom: 2rem;
  : 2rem; }

.pb-9 {
  padding-bottom: 2.25rem;
  : 2.25rem; }

.pb-10 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-11 {
  padding-bottom: 2.75rem;
  : 2.75rem; }

.pb-12 {
  padding-bottom: 3rem;
  : 3rem; }

.pb-13 {
  padding-bottom: 3.25rem;
  : 3.25rem; }

.pb-14 {
  padding-bottom: 3.5rem;
  : 3.5rem; }

.pb-15 {
  padding-bottom: 3.75rem;
  : 3.75rem; }

.pb-16 {
  padding-bottom: 4rem;
  : 4rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.25rem;
  : 0.25rem; }

.pl-2 {
  padding-left: 0.5rem;
  : 0.5rem; }

.pl-3 {
  padding-left: 0.75rem;
  : 0.75rem; }

.pl-4 {
  padding-left: 1rem;
  : 1rem; }

.pl-5 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-6 {
  padding-left: 1.5rem;
  : 1.5rem; }

.pl-7 {
  padding-left: 1.75rem;
  : 1.75rem; }

.pl-8 {
  padding-left: 2rem;
  : 2rem; }

.pl-9 {
  padding-left: 2.25rem;
  : 2.25rem; }

.pl-10 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-11 {
  padding-left: 2.75rem;
  : 2.75rem; }

.pl-12 {
  padding-left: 3rem;
  : 3rem; }

.pl-13 {
  padding-left: 3.25rem;
  : 3.25rem; }

.pl-14 {
  padding-left: 3.5rem;
  : 3.5rem; }

.pl-15 {
  padding-left: 3.75rem;
  : 3.75rem; }

.pl-16 {
  padding-left: 4rem;
  : 4rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.25rem;
  : 0.25rem; }

.p-2 {
  padding: 0.5rem;
  : 0.5rem; }

.p-3 {
  padding: 0.75rem;
  : 0.75rem; }

.p-4 {
  padding: 1rem;
  : 1rem; }

.p-5 {
  padding: 1.25rem;
  : 1.25rem; }

.p-6 {
  padding: 1.5rem;
  : 1.5rem; }

.p-7 {
  padding: 1.75rem;
  : 1.75rem; }

.p-8 {
  padding: 2rem;
  : 2rem; }

.p-9 {
  padding: 2.25rem;
  : 2.25rem; }

.p-10 {
  padding: 2.5rem;
  : 2.5rem; }

.p-11 {
  padding: 2.75rem;
  : 2.75rem; }

.p-12 {
  padding: 3rem;
  : 3rem; }

.p-13 {
  padding: 3.25rem;
  : 3.25rem; }

.p-14 {
  padding: 3.5rem;
  : 3.5rem; }

.p-15 {
  padding: 3.75rem;
  : 3.75rem; }

.p-16 {
  padding: 4rem;
  : 4rem; }

@media (min-width: 577px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-sm-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-sm-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-sm-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-sm-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-sm-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-sm-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-sm-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-sm-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-sm-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-sm-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-sm-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-sm-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-sm-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-sm-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-sm-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-sm-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-sm-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-sm-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-sm-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-sm-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-sm-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-sm-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-sm-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-sm-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-sm-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-sm-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-sm-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-sm-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-sm-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-sm-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-sm-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-sm-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-sm-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-sm-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-sm-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-sm-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-sm-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-sm-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-sm-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-sm-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-sm-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-sm-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-sm-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-sm-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-sm-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-sm-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-sm-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-sm-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-sm-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-sm-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-sm-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-sm-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-sm-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-sm-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-sm-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-sm-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-sm-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-sm-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-sm-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-sm-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-sm-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-sm-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-sm-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-sm-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-sm-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-sm-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-sm-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-sm-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-sm-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-sm-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-sm-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-sm-4 {
    padding: 1rem;
    : 1rem; }
  .p-sm-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-sm-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-sm-8 {
    padding: 2rem;
    : 2rem; }
  .p-sm-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-sm-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-sm-12 {
    padding: 3rem;
    : 3rem; }
  .p-sm-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-sm-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-sm-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-sm-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 577px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-sm-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-sm-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-sm-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-sm-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-sm-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-sm-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-sm-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-sm-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-sm-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-sm-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-sm-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-sm-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-sm-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-sm-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-sm-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-sm-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-sm-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-sm-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-sm-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-sm-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-sm-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-sm-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-sm-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-sm-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-sm-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-sm-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-sm-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-sm-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-sm-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-sm-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-sm-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-sm-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-sm-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-sm-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-sm-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-sm-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-sm-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-sm-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-sm-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-sm-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-sm-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-sm-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-sm-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-sm-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-sm-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-sm-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-sm-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-sm-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-sm-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-sm-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-sm-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-sm-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-sm-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-sm-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-sm-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-sm-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-sm-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-sm-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-sm-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-sm-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-sm-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-sm-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-sm-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-sm-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-sm-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-sm-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-sm-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-sm-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-sm-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-sm-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-sm-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-sm-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-sm-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-sm-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-sm-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-sm-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-sm-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-sm-4 {
    margin: 1rem;
    : 1rem; }
  .m-sm-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-sm-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-sm-8 {
    margin: 2rem;
    : 2rem; }
  .m-sm-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-sm-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-sm-12 {
    margin: 3rem;
    : 3rem; }
  .m-sm-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-sm-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-sm-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-sm-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 769px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-md-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-md-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-md-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-md-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-md-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-md-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-md-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-md-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-md-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-md-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-md-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-md-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-md-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-md-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-md-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-md-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-md-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-md-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-md-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-md-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-md-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-md-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-md-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-md-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-md-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-md-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-md-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-md-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-md-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-md-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-md-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-md-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-md-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-md-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-md-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-md-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-md-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-md-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-md-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-md-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-md-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-md-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-md-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-md-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-md-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-md-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-md-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-md-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-md-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-md-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-md-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-md-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-md-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-md-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-md-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-md-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-md-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-md-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-md-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-md-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-md-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-md-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-md-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-md-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-md-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-md-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-md-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-md-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-md-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-md-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-md-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-md-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-md-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-md-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-md-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-md-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-md-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-md-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-md-4 {
    padding: 1rem;
    : 1rem; }
  .p-md-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-md-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-md-8 {
    padding: 2rem;
    : 2rem; }
  .p-md-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-md-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-md-12 {
    padding: 3rem;
    : 3rem; }
  .p-md-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-md-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-md-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-md-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-md-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-md-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-md-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-md-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-md-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-md-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-md-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-md-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-md-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-md-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-md-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-md-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-md-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-md-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-md-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-md-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-md-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-md-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-md-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-md-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-md-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-md-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-md-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-md-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-md-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-md-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-md-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-md-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-md-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-md-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-md-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-md-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-md-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-md-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-md-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-md-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-md-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-md-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-md-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-md-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-md-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-md-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-md-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-md-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-md-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-md-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-md-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-md-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-md-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-md-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-md-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-md-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-md-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-md-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-md-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-md-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-md-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-md-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-md-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-md-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-md-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-md-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-md-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-md-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-md-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-md-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-md-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-md-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-md-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-md-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-md-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-md-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-md-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-md-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-md-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-md-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-md-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-md-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-md-4 {
    margin: 1rem;
    : 1rem; }
  .m-md-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-md-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-md-8 {
    margin: 2rem;
    : 2rem; }
  .m-md-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-md-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-md-12 {
    margin: 3rem;
    : 3rem; }
  .m-md-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-md-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-md-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-md-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1025px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-lg-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-lg-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-lg-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-lg-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-lg-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-lg-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-lg-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-lg-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-lg-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-lg-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-lg-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-lg-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-lg-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-lg-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-lg-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-lg-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-lg-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-lg-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-lg-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-lg-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-lg-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-lg-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-lg-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-lg-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-lg-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-lg-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-lg-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-lg-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-lg-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-lg-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-lg-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-lg-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-lg-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-lg-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-lg-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-lg-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-lg-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-lg-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-lg-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-lg-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-lg-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-lg-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-lg-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-lg-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-lg-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-lg-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-lg-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-lg-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-lg-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-lg-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-lg-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-lg-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-lg-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-lg-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-lg-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-lg-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-lg-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-lg-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-lg-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-lg-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-lg-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-lg-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-lg-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-lg-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-lg-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-lg-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-lg-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-lg-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-lg-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-lg-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-lg-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-lg-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-lg-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-lg-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-lg-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-lg-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-lg-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-lg-4 {
    padding: 1rem;
    : 1rem; }
  .p-lg-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-lg-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-lg-8 {
    padding: 2rem;
    : 2rem; }
  .p-lg-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-lg-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-lg-12 {
    padding: 3rem;
    : 3rem; }
  .p-lg-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-lg-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-lg-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-lg-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1025px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-lg-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-lg-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-lg-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-lg-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-lg-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-lg-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-lg-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-lg-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-lg-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-lg-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-lg-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-lg-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-lg-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-lg-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-lg-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-lg-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-lg-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-lg-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-lg-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-lg-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-lg-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-lg-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-lg-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-lg-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-lg-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-lg-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-lg-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-lg-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-lg-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-lg-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-lg-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-lg-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-lg-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-lg-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-lg-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-lg-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-lg-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-lg-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-lg-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-lg-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-lg-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-lg-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-lg-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-lg-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-lg-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-lg-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-lg-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-lg-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-lg-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-lg-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-lg-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-lg-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-lg-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-lg-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-lg-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-lg-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-lg-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-lg-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-lg-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-lg-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-lg-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-lg-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-lg-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-lg-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-lg-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-lg-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-lg-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-lg-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-lg-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-lg-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-lg-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-lg-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-lg-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-lg-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-lg-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-lg-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-lg-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-lg-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-lg-4 {
    margin: 1rem;
    : 1rem; }
  .m-lg-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-lg-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-lg-8 {
    margin: 2rem;
    : 2rem; }
  .m-lg-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-lg-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-lg-12 {
    margin: 3rem;
    : 3rem; }
  .m-lg-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-lg-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-lg-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-lg-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1281px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1281px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xl-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1441px) {
  .pt-xxl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xxl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xxl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xxl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xxl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xxl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xxl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xxl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xxl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xxl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xxl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xxl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xxl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xxl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xxl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xxl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xxl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xxl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xxl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xxl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xxl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xxl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xxl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xxl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xxl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xxl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xxl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xxl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xxl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xxl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xxl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xxl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xxl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xxl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xxl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xxl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xxl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xxl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xxl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xxl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xxl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xxl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xxl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xxl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xxl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xxl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xxl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xxl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xxl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xxl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xxl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xxl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xxl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xxl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xxl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xxl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xxl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xxl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xxl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xxl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xxl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xxl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xxl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xxl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xxl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xxl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xxl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xxl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xxl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xxl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xxl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xxl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xxl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xxl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xxl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xxl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xxl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xxl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xxl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xxl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xxl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xxl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xxl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xxl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xxl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xxl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xxl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xxl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xxl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xxl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xxl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xxl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xxl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xxl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xxl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xxl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xxl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xxl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xxl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xxl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xxl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xxl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xxl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xxl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xxl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xxl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xxl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xxl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xxl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xxl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xxl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xxl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xxl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xxl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xxl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1441px) {
  .mt-xxl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xxl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xxl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xxl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xxl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xxl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xxl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xxl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xxl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xxl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xxl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xxl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xxl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xxl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xxl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xxl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xxl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xxl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xxl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xxl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xxl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xxl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xxl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xxl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xxl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xxl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xxl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xxl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xxl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xxl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xxl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xxl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xxl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xxl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xxl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xxl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xxl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xxl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xxl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xxl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xxl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xxl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xxl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xxl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xxl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xxl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xxl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xxl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xxl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xxl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xxl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xxl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xxl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xxl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xxl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xxl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xxl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xxl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xxl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xxl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xxl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xxl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xxl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xxl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xxl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xxl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xxl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xxl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xxl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xxl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xxl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xxl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xxl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xxl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xxl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xxl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xxl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xxl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xxl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xxl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xxl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xxl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xxl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xxl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xxl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xxl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xxl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xxl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xxl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xxl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xxl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xxl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xxl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xxl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xxl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xxl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xxl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xxl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xxl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xxl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xxl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xxl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xxl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xxl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xxl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xxl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xxl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xxl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xxl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xxl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xxl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xxl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xxl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xxl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xxl-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1601px) {
  .pt-2xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-2xl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-2xl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-2xl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-2xl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-2xl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-2xl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-2xl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-2xl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-2xl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-2xl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-2xl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-2xl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-2xl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-2xl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-2xl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-2xl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-2xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-2xl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-2xl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-2xl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-2xl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-2xl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-2xl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-2xl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-2xl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-2xl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-2xl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-2xl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-2xl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-2xl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-2xl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-2xl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-2xl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-2xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-2xl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-2xl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-2xl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-2xl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-2xl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-2xl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-2xl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-2xl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-2xl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-2xl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-2xl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-2xl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-2xl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-2xl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-2xl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-2xl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-2xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-2xl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-2xl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-2xl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-2xl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-2xl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-2xl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-2xl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-2xl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-2xl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-2xl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-2xl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-2xl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-2xl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-2xl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-2xl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-2xl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-2xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-2xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-2xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-2xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-2xl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-2xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-2xl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-2xl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-2xl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-2xl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-2xl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-2xl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-2xl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-2xl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-2xl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-2xl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-2xl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-2xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-2xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-2xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-2xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-2xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-2xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-2xl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-2xl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-2xl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-2xl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-2xl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-2xl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-2xl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-2xl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-2xl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-2xl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-2xl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-2xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-2xl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-2xl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-2xl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-2xl-4 {
    padding: 1rem;
    : 1rem; }
  .p-2xl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-2xl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-2xl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-2xl-8 {
    padding: 2rem;
    : 2rem; }
  .p-2xl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-2xl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-2xl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-2xl-12 {
    padding: 3rem;
    : 3rem; }
  .p-2xl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-2xl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-2xl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-2xl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1601px) {
  .mt-2xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-2xl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-2xl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-2xl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-2xl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-2xl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-2xl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-2xl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-2xl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-2xl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-2xl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-2xl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-2xl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-2xl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-2xl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-2xl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-2xl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-2xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-2xl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-2xl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-2xl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-2xl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-2xl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-2xl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-2xl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-2xl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-2xl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-2xl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-2xl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-2xl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-2xl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-2xl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-2xl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-2xl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-2xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-2xl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-2xl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-2xl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-2xl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-2xl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-2xl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-2xl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-2xl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-2xl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-2xl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-2xl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-2xl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-2xl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-2xl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-2xl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-2xl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-2xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-2xl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-2xl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-2xl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-2xl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-2xl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-2xl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-2xl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-2xl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-2xl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-2xl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-2xl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-2xl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-2xl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-2xl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-2xl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-2xl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-2xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-2xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-2xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-2xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-2xl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-2xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-2xl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-2xl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-2xl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-2xl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-2xl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-2xl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-2xl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-2xl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-2xl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-2xl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-2xl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-2xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-2xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-2xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-2xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-2xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-2xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-2xl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-2xl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-2xl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-2xl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-2xl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-2xl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-2xl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-2xl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-2xl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-2xl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-2xl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-2xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-2xl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-2xl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-2xl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-2xl-4 {
    margin: 1rem;
    : 1rem; }
  .m-2xl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-2xl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-2xl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-2xl-8 {
    margin: 2rem;
    : 2rem; }
  .m-2xl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-2xl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-2xl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-2xl-12 {
    margin: 3rem;
    : 3rem; }
  .m-2xl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-2xl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-2xl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-2xl-16 {
    margin: 4rem;
    : 4rem; } }

.section-title {
  font-size: 3rem;
  line-height: 1;
  font-weight: 700; }
  @media (max-width: 768px) {
    .section-title {
      font-size: clamp(14px, 3rem, 48px); } }
  @media (max-width: 1024px) {
    .section-title {
      font-size: 2rem;
      line-height: 1; } }
  @media (max-width: 1024px) and (max-width: 768px) {
    .section-title {
      font-size: clamp(14px, 2rem, 32px); } }

.heading-2 {
  font-weight: 700;
  font-size: 2rem;
  line-height: 1; }
  @media (max-width: 768px) {
    .heading-2 {
      font-size: clamp(14px, 2rem, 32px); } }

.heading-2.text-normal {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1; }
  @media (max-width: 768px) {
    .heading-2.text-normal {
      font-size: clamp(14px, 2rem, 32px); } }

.heading-3, .product-item .product-title {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1; }
  @media (max-width: 768px) {
    .heading-3, .product-item .product-title {
      font-size: clamp(14px, 1.5rem, 24px); } }

.heading-4, .detail-attr .tab-content h4 {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1; }
  @media (max-width: 768px) {
    .heading-4, .detail-attr .tab-content h4 {
      font-size: clamp(14px, 1.25rem, 20px); } }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media (max-width: 1024px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 1025px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.h-100 {
  width: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.333333; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
