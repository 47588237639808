.section
	padding: r(60px) 0
	@media screen and ( max-width: 1024px )
		padding: r(40px) 0

.section-large
	padding: r(80px) 0
	@media screen and ( max-width: 1024px )
		padding: r(40px) 0

.section-small
	padding: r(40px) 0