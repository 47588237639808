.global-header
	position: relative
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08)
	+bg(white)

.logo
	+mq-max(lg)
		img
			height: 60px
	+mq-min(lg)
		text-align: center

.menu
	+mq-min(lg)
		padding-left: r(24px)
	+mq-min(xl)
		padding-left: r(36px)
	+mq-min(xxl)
		padding-left: r(80px)
	> ul
		+mq-min(lg)
			display: flex
			align-items: center
			justify-content: space-between
		+mq-min(xxl)
			justify-content: flex-start
			+content-margins
				margin-left: r(64px)
		> li
			+mq-max(lg)
				+after-first(1)
					margin-top: r(20px)
			> a
				display: inline-flex
				height: r(100px)
				align-items: center
				+fz-lh(18px,24px)
				text-transform: uppercase
				+mq-max(lg)
					font-size: r(16px)
					height: auto
					text-align: left
					justify-content: flex-start
					+text(white)
			&.active
				+text(main)
			+on-hover
				+text(main)

.has-sub
	position: relative
	display: flex
	align-items: center
	> span
		margin-left: 5px
	.sub-menu
		display: none
		position: absolute
		top: 100%
		left: 0
		z-index: 2
		+mq-min(lg)
			min-width: r(320px)
		ul
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15)
			+bg(secondary)
			padding: r(8px)
			+radius(r(5px))
			+content-margins
				margin-top: r(8px)
			li
				a
					white-space: nowrap
					display: flex
					align-items: center
					padding: r(16px) r(32px) r(12px)
					+trans-all
					+text(white)
					+radius(r(5px))
					font-size: r(18px)
					font-weight: 700
				+on-hover
					> a
						+bg(main-light)
						+text(body)
				&.active
					> a
						+bg(main-light)
						+text(body)
				&.has-child
					position: relative
					+on-hover
						.child-menu
							display: block
					.child-menu
						position: absolute
						top: 0
						left: 100%
						padding-left: r(12px)
						display: none
	+mq-max(lg)
		justify-content: space-between
		flex-wrap: wrap
		> span
			font-size: r(16px)
			+text(white)
			position: relative
			margin-right: r(16px)
		.sub-menu
			position: static
			width: 100%
			border-top: 0
			box-shadow: none
			margin-top: r(8px)
			ul
				color: white
				padding: 0 0 0 r(16px)
				background: transparent
				box-shadow: none
				+content-margins
					margin-top: r(12px)
				li
					a
						font-size: r(14px)
						white-space: initial
						padding: 0
						+text(white)
					&.active
						a
							background: transparent
							+text(main)
					&.has-child
						display: flex
						justify-content: space-between
						flex-wrap: wrap
						> span
							font-size: r(14px)
							position: relative
							margin-right: r(12px)
						.child-menu
							position: static
							width: 100%
							border-top: 0
							ul
								box-shadow: none
								padding: 0.5rem 0 0
								li
									a
										font-size: r(16px)
										font-weight: 400

	+mq-min(lg)
		.toggle
			display: none
		&:hover
			.sub-menu
				display: block

.header-util,.header-tool
	display: flex
	align-items: center

.header-util
	justify-content: flex-end

.header-tool
	padding-left: r(24px)
	> *
		height: r(24px)
		display: inline-flex
		align-items: center
	+content-margins
		margin-left: r(12px)
		padding-left: r(12px)
		border-left: 1px solid rgba(color(body), 0.3)

.search-toggle
	+mq-max(md)
		font-size: r(16px)

.language
	.current
		display: flex
		+flex-center
		img
			height: r(24px)
		em
			margin-left: r(8px)
			+mq-max(md)
				font-size: 3.6vw
	.dropdown-content
		top: 100%
		min-width: auto
		z-index: 2
		padding-top: r(20px)
		ul
			padding: r(8px)
			+content-margins
				margin-top: r(4px)

.account
	display: block !important
	.firstnav
		> span
			font-weight: 700
			white-space: nowrap
	> a
		display: flex
		align-items: center
		font-size: 0
		&::before
			font-family: 'Font Awesome 6 Pro'
			content: "\f2bd"
			font-weight: 400
			font-size: r(16px)
			display: inline-block
		span
			overflow: hidden
			text-overflow: ellipsis
	.dropdown-content
		z-index: 2
		padding-top: r(20px)
		ul
			li
				a
					white-space: nowrap
					line-height: 1.5
	+mq-max(lg)
		padding: r(16px)
		margin-bottom: r(16px)
		border-radius: r(8px)
		+bg(white)
		> a
			font-size: r(14px)
			em
				display: none
			&::before
				font-size: r(14px)
				text-align: center
				margin-right: 12px
		.dropdown-content
			right: auto
			left: 0
			position: static
			display: block
			margin-top: 0
			ul
				padding: 0
				background: transparent
				box-shadow: none
				li
					&.firstnav
						display: none
					a,span
						font-size: r(16px)

.menu-toggle
	+mq-max(lg)
		display: flex
		+flex-center
		+box(r(32px), r(32px))
		font-size: r(16px)
		line-height: 1
		+bg(main)
		+text(white)
	+mq-max(md)
		width: r(40px) !important
		height: r(40px) !important

.mobile-menu
	position: fixed
	width: 100vw
	height: 100vh
	z-index: 100
	display: none
	top: 0
	left: 0
	background: rgba(#fff , 0.1)
	backdrop-filter: blur(5px)
	.mobile-menu-overlay
		position: absolute
		z-index: 1
		height: 100%
		width: 100%
	.mobile-menu-wrapper
		z-index: 2
		height: 100%
		width: 100%
		position: relative
		transform: translateY(-100%)
		transition: 0.3s all
		pointer-events: none
		+parent-state(".is-open")
			transform: none
	.mobile-menu-container
		position: absolute
		pointer-events: all
		+center(both)
		height: 88vh
		width: 94vw
		background: linear-gradient(135deg, rgba(71,163,71,1) 50%, rgba(93,135,59,1) 100%)
		padding: r(20px)
		overflow: auto
		border-radius: r(20px)
	.logo-w
		margin-bottom: r(20px)
