$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $colors
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(body), color(white))
		&.has-icon
			em
				background-color: color(white)
				color: color(secondary)
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: $value
		+on-hover
			background-color: $value
			color: contrast-color($value, color(body), color(white))

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	display: inline-flex
	+flex-center
	height: r(40px)
	border-radius: r(40px) 0px
	padding: r(12px) r(32px) r(8px)
	+trans-all
	white-space: nowrap
	text-transform: uppercase
	font-weight: 600
	&.btn-secondary-light
		+text(body)
	&.has-icon
		position: relative
		em
			position: absolute
			+center("Y")
			display: inline-flex
			+flex-center
			width: r(28px)
			height: r(28px)
			border-radius: r(40px) 0px
			padding-right: r(2px)
			&.fa-phone
				font-size: r(12px)
				&:before
					transform: matrix(-1, 0, 0, 1, 0, 0)
	&.is-icon-left
		padding: r(12px) r(28px) r(8px) r(48px)
		em
			left: r(8px)
	&.is-icon-right
		padding: r(12px) r(48px) r(8px) r(28px)
		em
			right: r(8px)
	&.btn-secondary-light
		em
			background-color: color(secondary)
			color: color(white)
	&.btn-white
		em
			background-color: color(secondary)
			color: color(white)
	&.view-more
		padding-left: 0
		padding-bottom: 0
		padding-top: 0
		height: auto

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(r(60px))
	border: 1px solid color(grey-999)
	+center(Y)
	+text(grey-999)
	font-size: 2rem
	position: absolute
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+mq-max(xxl)
		+bg(grey-999)
		+text(white)
	+mq-min(lg)
		&.swiper-prev
			left: 0
		&.swiper-next
			right: 0
	+mq-min(xxl)
		margin: 0 r(32px)
		&.swiper-prev
			right: 100%
			left: auto
		&.swiper-next
			right: auto
			left: 100%
	&-white
		border-color: #fff
		+text(white)

	+on-hover
		+bg(main)
		+text(white)
