.subnav
	ul
		+mq-min(md)
			+flex-gap(r(24px))
			white-space: nowrap
			overflow-x: auto
			justify-content: center
		+mq-max(md)
			display: none
			padding: 1rem
			box-shadow: 0 4px 4px rgba(0,0,0,.1)
			+content-margins
				margin-top: 1rem
		li
			a
				display: inline-flex
				padding: r(16px) r(32px) r(12px)
				border: 1px solid color(secondary)
				+flex-center
				+text(secondary)
				+radius(r(4px))
				+fz-lh(18px,20px)
				+trans-all
				+parent-state(":hover",".active")
					+bg(secondary)
					+text(white)
				+mq-min(lg)
					min-width: r(235px)
				+mq-max(md)
					width: 100%
					justify-content: flex-start
					padding: r(16px) r(16px) r(12px)
					font-size: 1rem
		&.is-center
			+mq-min(lg)
				justify-content: center

.sub-nav-mobile
	+remove(md,min)
	display: flex
	align-items: center
	justify-content: space-between
	padding: r(16px)
	+bg(secondary)
	+text(white)