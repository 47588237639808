.product-item
	+bg(white)
	+radius(r(8px))
	padding: r(8px)
	display: flex
	flex-direction: column
	.product-img
		+radius(r(8px))
		+bg(main-light)
		position: relative
		.product-discount
			height: r(24px)
			min-width: r(40px)
			border-radius: r(4px)
			display: inline-flex
			align-items: center
			line-height: 1
			justify-content: center
			color: #fff
			text-align: center
			padding: 0 r(8px)
			+bg(secondary)
			position: absolute
			top: 0
			right: 0
		figure
			a
				display: block
				+res-ratio(100,100)
	.product-caption
		padding: r(24px) r(12px)
		flex: 1 1 0%
		display: flex
		flex-direction: column
		+mq-max(md)
			padding: r(12px) 0
	.product-title
		@extend .heading-3
		line-height: 1.3333 !important
		+mq-max(md)
			font-size: 1.25rem !important
	.product-subtitle
		margin-top: r(4px)
		+text(grey-666)
		+fz-lh(20px,28px)
		+mq-max(md)
			font-size: 1rem
	.product-brief
		font-weight: 300
		margin-top: r(8px)
		+line(3)
	.product-bottom
		margin-top: r(24px)
		display: flex
		align-items: center
		justify-content: space-between
		+mq-max(md)
			margin-top: r(12px)
	.product-price
		.price
			font-size: r(20px)
			+text(secondary)
			+trans-all
		.old-price
			font-size: r(14px)
			+text(grey-999)
			margin-left: r(12px)
			+mq-max(md)
				margin-left: 0
	.product-buy
		a
			display: flex
			+flex-center
			+circle(r(36px))
			border: 1px solid color(grey-e1)
			font-size: r(14px)
			+text(grey-999)
			+trans-all
	.product-progress
		display: flex
		align-items: center
		justify-content: center
		height: r(16px)
		border-radius: r(8px)
		font-size: 12px
		+bg(secondary-light)
		color: color(secondary)
		margin: 0 r(12px)
		margin-top: auto
	.product-deadline
		display: flex
		padding: r(10px) r(12px) r(20px)
		margin-left: -5px
		.count
			flex: 1
			padding-left: 5px
			> *
				display: block
			.number
				height: 25px
				padding-top: 3px
				background: color(secondary)
				color: #fff
				font-size: 14px
				font-weight: 700
				border-radius: 5px
				display: flex
				align-items: center
				justify-content: center
			.unit
				text-align: center
				font-size: 12px
				color: color(secondary)
				margin-top: 8px
				font-weight: 400
	+on-hover
		.product-price
			.price
				+text(brown)
		.product-buy
			a
				+bg(secondary)
				+text(white)
				span
					&:before
						content: '\2b'

.product-slider
	.swiper-slide
		height: auto
		.product-item
			height: 100%
