.contact-showroom-bg-2
	+cover-background
	+mq-min(lg)
		padding-bottom: r(180px) !important
	.subnav-wrap
		margin-bottom: r(24px)
		+mq-min(lg)
			margin-bottom: r(36px)
	.subnav
		ul
			li
				a
					+bg(grey-f3)
	.text-shadow
		text-transform: none
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
		margin-bottom: 1.5rem
		+mq-min(lg)
			margin-bottom: r(60px)

.contact-note
	position: relative
	p
		font-size: r(36px)
		text-align: center
	.contact-note-bg-small
		position: absolute
		right: 0
		bottom: r(-52px)
		+remove(lg,max)
	.contact-note-bg-large
		position: absolute
		right: 0
		bottom: r(40px)
		+remove(lg,max)