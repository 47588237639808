.about-2
	.item-list-wrap
		+mq-max(lg)
			background-image: none !important
		+mq-min(lg)
			padding-top: r(240px)
			padding-bottom: r(40px)
			background-repeat: no-repeat
			background-size: auto
			background-position: 50% 0
			min-height: 49.75rem
	.item-list
		background: #FFFFFF
		box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.1)
		border-radius: 2rem
		padding: 1rem
		+mq-min(lg)
			padding: 1rem 0 r(40px)
		.item-col
			+after-first(1)
				border-top: 1px solid color(main)
				+mq-min(lg)
					border-left: 1px solid color(main)
					border-top: 0
		.item
			+fz-lh(18px,24px)
			padding: r(30px) 0
			display: flex
			flex-direction: column
			+mq-min(lg)
				padding: 0 r(40px) r(24px)
			.icon
				+circle(r(120px))
				display: flex
				+flex-center
				+text(tertiary)
				font-size: r(60px)
				+bg(main-light)
				margin: 0 auto
				+mq-min(lg)
					margin: r(-72px) auto 0
			.title
				margin: 1.5rem 0 1rem
				text-transform: uppercase
				+text(secondary)
			.btn-wrap
				margin-top: auto
			.content
				margin-bottom: r(40px)
				+mq-max(lg)
					margin-bottom: r(20px)
