.service-personal-3
	.section-heading 
		padding: r(32px) 0.5rem
		+bg(tertiary)
		+text(white)
		text-transform: uppercase
		position: relative
		&:before
			content: ''
			position: absolute
			width: 844 / 1920 * 100%
			height: r(286px)
			background-image: url(../img/service-bg-1.png)
			background-repeat: no-repeat
			background-size: contain
			top: r(-60px)
			pointer-events: none
			+remove(lg,max)
		&:after
			content: ''
			position: absolute
			width: 859 / 1920 * 100%
			height: r(321px)
			background-image: url(../img/service-bg-2.png)
			background-repeat: no-repeat
			background-size: contain
			top: r(-60px)
			pointer-events: none
			right: 0
			+remove(lg,max)
