
.service-select-popup
	width: r(855px)
	padding: 0
	.popup-content
		min-height: r(212px)
		background-image: url(../img/service-popup-bg.png)
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		display: flex
		align-items: center
		justify-content: center
		padding: r(16px)
		.service-select
			text-align: center
			h3
				font-weight: 700
				+fz-lh(20px,20px)
				text-align: center
				text-transform: uppercase
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
				margin-bottom: r(20px)
			ul
				display: flex
				justify-content: center
				gap: r(32px)
			a
				min-width: r(200px)
				height: r(50px)
				display: flex
				align-items: center
				justify-content: center
				border-radius: 1000px
				font-weight: 600
				+fz-lh(16px,16px)
				+text(tertiary)
				text-transform: uppercase
				+mq-max(sm)
					min-width: r(150px)
