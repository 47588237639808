.footer-top
	padding: r(48px) 0
	border-top: 1px solid color(grey-e1)
	+mq-max(lg)
		padding: r(32px) 0
	.container
		position: relative
	.pot-bg
		position: absolute
		bottom: r(-110px)
		left: r(-60px)
		+remove(lg,max)
	.item
		display: flex
		align-items: center
		+mq-max(md)
			flex-direction: column
		.icon
			display: flex
			+flex-center
			+flex-width(r(84px))
			height: r(84px)
			+radius(50%)
			+bg(secondary)
			+text(white)
			font-size: r(40px)
		.content
			flex: 1
			padding-left: r(16px)
			font-size: r(18px)
			+mq-max(md)
				text-align: center
				margin-top: 1rem
				padding-left: 0

.footer-middle
	padding: r(52px) 0 r(24px)
	+mq-max(lg)
		padding: r(32px) 0
	.subscribe-title
		h3
			font-size: r(20px)
			font-weight: 700
			margin-bottom: r(8px)
	.subscribe
		.subscribefrm
			position: relative
			input
				width: 100%
				height: r(50px)
				border: 1px solid color(grey-e1)
				padding: 1rem r(60px) 1rem r(20px)
				background: transparent
				color: #fff
				font-size: 1rem
				+placeholder
					color: #fff
			button
				+reset-button
				+center(Y)
				position: absolute
				right: r(20px)
				font-size: r(20px)
	.social-list
		ul
			display: flex
			align-items: center
			+content-margins
				margin-left: r(8px)
			li
				a
					display: inline-flex
					+flex-center
					+circle(r(50px))
					+border($color: white)
					font-size: r(24px)

.footer-bottom
	position: relative
	background: linear-gradient(97.17deg, #F0EFEF 0.39%, #EDEAEA 100.44%)
	.pot-bg
		position: absolute
		bottom: 0
		right: 0
		width: 410 / 1920 * 100%
		+remove(lg,max)
		z-index: 1

.footer-bottom-wrap
	padding: r(40px) 0 r(60px)
	position: relative
	z-index: 2
	.footer-item
		h3
			font-size: r(24px)
			+text(secondary)
			font-weight: 700
			margin-bottom: r(28px)
	.footer-info
		ul
			+content-margins
				margin-top: 1rem
			li
				display: flex
				gap: 1rem
				em
					font-size: r(18px)
					flex: 0 0 r(18px)
					+text(secondary)
		.bct
			margin-top: r(28px)
	.footer-link
		ul
			font-size: r(18px)
			line-height: r(24px)
			+content-margins
				margin-top: 0.5rem
			li
				a
					+on-hover
						+text(main)
		&.is-2col
			.footer-link-row
				display: flex
				flex-wrap: wrap
				margin-left: -1rem
				margin-bottom: -0.5rem
				> *
					padding-left: 1rem
					padding-bottom: 0.5rem
				.footer-link-1
					width: 35%
				.footer-link-2
					width: 65%

.footer-copyright
	position: relative
	z-index: 2
	padding: r(12px) 0
	+bg(brown)
	+text(white)
	.footer-copyright-wrap
		display: flex
		align-items: center
		justify-content: space-between
		+fz-lh(14px,20px)
		+mq-max(lg)
			display: block
			text-align: center
