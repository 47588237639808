.project-detail-hero
	padding: r(60px)
	box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
	border-radius: 2rem
	+mq-max(md)
		border-radius: 1rem
		padding: r(20px)
	figure
		border-radius: 2rem
		overflow: hidden
		+mq-max(md)
			border-radius: 1rem

.project-detail-table
	padding: r(48px) r(80px)
	box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
	border-radius: 0 0 2rem 2rem
	+fz-lh(18px,24px)
	+mq-max(md)
		padding: r(20px)
		margin-top: r(20px)
		border-radius: 1rem
	table
		width: 100%
		tr
			+after-first(1)
				border-top: 1px solid #d9d9d9
			td
				padding: r(20px) 0 r(12px)
				+first(1)
					width: 40%
					+text(grey-666)
				+after-first(1)
					font-weight: 300

.project-detail-gallery
	.img
		+trans-all
		background: #000
		figure
			+trans-all
			+res-ratio(866, 510)
			opacity: 0.6
	.swiper-slide
		+mq-min(lg)
			.img
				transform: scale(0.7)
			&-next,&-prev
				z-index: 2
				.img
					transform: scale(0.85)
			&-active
				z-index: 3
				.img
					transform: scale(1)
					figure
						opacity: 1
	.swiper-navigation
		position: absolute
		+center(both)
		width: 860px
		z-index: 4
		.swiper-btn
			margin: 0 r(-32px)
			+bg(grey-999)
			+text(white)
			border: 0
