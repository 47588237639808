.blog-info-list
	+mq-min(lg)
		.row
			+gap-override(15px, 30px)
	
.blog-info-item
	+bg(white)
	padding: r(8px)
	+radius(r(8px))
	.img
		+radius(r(8px))
		overflow: hidden
		a
			+res-ratio(1,1)
		img
			+trans-all
	.caption
		padding: r(28px) r(12px) r(20px)
		+mq-max(md)
			padding: 1rem 0.5rem 0.5rem
	.title
		+font(600,24px,32px)
		margin-bottom: r(8px)
		+mq-max(md)
			font-size: r(18px)
	.brief
		+fz-lh(18px,24px)
		font-weight: 300
		+line(3)
		+mq-max(md)
			font-size: r(16px)
	+on-hover
		.img
			overflow: hidden
			img
				transform: scale(1.05)