.home-banner
	.banner
		position: relative
		.img,.video
			+mq-min(md)
				height: r(860px)
				> *
					+fit-cover
			+mq-max(md)
				+res-ratio(16,9)
		.caption
			position: absolute
			+center(Y)
			width: 100%
			+text(white)
			+mq-max(md)
				top: auto
				transform: none
				bottom: r(20px)
		.title
			font-size: r(60px)
			margin-bottom: r(24px)
			+mq-max(md)
				font-size: r(24px)
				margin-bottom: 0.5rem
		.desc
			+font(300,32px,40px)
			margin-bottom: r(48px)
			+mq-max(md)
				margin-bottom: 0.5rem
				font-size: r(16px)
			.btn
				font-size: r(16px)
				margin-top: r(12px)
	.swiper-pagination
		bottom: r(48px)
		+center(X)
		+content-margins
			margin-left: r(8px)
		.swiper-pagination-bullet
			height: r(4px)
			width: r(20px)
			+bg(white)
			border-radius: 0
			+trans-all
			&-active
				width: r(60px)