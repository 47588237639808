.info-cate-slider
	.item
		.img
			a
				+res-ratio(1,1)
				border-radius: 50%
				overflow: hidden
				+border($color: grey-e1)
				+trans-all
		.title
			text-align: center
			+font(600,20px,24px)
			margin-top: 1rem
		+on-hover
			.img
				a
					box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px
