.service-gallery-slider
	.img
		figure
			+res-ratio(620, 350)
	.swiper-btn
		margin: 0 r(-90px)

.service-contact
	.wrap-form
		padding: r(60px)
		+radius(r(8px))
		+bg-opa(white, 0.8)
		+bg-img-cover("../img/service-bg-5.png")
		+mq-max(md)
			padding: r(24px)