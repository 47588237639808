.sub-banner-section
	.banner
		position: relative
		.img
			+mq-max(md)
				+res-ratio(16,9)
		.caption
			position: absolute
			bottom: r(80px)
			width: 100%
			+text(tertiary)
			+mq-max(md)
				top: auto
				transform: none
				bottom: r(20px)
		.title
			font-size: r(60px)
			margin-bottom: r(24px)
			+mq-max(md)
				font-size: r(24px)
				margin-bottom: 0.5rem
		.desc
			+font(300,32px,40px)
			margin-bottom: r(48px)
			+mq-max(md)
				margin-bottom: 0.5rem
				font-size: r(16px)

.news-detail-page,.product-detail-page,.recruitment-detail-page
	.sub-banner-section
		display: none