.checkout-section
	.card-item
		+ .card-item
			margin-top: r(30px)
		.card-heading
			padding: r(20px) r(25px)
			border-bottom: 1px solid #ebebeb
			.card-title
				font-size: r(18px)
				color: map-get($colors, blue)
				text-transform: uppercase
				font-weight: 700
		.card-body
			padding: r(25px)

.checkout-info-form
	.form-group
		display: flex
		align-items: center
		+ .form-group
			margin-top: 20px
		label
			white-space: nowrap
			flex: 1 1 0%
			&.error 
				color: #dd0705
				font-size: 12px
				margin-top: 4px
				font-style: italic
		.form-control
			flex: 3 1 0%
	.add-new-address
		a
			color: #068fdd
			text-decoration: underline
	@media screen and ( max-width: 576px )
		.form-group
			flex-wrap: wrap
			label
				flex: 0 0 100%
				margin-bottom: 10px
			.form-control
				flex: 0 0 100%
				
.checkout-shipping-wrap
	margin-top: r(30px)
	@media screen and ( max-width: 768.98px )
		margin-top: 0
.checkout-check-list
	padding-left: 0
	list-style: none
.checkout-check-item
	cursor: pointer
	+ .checkout-check-item
		margin-top: 20px
	label
		display: flex
		align-items: center
	input[type="radio"],input[type="checkbox"]
		position: absolute
		height: 1px
		width: 1px
		visibility: hidden
		clip: rect(0px, 0px, 0px, 0px)
		opacity: 0
		&:checked + .fake-radio
			&::before
				content: "\F050"
				color: color(main)

	.fake-radio
		&::before
			font-family: remixicon!important
			content: "\EB7D"
			margin-right: r(12px)
			font-size: r(16px)
			color: #999999
	.content
		width: calc( 100% - 30px )
		border: 1px solid #e1e1e1
		border-radius: 5px
		display: flex
		.img
			width: 100px
			min-height: 100px
			display: flex
			align-items: center
			justify-content: center
			border-right: 1px solid #e1e1e1
		.text-wrap
			flex: 1
			display: flex
			align-items: center
		.text
			padding: 15px 20px
			line-height: 1.375
			flex: 1
			*
				+ *
					margin-top: 10px
			table
				width: 100%
				tr
					td
						&:first-child
							width: 33.33%
						&:last-child
							padding-left: 10px
			&.address-item
				* + *
					margin-top: 0
		.services
			display: flex
			align-items: center
			padding-right: 20px
			select
				appearance: none
				background-image: url(../img/sl-checkout.png)
				background-position: 95%
				background-repeat: no-repeat
				font-size: 1rem
				width: r(250px)
				height: 35px
				border: 0
				border-bottom: 2px solid #e1e1e1
				padding: 0 15px
	.vnpay-type-group
		margin-left: 30px
		margin-top: r(20px)
		padding: 15px
		border: 1px solid #e1e1e1
		border-radius: 5px
		.vnpay-type-group-title
			line-height: 1.375
			font-weight: 700
		&.vnpay-type-group-dropdown
			.vnpay-type-group-title
				display: flex
				align-items: center
				justify-content: space-between
				i
					font-size: r(20px)
			.bank-list
				display: none
	.bank-list
		padding-top: 10px
		.row
			margin-left: -10px !important
			margin-bottom: -10px !important
			> * 
				padding-left: 10px !important
				padding-bottom: 10px !important
	.select-bank
		cursor: pointer
		.content
			padding-top: 66.66%
			position: relative
			width: 100%
			border-style: dashed
			img
				position: absolute
				top: 50%
				left: 50%
				max-width: 90%
				height: auto
				transform: translate(-50%,-50%)
		input[type="radio"],input[type="checkbox"]
			&:checked + .content
				border-color: map-get($colors, main)
	@media screen and ( max-width: 576px )
		// .vnpay-type-group
		// 	margin-left: 0
		.content
			.services
				select
					width: 150px
.checkout-issue-invoice-wrap
	.checkout-check-item
		.content 
			border: 0

	.card-body
		display: none
		&.show
			display: block
.Module-194
	height: 100%
	.ModuleContent
		position: sticky
		top: 100px
		z-index: 3
.checkout-cart-summary
	.cart-summary-item
		display: flex
		justify-content: space-between
		flex-wrap: wrap
		margin-bottom: 20px
		padding-bottom: 20px
		border-bottom: 1px solid #ebebeb
		.product-detail-promo
			width: 100%
		.product-link
			display: flex
			width: 50%
			.img
				width: 50px
				margin-right: 10px
			.text
				flex: 1
				line-height: 1.375
		.product-price
			text-align: right
			*
				+ *
					margin-top: 5px
			.total-price
				font-size: r(18px)
	.cart-summary-price
		hr
			overflow: visible
			text-align: inherit
			margin: r(20px) 0
			border: 0
			border-top: 1px solid #e5e5e5
		ul
			li
				+ li
					margin-top: 1rem

.checkout-point-wrap
	.point-slider
		height: 5px
		border: 0 !important
		background: #bcbcbc
		margin: 0 5px 10px
		.ui-slider-handle
			border-radius: 50%
			top: 50%
			transform: translateY(-50%)
			width: 15px
			height: 15px
			border: 1px solid #666666
			background-color: #fff
		.ui-slider-range
			background-color: map-get($colors, main)
			border: 0
	.point-slider-input
		label
			flex: 1
		input
			width: 156px
			background: #fafafa
			border: 1px solid #e1e1e1
			border-radius: 5px
			margin-left: 10px
			padding: 0 15px
			height: 40px

.checkout-page,.cartpage
	@media screen and ( max-width: 768.99px )
		.breadcrumb-wrapper
			border-bottom: 1px solid #eeeeee
			.container
				max-width: none
		.checkout-section
			padding: 0 !important
			.container
				padding: 0
				max-width: none
			.row
				margin-bottom: 0
				margin-left: 0
				> *
					padding-bottom: 0
					padding-left: 0
			.card-item
				border-radius: 0
				box-shadow: none
				border-top: 5px solid #e5e5e5
				+ .card-item
					margin-top: 0
				.card-heading
					border-bottom: 0
					padding-bottom: 0
					padding-left: 15px
					padding-right: 15px
				.card-body
					padding: 20px 15px
		.checkout-issue-invoice-wrap
			padding-bottom: 20px
		.checkout-info-form
			.form-group
				flex-direction: column
				align-items: start
				label
					display: block
					margin-bottom: 10px
					flex: auto
					font-size: 14px
				.form-control
					flex: auto
					width: 100%
		.checkout-check-item
			.content
				.img
					display: none
		.cart-group-button
			padding: 15px
			display: block
			position: fixed
			bottom: 0
			left: 0
			background: #fff
			width: 100%
			box-shadow: 0 0 15px 0 rgba(18,51,104,.1)
			z-index: 2224
			margin: 0
			> *
				margin: 0
			.btn-next
				width: 100%
			.btn-back
				display: none
			.cart-summary-total-price
				margin-bottom: 10px
				justify-content: flex-end
				align-items: baseline
				strong
					font-size: 16px
					font-weight: 400
				.text-main
					font-size: 24px
					font-weight: 700
					margin-left: 20px

.cart-aside,.checkout-cart-summary
	line-height: 1.25