.blog-info-detail-image-wrap
	display: flex
	padding: r(56px) r(60px)
	+bg(main-light)
	+radius(2rem)
	+mq-max(lg)
		flex-wrap: wrap-reverse
	+mq-max(md)
		padding: r(24px)
		+radius(1rem)

.blog-info-detail-thumbnail
	+flex-width(100%)
	margin-top: r(16px)
	+mq-min(lg)
		+flex-width(r(120px))
		margin-top: 0
	.swiper-container
		padding: 4px
		margin: -4px
		+mq-min(lg)
			height: r(656px)
	.img
		+res-ratio(1,1)
		+radius(0.5rem)
		overflow: hidden
	.swiper-slide-thumb-active
		.img
			box-shadow: 0px 0px 0px 3px rgba(140,183,63,1)
	.swiper-btn
		+center(X)
		top: auto
		margin: r(-32px) 0
		&.swiper-prev
			bottom: 100%
			right: auto
			left: 50%
		&.swiper-next
			top: 100%
			left: 50%

.blog-info-detail-images
	+flex-width(100%)
	+mq-min(lg)
		+flex-width(calc(100% - 128px))
		padding-left: r(32px)
	.img
		+res-ratio(1,1)
		+radius(0.5rem)
		overflow: hidden

.blog-info-detail-content
	padding: r(40px) r(28px)
	box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
	+radius(2rem)
	hr
		background: transparent
		border: 0
		border-top: 1px solid #d9d9d9
		margin: r(16px) 0
	+mq-max(md)
		padding: r(24px)
		+radius(1rem)

.blog-info-detail-table
	font-weight: 300
	+fz-lh(18px,24px)
	table
		width: 100%
		td
			width: 50%
			padding: r(12px) 0
			+first(1)
				padding-right: r(16px)