.blog-detail
	padding: r(60px)
	box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.1)
	border-radius: 2rem
	position: relative

.blog-detail-util
	.detail-social
		text-align: center
		margin-left: 1rem
		+mq-min(xxl)
			margin-left: 0
			margin-top: r(20px)
		a
			margin: 0 auto
	+mq-max(xxl)
		display: flex
		align-items: center
		margin-top: 1rem
	+mq-min(xxl)
		position: absolute
		right: 100%
		top: r(60px)

.blog-detail-heading
	padding-bottom: 1rem
	border-bottom: 1px solid #d9d9d9
	margin-bottom: 2rem