.section-home-4
	position: relative
	.content-container
		padding: r(40px) 0
		+mq-min(xl)
			padding: 0
			position: absolute
			width: 100%
			top: 50%
			+center(Y)
	.content-wrap
		+mq-min(xl)
			padding-right: r(48px)
	.img
		display: block
		position: relative
		+mq-min(xl)
			height: r(580px)
			img,iframe
				+fit-cover
		.icon
			position: absolute
			+center(both)
			width: r(122px)
			img
				width: 100%
	.item-list
		margin-top: r(24px)
		+row(36px)
		+mq-max(md)
			+row(24px)
		.item-col
			+flex-width(33.33%)
			+mq-max(md)
				+flex-width(50%)
		.item
			.icon
				+circle(r(90px))
				+bg(white)
				display: flex
				+flex-center
				font-size: r(40px)
				+text(brown)
			.title 
				+font(700,18px,24px)
				text-transform: uppercase
				margin-top: r(24px)
			.brief
				+font(400,18px,24px)
				margin-top: r(12px)
