.project-filter-wrap
	padding: 1rem 2rem
	border: 1px solid color(grey-e1)
	+mq-max(md)
		padding: 1rem

.project-filter
	+row(24px)
	.form-group
		display: flex
		align-items: center
		+flex-width(46%)
		+mq-max(md)
			+flex-width(100%)
			flex-direction: column
			align-items: flex-start
		label
			margin-right: r(12px)
			white-space: nowrap
			font-size: r(18px)
			+mq-max(md)
				margin-bottom: 0.5rem
		select
			height: r(40px)
			border-color: color(grey-e1)
			+select-bg($bg: "../img/sl-small.png", $pos: calc(100% - 1.25rem))
	.form-submit
		flex: 1
	.btn-submit
		display: flex
		+flex-center
		height: r(40px)
		flex: 0 0 100%
		border-radius: r(40px)
		+bg(main)
		+text(white)
		font-size: r(18px)
		text-transform: uppercase
		padding-top: 0.25rem

.project-item
	display: block
	position: relative
	padding: 2rem r(20px)
	box-shadow: 6px 6px 15px 1px rgba(0, 0, 0, 0.1)
	border-radius: 2rem
	+bg(white)
	+mq-max(sm)
		padding: r(12px)
		border-radius: r(12px)
	+on-hover
		.project-title 
			text-decoration: underline
	.project-img
		figure
			border-radius: r(8px)
			overflow: hidden
			+res-ratio(422, 277)
	.project-title 
		padding: r(20px) r(40px)
		+bg(tertiary)
		+text(white)
		border-radius: r(8px)
		+fz-lh(20px,24px)
		font-weight: 600
		position: absolute
		bottom: r(20px)
		left: 0
		right: 0
		display: flex
		align-items: center
		+mq-max(sm)
			bottom: 0
			padding: r(16px)
			font-size: 1rem
		span
			padding-right: r(20px)
		em
			display: inline-flex
			+flex-center
			width: r(28px)
			height: r(28px)
			border-radius: r(40px) 0px
			padding-right: r(2px)
			background-color: color(secondary)
			color: color(white)
			font-size: r(12px)

.project-slider
	.swiper-container
		padding: 20px
		margin: -20px