.detail-social
	display: flex
	a
		+circle(r(48px))
		display: inline-flex
		+flex-center
		+bg(main)
		+text(white)
		font-size: r(24px)

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Roboto', sans-serif
	&:before
		font-family: 'Font Awesome 5 Pro'

.frm-btn-reset
	display: none !important
