.product-list-heading
	display: flex
	justify-content: space-between
	align-items: center
	+mq-max(md)
		display: block
	
.product-list-util
	+row(32px)
	+mq-max(md)
		+gap-override(16px)
		margin-top: 1rem
		> *
			+flex-width(50%)


.product-sort
	display: flex
	align-items: center
	+mq-max(md)
		display: block
	label
		margin-right: r(20px)
		+mq-max(md)
			margin-right: 0
			margin-bottom: 0.5rem
			display: inline-block
	.form-group
		border: r(4px) solid color(main-light)
		+radius(r(4px))
		select
			+select-bg("../img/sl-bg-white.png",$pos: calc(100% - 0.75rem))
			background-color: color(secondary)
			+text(white)
			border: 0
			height: r(40px)
			padding-right: r(48px) !important