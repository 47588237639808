.side-nav
	+bg(secondary)
	+text(white)
	+radius(r(8px))
	overflow: hidden
	.side-nav-heading
		padding: r(28px) r(32px) r(20px)
		+bg(tertiary)
		display: flex
		align-items: center
		justify-content: space-between
		+mq-max(lg)
			padding: r(16px) r(16px) r(12px)
		h2
			+mq-max(lg)
				font-size: r(20px) !important
	.side-nav-mobile-toggle
		+remove(lg,min)
		font-size: r(24px)
	.side-nav-body
		padding: r(24px) r(12px) r(32px)
		+mq-max(lg)
			display: none
	.side-nav-menu
		ul
			+content-margins
				margin-top: r(4px)
			> li
				+fz-lh(18px,24px)
				.links
					display: flex
					+radius(r(4px))
					+trans-all
					padding: r(12px) r(20px) r(8px)
					+on-hover
						+bg(main-light)
						+text(body)
					> a
						display: block
						width: 100%
				&.active
					> .links
						+bg(main-light)
						+text(body)
				&.has-accordion
					position: relative
					.links
						a
							flex: 1
						.accordion-toggle
							cursor: pointer
							position: relative
					.accordion-menu
						+flex-width(100%)
						padding-left: r(40px)
						font-weight: 300
						padding-top: r(8px)
						display: none
						> ul
							+content-margins
								margin-top: 0.5rem
							> li
								> a
									padding: 0
									font-size: 1rem
								&.active,&:hover
									a
										text-decoration: underline
					&.active
						.accordion-menu
							display: block
