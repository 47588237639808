.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(150px)
	right: r(20px)
	+mq-max(md)
		bottom: r(8px)
		right: r(8px)
	ul
		text-align: center
		li
			+after-first(1)
				margin-top: r(10px)
			.btn-circle
				+trans-all
				+circle(r(60px))
				display: inline-flex
				+flex-center
				+mq-max(md)
					+circle(r(48px))
				em
					font-size: r(24px)
					+mq-max(md)
						font-size: 1rem
				&.back-to-top
					flex-direction: column
					+bg(white)
					+text(grey-999)
					font-size: 1rem
					+mq-max(md)
						font-size: r(12px)
				&.cta-phone
					+circle(r(80px))
					+border($color: secondary)
					+mq-max(md)
						+circle(r(48px))
					.inner
						display: flex
						+flex-center
						+circle(r(60px))
						+bg(secondary)
						+text(white)
						+mq-max(md)
							+circle(r(40px))
			&.has-tooltip
				position: relative
				.btn-circle
					position: relative
					z-index: 2
				.tooltip
					position: absolute
					right: 0
					height: r(40px)
					+center(Y)
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					background: #00406D
					border: 1px solid #FFFFFF
					+text(white)
					border-radius: 999px
					z-index: 1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all
					+fz-lh(18px,24px)

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)