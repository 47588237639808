.career-detail-brief
	table
		width: r(660px)
		+mq-max(md)
			width: 100%
		tbody
			+content-margins
				border-top: 1px solid color(grey-e1)
		tr
			td
				padding: r(16px) 0 r(8px)
				+first(1)
					font-weight: 700
				+after-first(1)
					font-weight: 300
					padding-left: 1rem
					
.career-detail-content
	+fz-lh(16px,24px)
	font-weight: 300
	h3
		+font(700,20px,24px)
		+text(grey-666)
		margin-bottom: r(8px)
		&:before
			content: '•'
			margin-right: r(8px)
	* + h3
		margin-top: 1rem

.btn-group
	margin-top: r(32px)
	display: flex
	align-items: center
	flex-wrap: wrap
	margin-bottom: r(-20px)
	> *
		margin-right: r(20px)
		margin-bottom: r(20px)
	.btn
		min-width: r(200px)
	span
		display: block
		text-align: center
	+mq-max(md)
		display: block
		> *
			width: 100%

.career-detail-other
	padding: r(8px)
	border-radius: r(4px)
	+bg(grey-f3)
	.other-title
		padding: 1rem r(20px) 0.5rem
		border-radius: r(4px)
		+bg(main-light)
		font-size: r(20px)
		font-weight: 600
	.other-item-list
		padding: r(24px) r(8px)
		+content-margins
			padding-top: 1rem
			margin-top: 1rem
			border-top: 1px solid color(grey-e1)
	.other-item
		h3
			line-height: 1.5
			margin-bottom: r(8px)
		ul
			display: flex
			> *
				width: 50%
			li
				display: flex
				font-weight: 300
				+fz-lh(14px,20px)
				+text(grey-999)
				span
					margin-right: r(12px)
					font-size: r(16px)

.t-ff109
	font-size: 16px

.apply-frm
	position: relative
	margin: 0
	background: url(../img/career-popup-bg.jpg)
	background-repeat: no-repeat
	background-size: cover
	background-position: center
	line-height: 1.375
	padding: r(52px) r(30px)
	overflow: hidden
	+mq-max(sm)
		padding: 16px
	.text-heading-4
		font-size: 2em
	.form-group
		.label
			display: none
		// input[type="text"], input[type="date"], select, textarea
		// 	padding: 0.75em 1em !important
		// 	height: 3.125em
		// 	font-size: 1em
		textarea
			height: 8em
	.frm-btnwrap
		margin-top: 0 
	.attachfile1
		position: relative
		.label
			display: flex
			align-items: center
			height: 3.125em
			padding: 0 2em
			+bg(grey-999)
			+text(body)
			cursor: pointer
		.ruBrowse
			display: none
		.ruInputs
			position: static !important
			li
				margin-bottom: 0
				height: auto
		.ruFileWrap
			height: auto
			position: static !important

.recruitment-detail-page
	.fancybox-iframe
		width: 869px !important