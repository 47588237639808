.breadcrumb-wrapper
	+remove(lg, 'max')
	+bg(white)
	+text(grey-999)
	.breadcrumb
		padding: r(12px) 0
		display: flex
		align-items: center
		li
			a
				+fz-lh(14px,18px)
				display: flex
				align-items: center
			+ li
				padding-left: r(12px)
				display: flex
				align-items: center
				&::before
					content: "\f054"
					font-family: 'Font Awesome 6 Pro'
					margin-right: r(12px)
					font-weight: 400
					font-size: r(14px)
					position: relative
					font-weight: 300
			+first(1)
				a
					font-size: 0
					&:before
						content: '\f015'
						font-family: 'Font Awesome 6 Pro'
						+fz-lh(16px,18px)
						font-weight: 300
