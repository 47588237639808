$container-padding: r(15px)
$container-max-width: 100%
$container-xxxl-width: 1640px
$container-xxl-width: 1440px
$container-xl-width: 1260px
$container-lg-width: 1140px
$container-md-width: 92vw
$container-sm-width: 94vw

.container
	width: $container-max-width
	padding-right: $container-padding
	padding-left: $container-padding
	margin-right: auto
	margin-left: auto
	+mq-min(md)
		max-width: $container-md-width
	+mq-min(lg)
		max-width: $container-lg-width
	+mq-min(xl)
		max-width: $container-xl-width
	+mq-min(xxl)
		max-width: $container-xxl-width

.container-large
	width: $container-max-width
	padding-right: $container-padding
	padding-left: $container-padding
	margin-right: auto
	margin-left: auto
	+mq-min(md)
		max-width: $container-md-width
	+mq-min(lg)
		max-width: $container-xxxl-width