.about-4
	padding: r(116px) 0
	+mq-max(lg)
		padding: r(40px) 0

.partner-slider
	.item
		+res-ratio(218,110)
		+bg(white)
		+border($color: grey-e1)
		img
			width: 90%
			height: auto
			+center(both)
			object-fit: none