.gallery-item
	display: block
	position: relative
	.gallery-img
		+res-ratio(690,432)
		border-radius: r(4px)
		overflow: hidden
	.gallery-title
		position: absolute
		bottom: 0
		right: 0
		padding: r(20px) 2rem r(12px) r(40px)
		font-size: r(20px)
		border-radius: 100px 0 0 0
		+bg(main-light)
		font-weight: 600
		text-align: center
		+line(2)