.about-6
	+mq-max(lg)
		margin-top: r(40px)
	.section-title
		+mq-min(lg)
			margin-bottom: r(100px)
	.content-wrap
		+bg-opa(brown, 0.6)
		position: relative
		border-radius: r(20px)
		+mq-min(lg)
			border-radius: r(20px) 0px 0px r(20px)
			&:before
				+pseudo
				left: 100%
				height: 100%
				width: 1000px
				+bg-opa(brown, 0.6)
		.bg-img
			position: absolute
			left: r(-128px)
			top: r(-30px)
			+remove(lg,max)
	.content
		padding: r(40px) r(20px) r(20px)
		+mq-min(lg)
			padding: r(92px) r(40px) r(128px)
		+mq-min(xl)
			margin: 0 0 r(-60px) 0
	.img
		text-align: center
		padding: 0 r(20px) r(40px)
		+mq-min(lg)
			padding: r(40px) 0
		+mq-min(xl)
			padding: r(80px) r(120px)
			+bg(grey-f3)
			border-radius: r(20px)
			margin: r(-60px) 0