.about-8
	+mq-max(lg)
		padding-top: r(40px)
	.content-wrap
		+bg(grey-f3)
		position: relative
		z-index: 1
		border-radius: r(20px)
		padding: r(40px) r(24px)
		+mq-min(lg)
			border-radius: 0
			padding: r(108px) 0 r(72px)
			margin-top: r(-64px)
			&:before
				+pseudo
				left: 100%
				height: 100%
				width: 1000px
				+bg(grey-f3)
				top: 0
		.bg-img
			position: absolute
			top: r(64px)
			left: r(-40px)
			z-index: 3
			+remove(lg,max)
	.item-list-wrap
		+after-first(1)
			margin-top: r(40px)
	.item-list
		+row(40px)
		.btn
			&:hover
				+bg(brown)
		+mq-max(lg)
			+row(20px)
		+mq-max(md)
			> *
				+flex-width(100%)
			.btn
				width: 100%
	