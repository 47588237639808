.section-title
	+fz-lh(48px,48px)
	font-weight: 700
	+mq-max(lg)
		+fz-lh(32px,32px)

.heading-2
	+font(700,32px,32px)

.heading-2.text-normal
	+font(400,32px,32px)

.heading-3
	+font(700,24px,24px)

.heading-4
	+font(700,20px,20px)
