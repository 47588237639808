.section-home-2
	position: relative
	.content-container
		padding: r(40px) 0
		+mq-min(xl)
			padding: 0
			position: absolute
			width: 100%
			top: 50%
			+center(Y)
	.article-content
		+mq-min(xl)
			padding-right: r(80px)
	.img
		+mq-min(xl)
			height: r(580px)
			img
				+fit-cover
	.item-list
		margin-top: r(24px)
		+row(60px)
		+mq-max(md)
			+row(24px)
		.item-col
			+mq-max(md)
				+flex-width(50%)
		.item
			.icon
				+circle(r(72px))
				+bg(white)
				display: flex
				+flex-center
				font-size: r(40px)
				+text(brown)
			.number
				margin-top: r(20px)
				+text(brown)
				font-weight: 700
				font-size: r(48px)
			.divider
				height: 1px
				background: linear-gradient(270deg, rgba(205, 182, 156, 0) 0%, rgba(216, 185, 24, 0) 0.01%, #CDB69C 95%)
				margin: 1rem 0
			.title 
				+fz-lh(18px,24px)
				text-transform: uppercase