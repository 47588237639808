.section-home-3
	.ModuleContent
		height: 100%
	
.home-service-cate
	height: 100%
	> *
		height: 100%
	.item
		height: 100%
		display: block
		position: relative
		.img
			height: 100%
			figure
				+mq-min(lg)
					height: 100%
					img
						+fit-cover
				+mq-max(lg)
					+res-ratio(480,600)
		.title
			position: absolute
			width: 100%
			bottom: r(56px)
			+text(white)
			text-align: center
			left: 0
			padding: 0 r(16px)
			+font(700,24px,32px)
			+mq-max(md)
				bottom: r(20px)
				font-size: r(20px)

.product-new-list
	padding: r(72px) r(80px)
	+mq-max(xxl)
		padding: r(52px) r(48px)
	+mq-max(lg)
		padding: r(40px) r(15px)
	.product-slider
		.swiper-btn
			margin: 0 r(-30px)
			+bg(white)
			+text(grey-999)