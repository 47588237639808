.faq-list
	counter-reset: section
	
.faq-item
	background: #fff
	overflow: hidden
	+ .faq-item
		margin-top: r(16px)
	.heading
		padding-bottom: r(16px)
		border-bottom: 1px solid rgba(color(grey-999) , 0.5)
		display: flex
		align-items: center
		transition: 0.3s all
		cursor: pointer
		.number
			&::before
				counter-increment: section
				content: counter(section)'. '
		.arrow
			position: relative
			top: 4px
			font-size: 14px
		&.active
			color: color(main)
			.arrow
				span:before
					content: '\f077'
	.title
		line-height: 1.5
	.arrow
		margin-left: auto
		transition: 0.3s all
	.body
		padding: r(16px) 0 0
		display: none
	@media screen and ( max-width: 768.98px )
		.heading
			padding: r(13px) r(15px) r(10px)
			.arrow
				top: 0px
			.number
				margin-right: r(12px)
				line-height: 1.15
		.body
			padding: r(15px)
