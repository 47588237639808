.about-1
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05)
	.content-wrap
		position: relative
	.content
		.bg-leaf
			position: absolute
			top: r(-60px)
			pointer-events: none
			+remove(lg,max)
			&.is-left
				right: 100%
				width: r(523px)
				transform: rotate(13.14deg)
				figure
					+res-ratio(523,644)
			&.is-right
				left: 100%
				width: r(521px)
				transform: rotate(-13.14deg)
				figure
					+res-ratio(521,643)
		h2
			+text(secondary)