.blog-corner-list
	+mq-min(lg)
		.row
			+gap-override(60px)
		
.blog-corner-item
	+bg(white)
	box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1)
	border-radius: 2rem
	overflow: hidden
	+mq-max(md)
		border-radius: 0.5rem
	.caption
		padding: r(40px) r(40px) r(80px)
		+mq-max(md)
			padding: r(20px)
	.subtitle
		font-weight: 300
		+text(secondary)
		margin-bottom: 0.5rem
		text-transform: uppercase
	.title
		+font(600,20px,28px)
		+mq-max(md)
			font-size: 1rem
	.brief
		font-weight: 300
		+line(4)
		+mq-min(md)
			text-align: justify
			margin-top: r(24px)
	.view-more
		margin-top: 1rem
		+mq-min(md)
			margin-top: r(24px)
	.img
		a
			display: block
			+res-ratio(430, 320)
		img
			+trans-all
	+on-hover
		.img
			overflow: hidden
			img
				transform: scale(1.05)