.career-list
	padding: r(8px)
	+radius(r(4px))
	+bg(grey-f3)
	+mq-max(md)
		overflow: auto
	table
		width: 100%
		+mq-max(md)
			width: 150vw
		thead
			+radius(r(4px))

		th
			+fz-lh(20px,28px)
			text-transform: uppercase
			+bg(main-light)
			padding: r(16px) 6rem r(8px)
			+mq-max(md)
				padding: r(16px) 1rem r(8px)
			+first(1)
				text-align: left
		tr
			+on-hover
				td
					+text(main)
		td
			padding: r(16px) 6rem r(8px)
			text-align: center
			+text(grey-666)
			+first(1)
				text-align: left
			+mq-max(md)
				padding: r(16px) 1rem r(8px)
		tbody
			+content-margins
				border-top: 1px solid color(grey-e1)