.service-business-1
	.bg-img
		+mq-max(lg)
			margin-bottom: 1rem
		figure
			+mq-min(lg)
				height: r(230px)
			img
				+fit-cover
	.item-list
		+mq-min(lg)
			margin: r(-104px) auto 0
		+mq-min(xl)
			width: 1140px
		.item
			.img
				figure
					+circle(r(208px))
					border: r(8px) solid #fff
					overflow: hidden
					margin: 0 auto
					+mq-max(sm)
						+circle(r(160px))
					img
						+fit-cover
			.title
				+font(300,18px,24px)
				margin-top: r(12px)
				text-align: center