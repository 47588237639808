.contact-showroom-bg-1
	+cover-background
	.subnav-wrap
		margin-bottom: r(24px)
		+mq-min(lg)
			margin-bottom: r(124px)
	.form-contact
		+mq-min(lg)
			padding-right: r(30px)
			padding-top: r(30px)
			border-right: 1px solid color(secondary)
	.form-info
		+mq-min(lg)
			padding-top: r(30px)
		ul
			+content-margins
				margin-top: r(12px)
				+mq-min(lg)
					margin-top: r(24px)
			li
				display: flex
				align-items: center
				.icon
					+flex-width(r(92px))
					+circle(r(92px))
					+bg(white)
					+flex-center
					display: flex
					img
						max-width: 60%
				.text
					padding-left: 1rem
					font-size: r(18px)
					+content-margins
						margin-top: r(12px)

.dealer-wrap
	.filter-map
		margin-bottom: r(24px)
		.form-group
			border: r(4px) solid color(main-light)
			+radius(r(4px))
			select
				+select-bg("../img/sl-bg-white.png")
				background-color: color(secondary)
				+text(white)
				border: 0
				height: r(40px)
	.dealer-result
		padding: r(24px) 1rem
		+border($color: secondary)
		border-radius: r(4px)
	.result-list
		height: 100%
		&.result-info
			height: auto
			+remove(lg,max)
		ul
			max-height: r(400px)
			overflow-y: auto
			+scrollbars(4px, color(secondary), #d9d9d9)
			padding-right: 2rem
			+content-margins
				margin-top: r(24px)
				padding-top: r(24px)
				border-top: 1px solid rgba(color(secondary), 0.2)
			li
				cursor: pointer
				+mq-min(lg)
					padding-left: r(20px)
					padding-right: r(20px)
				p 
					+fz-lh(16px, 24px)
					font-weight: 300
				.name
					+font(700, 18px, 28px)
					+text(main)
					text-transform: uppercase
	.map
		margin-top: auto
		iframe
			width: 100%
			height: r(280px)
