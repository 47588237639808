.cart
	text-align: center
	position: relative
	top: 2px
	.cart-toggle
		cursor: pointer
		.icon
			font-size: r(16px)
			+mq-max(md)
				font-size: 4.8vw
		.cart-amount
			font-size: r(12px)
			background: red
			color: #fff
			+mq-max(md)
				top: -2.5vw
				right: -2.5vw
				width: 3.6vw
				height: 3.6vw
			+mq-min(md)
				width: r(20px)
				height: r(20px)
				bottom: 100%
				left: 100%
	.cart-dropdown
		position: absolute
		top: 100%
		right: 0
		margin-top: 12px
		width: r(360px)
		background: #fff
		border-radius: 10px
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15)
		z-index: 20
		display: none
		&.show
			display: block

	.cart-container
		padding: r(25px)

	.cart-heading
		display: flex
		align-items: center
		justify-content: space-between
		padding-bottom: r(12px)
		border-bottom: 3px solid #ebebeb
		margin-bottom: r(15px)

	.cart-close
		font-size: r(20px)
		cursor: pointer
		position: absolute
		top: 10px
		right: 24px

	.cart-body
		overflow-y: auto
		height: r(265px)
		padding-right: 15px
		&::-webkit-scrollbar
			width: 4px
			background-color: #ccc
		&::-webkit-scrollbar-thumb
			background-color: color(main)
		ul
			li
				& + li
					margin-top: 15px
					padding-top: 15px
					border-top: 1px solid #ebebeb

	.cart-product
		.img
			display: block
			width: r(60px)
		.caption
			flex: 1
			text-align: left
			padding-left: r(20px)
		.name
			display: block
			margin-bottom: 10px
			&:hover
				color: map-get($colors, main)
		.quantity
			color: #999

	.cart-button
		margin-top: r(15px)
		padding-top: r(15px)
		border-top: 1px solid #ebebeb
		a
			height: r(40px)
			width: 100%
			background: color(main)
			color: #fff
			border-radius: 20px
			em
				margin-left: r(15px)
	@media screen and ( max-width: 576px )
		position: static
