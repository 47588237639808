.service-share-wrap
	+mq-max(2xl)
		display: flex
		align-items: center
	.btn-share
		+circle(r(84px))
		display: flex
		+flex-center
		background: rgba(color(secondary) , 0.5)
		+text(white)
		font-size: r(32px)
		margin-right: r(16px)
		flex: 0 0 r(84px)
		+mq-min(2xl)
			position: absolute
			right: 100%
			margin-right: 0
		span
			+circle(r(72px))
			display: flex
			+flex-center
			+bg(secondary)
.service-personal-1
	.item
		.icon
			+circle(r(120px))
			display: flex
			+flex-center
			margin: 0 auto
			+bg(main)
			font-size: r(48px)
			+text(white)
		.title
			text-align: center
			+fz-lh(18px,24px)
			font-weight: 300
			margin-top: r(24px)
			+mq-max(md)
				margin-top: r(12px)