.award-slider
	.swiper-container
		padding: r(15px)
		margin: r(-15px)
	.item
		border-radius: r(8px)
		+bg(white)
		box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1)
		.img
			figure
				+res-ratio(330,433)
		.title
			padding: r(20px) r(24px)
			+font(300,18px,24px)
			text-align: center