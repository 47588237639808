.contact-showroom-bg-3
	background-color: #fff
	background-position: bottom
	background-repeat: no-repeat
	background-size: 100% auto
	+mq-min(lg)
		padding-bottom: r(260px) !important
	.subnav-wrap
		margin-bottom: r(24px)
		+mq-min(lg)
			margin-bottom: r(36px)
	.text-shadow
		text-transform: none
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
		margin-bottom: 1.5rem
		+mq-min(lg)
			margin-bottom: r(60px)