
.noti-purchase-wrap
	position: fixed
	bottom: 0
	left: 0
	z-index: 50
	width: 100%
	.noti-purchase-content
		background: #FFFFFF
		box-shadow: 0px -6px 15px rgba(0, 0, 0, 0.1)
		border-radius: r(10px) r(10px) 0px 0px
		line-height: 1.33333
		padding: r(20px)
		position: relative
		+mq-min(sm)
			padding: r(32px) r(54px) r(32px) r(30px)
			display: flex
			align-items: center
			justify-content: space-between
	.noti-purchase-text
		margin-bottom: r(20px)
		+fz-lh(20px,28px)
		+mq-min(sm)
			margin-bottom: 0
			padding-right: r(30px)
	.product-detail-purchase
		margin-top: 0
	.noti-close
		position: absolute
		top: r(10px)
		right: r(10px)
		font-size: r(24px)
		cursor: pointer
