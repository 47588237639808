.section-home-6
	background: linear-gradient(97.17deg, #F0EFEF 0.39%, #EDEAEA 100.44%)

.home-plant-slider
	.item
		padding-bottom: r(60px)
		position: relative
		.img
			+mq-min(xl)
				width: 450 / 670 * 100%
				+radius(r(8px))
			figure
				+res-ratio(450,310)
				overflow: hidden
		.caption
			+bg(white)
			padding: r(24px)
			+mq-min(xl)
				padding: r(60px) r(32px)
				+radius(r(8px))
				position: absolute
				bottom: 0
				right: 0
				width: 310 / 670 * 100%
			.subtitle
				+text(secondary)
				text-transform: uppercase
				margin-bottom: r(8px)
			.title
				+font(600,20px,24px)
				margin-bottom: r(20px)
			.brief
				+font(300,18px,24px)
				margin-bottom: r(32px)
				+line(3)
				font-weight: 300
