.form-group
	+all-input
		+radius(r(4px))
		+border($color: grey-666)
		padding: 0.25rem r(16px) 0 !important
		width: 100%
		height: r(48px)
		font-style: normal !important
		font-size: 1rem !important
		font-family: 'Josefin Sans', sans-serif !important
		background: transparent
	select
		appearance: none
		+select-bg($pos: calc(100% - 1.25rem))
		option
			+text(body)
	textarea
		height: r(140px)
		padding-top: r(16px) !important
	.form-link
		.btn
			+mq-max(sm)
				width: 100%
	.RadPicker
		width: 100% !important
		position: relative
		.riTextBox
			+bg-opa(white, 0.95)
			border: 0 !important
		.riEmpty
			+text(grey-666)
		.rcTable
			width: 100% !important
		.rcCalPopup
			position: absolute
			right: r(10px)
			+center(Y)
			background-image: url(../img/calendar.png)
			background-position: center !important
	&.qradio
		ul
			margin-bottom: r(-20px)
			+columnizer(2,r(20px))
			li
				input
					+hide()
					&:checked ~ label
						color: color(blue)
						&:before
							content: '\f192'
				label
					display: flex
					&:before
						content: '\f111'
						font-family: "Font Awesome 6 Pro"
						margin-right: r(10px)
						position: relative

.frm-btnwrap
	margin-top: r(30px)
	label
		display: none
	+mq-max(sm)
		margin-top: r(20px)

.frm-btn
	display: inline-flex
	+flex-center
	height: r(40px)
	border-radius: r(40px) 0px
	padding: r(12px) r(32px) r(8px)
	+trans-all
	white-space: nowrap
	position: relative
	+bg(secondary)
	width: r(200px)
	overflow: hidden
	&:before
		position: absolute
		display: inline-flex
		content: '\e09f'
		font-family: "Font Awesome 6 Pro"
		font-weight: 300
		+center(Y)
		+flex-center
		width: r(28px)
		height: r(28px)
		border-radius: r(40px) 0px
		font-size: r(16px)
		z-index: 2
		right: r(8px)
		+bg(white)
		+text(secondary)
	input
		+reset-button
		+text(white)
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		text-transform: uppercase
		padding-top: 0.25rem

.frm-captcha
	display: flex
	flex-wrap: wrap-reverse
	margin-top: r(20px)
	flex-direction: row-reverse
	justify-content: flex-end
	gap: r(20px)
	label
		display: none
	.frm-captcha-input
		flex: 1
		+mq-max(sm)
			flex: 0 0 100%
	.RadCaptcha
		display: flex
		flex-direction: column-reverse
		flex: 1
		max-width: fit-content
		img
			height: r(50px) !important
		> span
			color: red
			display: block
			margin-top: 5px
		> div
			> div
				display: flex
				align-items: center
		a
			font-size: 0
			margin-left: 20px
			// display: none !important
			&:before
				font-family: "Font Awesome 6 Pro"
				content: '\f2ea'
				font-size: r(27px)
				color: #333

.form-container
	.wrap-form
		.qradio
			.label
				display: none
		.frm-btn
			height: r(40px)
			width: 100%
			input[type="submit"]
				width: 100%
				min-width: auto
		.frm-btnwrap
			width: r(200px)
			margin-left: r(20px)
			margin-top: 0 !important
			+mq-max(sm)
				width: 100%
				margin-left: 0
				margin-top: r(20px) !important
		.form-append
			+mq-max(sm)
				flex-direction: column
