.section-business-3
	.item-list
		.item
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
			padding: 2rem r(20px)
			.title
				font-size: r(76px)
				+text(main)
				sub
					font-size: r(14px)
			.title,.img
				height: r(120px)
			.img
				figure
					height: 100%
				img
					+fit-contain
			.desc
				+font(300,18px,24px)
				margin-top: 1rem